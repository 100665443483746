
export default {
    name: 'checkbox',
    props: ['value', 'disable', 'label-class', 'checkmark-class', 'id', 'plan-id'],
    mounted() {
        setTimeout(() => {
            if(this.planId && !this.value &&
            ($('.c-View-all-plans__section:not([style*="display: none"]) #checkbox' + this.planId).is(':checked') || $('.c-Q-Res__content-wrap #checkbox' + this.planId).is(':checked')) ) {
                this.$emit('input', true);
                this.$emit('change', true);
            }
        }, 2000);
    },
    methods: {
        onchange (e) {
            this.$emit('input', e.target.checked);
            this.$emit('change', e.target.checked);
        }
    }
}
