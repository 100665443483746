import Vue from "vue";
import Vuelidate from 'vuelidate';
import { required, requiredIf } from 'vuelidate/lib/validators';

import {
    isTruthy
} from '../../helper-functions/helper-functions';

Vue.use(Vuelidate);

Vue.component('quote-form-covid-location', {
    data () {
        return {
            modalVisible: false,
            isFormSubmitted: false,
            isAllVisible: true,
            coverageAreaInfo: '',
            form: {
                section: window.globalLanguage.section || '',
                mailingState: '',
                coverageArea: '',
                arrivalTime: '',
                homeCountry: '',
                residenceState: '',
                physicalPresenceState: '',
                policyMaximum: ''
            },
            frmCreated: false,
            destination: ''
        }
    },
    validations () {
        let validations = {
            form: {
                mailingState: {
                    required
                },
                coverageArea: {
                    required
                },
                arrivalTime: {
                    required
                },
                homeCountry: {},
                residenceState: {
                    required
                },
                physicalPresenceState: {}
            }
        }

        // Travel Outside USA
        if (['travelOutsideUSA','schengen','studyAbroad'].indexOf(this.form.section) > -1) {

            // require by default
            validations.form.homeCountry.required = required;
            validations.form.physicalPresenceState.required = required;

            if (this.form.homeCountry !== 'USA' && this.form.arrivalTime == '0') {
                // do not require
                if  (validations.form.physicalPresenceState.required) {
                    delete validations.form.physicalPresenceState.required;
                }
            }
            if (this.form.homeCountry === 'USA' && this.form.arrivalTime != '0') {
                // do not require
                if (validations.form.physicalPresenceState.required) {
                    delete validations.form.physicalPresenceState.required;
                }
            }

            if(this.form.homeCountry === 'USA'){
                validations.form.residenceState.required = required;
            }
            else{
                delete validations.form.residenceState.required;
            }
        }

        if (this.form.section === 'missionaryTravel' || this.form.section === 'covid19') {
            if (this.form.coverageArea === '2' || 
                (this.form.section === 'covid19' && (this.form.coverageArea === '1' || this.form.coverageArea === '5'))
            ) {
                validations.form.arrivalTime.required = required;
            }else if(this.form.coverageArea === '3'){
                validations.form.homeCountry.required = required;                
            }

            if (this.showPhysicalPresenceState) {
                validations.form.physicalPresenceState.required = required;
            }

            if(this.form.homeCountry === 'USA'){
                validations.form.residenceState.required = required;
            }
            else{
                delete validations.form.residenceState.required;
            }
        }

        if(['travelOutsideUSA','schengen','studyAbroad','missionaryTravel'].indexOf(this.form.section) < 0){
            delete validations.form.residenceState.required;
        }

        return validations;
    },
    created () {
        this.$root.$on('quote-covid-location-modal', (val) => {
            this.frmCreated = true;
            this.form.mailingState = val.form.mailingState;
            this.form.coverageArea = val.form.coverageArea;
            this.form.arrivalTime = val.form.arrivalTime;
            this.form.homeCountry = val.form.homeCountry;
            this.form.residenceState = val.form.residenceState;
            this.form.physicalPresenceState = val.form.physicalPresenceState;
            this.coverageAreaInfo = val.coverageAreaInfo;
            this.form.policyMaximum = val.form.policyMaximum;
            this.modalVisible = true;
            if (val.form.primaryDestination) this.destination = val.form.primaryDestination;
            $('body').addClass('is-Locked--quote');
        });
        this.$root.$on('reset-covid-location-modal', ()=>{
            this.resetForm();
        });
    },
    methods: {
        showModal: function (url) {
            this.$emit('show-modal', url);
        },
        closeModal (resetForm) {
            this.modalVisible = false;
            if (resetForm) { 
                this.resetForm();
                this.$root.$emit('set-covid-location-modal', {}, true);
            }
            $('body').removeClass('is-Locked--quote')
        },
        submitForm () {
            this.isFormSubmitted = true;
            this.$v.form.$touch();
            if (!this.$v.form.$invalid && !this.calculateAges) {
                this.$root.$emit('set-covid-location-modal', {
                    form: JSON.parse(JSON.stringify(this.form)),
                    coverageAreaInfo: this.coverageAreaInfo
                });
                this.closeModal();
            }
        },
        resetForm () {
            this.$v.$reset();
            this.isFormSubmitted = false;
            this.form = {
                mailingState: '',
                coverageArea: '',
                arrivalTime: '',
                homeCountry: '',
                residenceState: '',
                physicalPresenceState: '',
                section: window.globalLanguage.section || ''
            };
            this.coverageAreaInfo = '';
        },
        changeArrivalInUSA (e){
            this.form.arrivalTime='';
            this.form.homeCountry='';
            var selectedIndex = e.target.options.selectedIndex;
            this.coverageAreaInfo = e.target.options[selectedIndex]?.text;
            this.$v.form.coverageArea.$touch();
        },
        displayHomeCountry: function(e){
            var selectedIndex = e.target.options.selectedIndex;
            this.homeCountryInfo = e.target.options[selectedIndex]?.text;
            this.$v.form.homeCountry.$touch();
        },
    },
    computed: {
        showPhysicalPresenceState() {
            let isVisible = false;

            if (
                (
                    (this.form.coverageArea == '1' || this.form.coverageArea == '5' || this.form.coverageArea == '2') &&
                    (this.form.arrivalTime == '1' || this.form.arrivalTime == '2' || this.form.arrivalTime == '4')
                ) || (this.form.homeCountry === 'USA' && this.form.arrivalTime == '0')
            ) {
                isVisible = true;
            }

            return isVisible;
        }
    },
    watch: {
        'form.arrivalTime': function (val) {
            if (!this.frmCreated) {
                this.form.physicalPresenceState = "";
            } else if (this.frmCreated) {
                this.frmCreated = false;
            }
        }
    }
});