import WorldTrips from './companies/worldtrips-planids';
import IMG from './companies/img-planids';
import Trawick from './companies/trawick-planids';
import Travelex from './companies/travelex-planids';
import Azimuth from './companies/azimuth-planids';
import GU from './companies/gu-planids';

class PlanIds {
    constructor(){
        this.worldtrips = new WorldTrips();
        this.img = new IMG();
        this.trawick = new Trawick();
        this.azimuth = new Azimuth();
        this.gu = new GU();
        this.travelex = new Travelex();
    }

    /**
     * Method to check if the given plan has an in app feature.
     * @param {Number} planId 
     */
    checkIfInApp(planId) {
        if (typeof planId == 'string') planId = +planId;
        let isInApp = false;
        const allInAppIds = [
            ...this.worldtrips.allInAppIds(), 
            ...this.img.allInAppIds(), 
            ...this.trawick.allInAppIds(), 
            ...this.azimuth.allInAppIds(), 
            ...this.gu.allInAppIds(),
            ...this.travelex.allInAppIds()
        ];

        if (allInAppIds.includes(planId)) isInApp = true;

        return isInApp;
    }

    isAllInAppTripInsurance (planId) {
        if (typeof planId == 'string') planId = +planId;
        let isTrip = false;

        const allTripInsuranceIds = [
            ...this.worldtrips.isTripInsurance(),
            ...this.img.isTripInsurance(),
            ...this.trawick.isTripInsurance(),
            ...this.azimuth.isTripInsurance(),
            ...this.gu.isTripInsurance(),
            ...this.travelex.isTripInsurance()
        ];

        if (allTripInsuranceIds.includes(planId)) isTrip = true;

        return isTrip;
    }

    isAllInAppFlightInsurance (planId) {
        if (typeof planId == 'string') planId = +planId;
        let isFlight = false;

        const allFlightInsuranceIds = [
            ...this.travelex.isFlightInsurance()
        ]

        if (allFlightInsuranceIds.includes(planId)) isFlight = true;

        return isFlight;
    }

    getCompanyCode (planId) {
        if (typeof planId == 'string') planId = +planId;
        if (this.worldtrips.allPlanIds().includes(planId)) {
            return 'WorldTrips'
        } else if (this.img.allPlanIds().includes(planId)) {
            return 'IMG'
        } else if (this.trawick.allPlanIds().includes(planId)) {
            return 'Trawick'
        } else if (this.azimuth.allPlanIds().includes(planId)) {
            return 'Azimuth'
        } else if (this.gu.allPlanIds().includes(planId)) {
            return 'GU'
        } else if (this.travelex.allPlanIds().includes(planId)) {
            return 'Travelex'
        } else {
            return null
        }
    }
}

export default PlanIds