
export default {
    name: 'radio',    
    props: ['name', 'label', 'disable', 'default'],
    data() {
        return {
            value: null,
            pcfTraining: {
                option: null
            },
        }
    },
    created () {
        if(this.default) this.value = this.default;
    },
    watch: {
        disable () {
            if (this.disable) {
                this.value = null;
            }
        },
        default () {
            if (this.default) {
                this.value = this.default;
            } else {
                this.value = null;
            }
        }
    },
    methods: {
        onchange (e) {
            this.$emit('change', e.target.checked);
        }
    }
}
