"use strict";

import Vue from 'vue';
import VueResource from 'vue-resource';
import { dynamicVueComponent } from '../section-specific/applications/mixins/dynamic-vue-modal';
Vue.use(VueResource);

import {changeInSessionStorage} from '../helper-functions/services';


var printThis = require('print-this');

$(document).ready(function () {
    $('body').on('click', '.c-Modal__print-link', function (e) {
        e.preventDefault();
        try {
            $('#js-Modal-print').printThis({
                header: $('.c-Print-H')[0] ? $('.c-Print-H')[0].outerHTML : null,
                footer: $('.c-Print-F')[0] ? $('.c-Print-F')[0].outerHTML : null
            });
        } catch (err) {
            // print is not supported
            console.warn(err);
        }
    });
});

Vue.component('modal', {
    props: ['modal-url', 'size-prop', 'modal-address', 'app-modal', 'videomodal', 'api-modal', 'modal-header'],
    template: '#modal-template',
    data: function () {
        return {
            content: globalLanguage.contentIsLoading,
            sizeInit: 'default',
            qResultsLang: window.qResultsLang,
            uspsAddress:{
                address1:'',
                address2:'',
                city:'',
                state:'',
                contact:'',
                country: 'USA'
            },
            uspsValidated: false,
            showDynamicComponent: false
        }
    },
    computed: {
        size: function () {
            var size = this.sizeProp !== null ? this.sizeProp : this.sizeInit;
            return size;
        },
        dynamicVueComponent
    },
    created: function () {
        var self = this;
        var problemMsg = globalLanguage.problemLoadingContent;
        // TODO: Figure out the correct way to handle the response

        if(!this.appModal){
            if (this.modalUrl != '' || this.modalUrl != 'undefined') {
                this.getAppModal(this.modalUrl);
            } else {
                self.content = problemMsg;
            }
        }else{
            var address = self.modalAddress;
            var obj = new Object();
            obj.addrArray = [address.address1,address.address2,address.city,address.state,address.postalCode,address.contact];
            var jsonAddress=JSON.stringify(obj);

            if((self.modalUrl != '' || self.modalUrl != 'undefined') && (jsonAddress != '' || jsonAddress != 'undefined') ) {
                self.$http.post(this.modalUrl, jsonAddress)
                    .then(
                        function (response) {
                            if (response.status === 200) {
                                var validatedAddress = response.body.data.uspsAddress;

                                if(validatedAddress!='' || validatedAddress!=null){

                                    if(validatedAddress === 'exception'){
                                        var msg = 'We were unable to validate your mailing address with United States Postal Service. Please select the address that you entered in order to continue.';
                                        $("#lab_returnText").html('<p>' + msg + '</p>');
                                        this.uspsValidated = false;
                                    }else{
                                        this.uspsValidated = true;
                                        var dataArr = validatedAddress.split('|#|');
                                        if(dataArr.length>2){
                                            self.uspsAddress.address2 = dataArr[0];
                                            self.uspsAddress.address1 = dataArr[1];
                                            self.uspsAddress.city = dataArr[2];
                                            self.uspsAddress.state = dataArr[3];
                                            self.uspsAddress.postalCode = dataArr[4];
                                            self.uspsAddress.contact = dataArr[8];
                                            var errorType = dataArr[6] != '' ? dataArr[6] : dataArr[5] != '' ? dataArr[5] : '';
                                            if(errorType!='') {
                                                this.uspsValidated = false;

                                                if(errorType.indexOf("Address Not Found") > -1) {
                                                    $("#lab_returnText").html('<p>' + window.addressValidation.addressNotFound + '</p>');
                                                } else if(errorType.indexOf("Invalid Zip Code") > -1) {
                                                    $("#lab_returnText").html('<p>' + window.addressValidation.invalidZipCode + '</p>');
                                                } else if(errorType.indexOf("Invalid City") > -1) {
                                                    $("#lab_returnText").html('<p>' + window.addressValidation.invalidCity + '</p>');
                                                } else if(errorType.indexOf("Invalid State Code") > -1) {
                                                    $("#lab_returnText").html('<p>' + window.addressValidation.invalidState + '</p>');
                                                } else if(errorType.indexOf("Multiple addresses were found") > -1) {
                                                    $("#lab_returnText").html('<p>' + window.addressValidation.multipleAddressFound + '</p>');
                                                } else if(errorType.indexOf("Default address: The address you entered was found") > -1) {
                                                    $("#lab_returnText").html('<p>' + window.addressValidation.defaultAddress + '</p>');
                                                } else{
                                                    $("#lab_returnText").html('<p>' + errorType + '</p>');
                                                }
                                            }
                                            // var errorType = dataArr[6] != '' ? dataArr[6] : dataArr[5] != '' ? dataArr[5] : '';
                                            // if(errorType!='') {
                                            //     if(errorType.indexOf("Address Not Found") > -1) {
                                            //         $("#lab_returnText").html('<p>' + window.addressValidation.addressNotFound + '</p>');
                                            //     } else if(errorType.indexOf("Invalid Zip Code") > -1) {
                                            //         $("#lab_returnText").html('<p>' + window.addressValidation.invalidZipCode + '</p>');
                                            //     } else if(errorType.indexOf("Invalid City") > -1) {
                                            //         $("#lab_returnText").html('<p>' + window.addressValidation.invalidCity + '</p>');
                                            //     } else if(errorType.indexOf("Invalid State Code") > -1) {
                                            //         $("#lab_returnText").html('<p>' + window.addressValidation.invalidState + '</p>');
                                            //     } else if(errorType.indexOf("Multiple addresses were found") > -1) {
                                            //         $("#lab_returnText").html('<p>' + window.addressValidation.multipleAddressFound + '</p>');
                                            //     } else if(errorType.indexOf("Default address: The address you entered was found") > -1) {
                                            //         $("#lab_returnText").html('<p>' + window.addressValidation.defaultAddress + '</p>');
                                            //     } else{
                                            //         $("#lab_returnText").html('<p>' + errorType + '</p>');
                                            //     }
                                            // }
                                        }else if(dataArr.length==2){
                                            if(dataArr[0]!='') {
                                                $("#lab_description").html('<p>' + dataArr[0] + '</p>');
                                            }
                                        }else{
                                            if(dataArr[0]!='') {
                                                $("#lab_returnText").html('<p>' + dataArr[0] + '</p>');
                                            }
                                            if(dataArr[1]!='') {
                                                $("#lab_description").html('<p>' + dataArr[1] + '</p>');
                                            }
                                        }
                                    }

                                }
                            } else {
                                self.content = problemMsg;
                                self.showDynamicComponent = true;
                                this.uspsValidated = false;
                            }
                        },
                        function (response) {
                            self.content = problemMsg;
                            this.uspsValidated = false;
                        }
                    )
            } else {
                self.content = problemMsg;
            }
        }


    },
    mounted: function(){
        setTimeout(() => {
            document.querySelector("#container").addEventListener('keydown', this.tabFocusEvent);
            $("#js-Modal-wrap").focus();
        }, 500);

        this.$nextTick(() => $('#use_this_address').focus());
    },
    methods:{
        assignReturnLink: function() {
            const aTag = document.getElementById('return-link');
            if(aTag) {
                aTag.href = ibJS.purchaseLink;
            }
        },
        returnAddress: function(event){
            event.preventDefault();
            this.$emit('show-return-address', this.uspsAddress, this.uspsValidated);
            $('#validate_now')?.focus()
        },
        isSameAddress: function(addr1, addr2) {
            const checkProps = ['address1', 'address2', 'city', 'state', 'postalCode'];
            for (let prop of checkProps) {
                if (addr1[prop] !== addr2[prop]) {
                    return false;
                }
            }
            return true;
        },
        existingAddress : function(event){
            event.preventDefault();
            if (this.uspsValidated) {
                this.uspsValidated = this.isSameAddress(this.modalAddress, this.uspsAddress);
            }
            this.$emit('choose-entered-addresss', this.uspsValidated);
            $('#validate_now')?.focus()
        },
        cancelApp: function(){
            window.location.href=window.ibJS.purchaseLink;
        },
        continueApp: function(){
            window.location.href='/'+window.ibJS.productName+window.qResultsLang.step3;
        },
        resetApplication : function(e){
            var query =  '/' + ibJS.productName + window.qResultsLang.reset;
            
            // CTS-4748
            sessionStorage.removeItem('inbound-call');
            
            if(this.modalUrl.indexOf("extension") > -1){
                if(this.modalUrl.indexOf("worldtrips-extension") > -1) {
                    if(window.globalLanguage.lang == 'en') {
                        query = '/travel-and-student-insurance-extension-reset-application'; 
                    } else if (window.globalLanguage.lang == 'es') {
                        query = '/seguro-de-viaje-y-de-estudiante-prorroga-reiniciar-solicitud';     
                    } else if (window.globalLanguage.lang == 'zh') {
                        query = '/lvyou-he-xuesheng-baoxian-yanchang-chongzhi-shenqing';   
                    }
                } else if (this.modalUrl.indexOf("img-extension") > -1) {
                    if(window.globalLanguage.lang == 'en') {
                        query = '/international-medical-extension-reset-application';
                    } else if (window.globalLanguage.lang == 'es') {
                        query = '/prorroga-solicitud-de-reinicio-medico-internacional';     
                    } else if (window.globalLanguage.lang == 'zh') {
                        query = '/guoji-yiliao-yanchang-chongzhi-shenqing';   
                    }
                } else if (this.modalUrl.indexOf("trawick-extension") > -1) {
                    if(window.globalLanguage.lang == 'en') {
                        query = '/safe-travels-extension-reset-application';
                    } else if (window.globalLanguage.lang == 'es') {
                        query = '/safe-travels-solicitud-de-reinicio-de-prorroga';     
                    } else if (window.globalLanguage.lang == 'zh') {
                        query = '/safe-travels-xubao-chongzhi-shenqing';   
                    }
                } else if (this.modalUrl.indexOf("azimuth") > -1) {
                    if(window.globalLanguage.lang == 'en') {
                        query = '/az-extension-reset-application/'; 
                    } else if (window.globalLanguage.lang == 'es') {
                        query = '/az-prorroga-reiniciar-solicitud/';
                    } else if (window.globalLanguage.lang == 'zh') {
                        query = '/az-xubao-chongzhi-shenqing/';
                    }
                }
            }
            window.location.href = query;
            e.preventDefault();
        },
        removeConfirm: function() {
            this.$emit('remove-confirm');
            this.$emit('close');
        },
        emitCancelValidateAddress: function() {
            this.$emit('close');
            if($('#validate_now')) {
                $('#validate_now').focus();
            }
        },
        tabFocusEvent: function (e) {
            const event = e;
            const key_tab = 9;
            console.log(e.target);
            const $thisElm = $(e.target);            
            const $allElms = $("#js-Modal-wrap").find('a[href]:not([disabled]), button:not([disabled]), input[type=text]:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])');
            const allElmsSize = $allElms.length;
            console.log(e.target);
            if (event.shiftKey && event.keyCode == key_tab) {
                for (let i = 0; i < allElmsSize; i++) {
                    console.log(e.target === $allElms.get(i), i);
                    if (e.target === $allElms.get(i) && (i === 0)) {
                        $($allElms[allElmsSize-1]).focus();
                        break;
                    }
                }
            } else if (event.keyCode == key_tab) {
                for (let i = 0; i < allElmsSize; i++) {
                    if (e.target === $allElms.get(i) && (i === allElmsSize - 1)) {
                        $("#js-Modal-wrap").focus();
                        break;
                    }
                }
            }
        },
        focusCloseEl(e) {
            document.getElementById('close_modal')?.focus();
            e.stopPropagation()
            e.preventDefault();
        },
        getAppModal (url) {
            let problemMsg = globalLanguage.problemLoadingContent;
            this.$http.get(url)
            .then(response => {
                    if (response.status === 200) {
                        this.content = response.body;
                        const $closeModal = document.querySelector('.c-Modal');
                        if($closeModal) $closeModal.setAttribute('data-url', url);
                        this.showDynamicComponent = true;
                        Vue.nextTick(() => {
                            global.eventHub.$emit('show-modal-rendered', true, response.body); // global.eventHub.$on('show-modal-rendered', (rendered) => {});
                            this.assignReturnLink();
                            if($closeModal) {
                                let $closeBtn = document.querySelectorAll('.close-modal');
                                if ($closeBtn) { 
                                    $closeBtn = Array.from($closeBtn);
                                    $closeBtn.forEach(elm=>{
                                        elm.addEventListener('click', (e) => {
                                            this.$emit('close');
                                        });
                                    })
                                }
                            }
                        } );
                    } else {
                        this.content = problemMsg;
                    }
                },
                function (response) {
                    this.content = problemMsg;
                }
            )
        }
    },
    beforeDestroy() {
        document.querySelector("#container").removeEventListener('keydown', this.tabFocusEvent);
    },
});
