class Azimuth {
    constructor () {
        this.Beacon_America = 673
        this.Beacon_International = 674
        this.Meridian_Parent = 688
        this.Meridian_Essential_Excluding_US_And_Canada = 733
        this.Meridian_Essential_Including_US_And_Canada = 734
        this.Meridian_Enhanced_Excluding_US_And_Canada = 735
        this.Meridian_Enhanced_Including_US_And_Canada = 736
    }

    allInAppIds () {
        return [
            this.Beacon_America,
            this.Beacon_International
        ]
    }

    isTripInsurance () {
        // No Trip plans for Azimuth
        return [];
    }

    allPlanIds () {
        return [
            this.Beacon_America,
            this.Beacon_International,
            this.Meridian_Parent,
            this.Meridian_Essential_Excluding_US_And_Canada,
            this.Meridian_Essential_Including_US_And_Canada,
            this.Meridian_Enhanced_Excluding_US_And_Canada,
            this.Meridian_Enhanced_Including_US_And_Canada
        ]
    }

    /**
     * Validates the planId part of meridian
     * @param {Number} planId 
     * @returns Boolean
     */
    isMeridianPlan (planId) {
        const meridian = [this.Meridian_Parent, 
                this.Meridian_Essential_Excluding_US_And_Canada, 
                this.Meridian_Essential_Including_US_And_Canada, 
                this.Meridian_Enhanced_Excluding_US_And_Canada, 
                this.Meridian_Enhanced_Including_US_And_Canada].indexOf(planId) > -1 ? true : false;

        return meridian;
    }
}

export default Azimuth;

