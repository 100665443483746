(function($) {
    "use strict";

    $.fn.ibRandom = function(itemClass) {
        var $this = $(this);
        var $items = $this.find(itemClass);
        var random = Math.floor(Math.random() * $items.length);

        $items.addClass('js-Hide');
        $items.eq(random).removeClass('js-Hide');

        $this.removeClass('js-Init-hidden');

        return this;
    };

})(jQuery);
