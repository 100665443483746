export default {
    methods: {
        resetNarrowFilter: function(){
            this.resetNarrow();
        },
        clearPlanLists() {
            if(!this.numOfPlansLists) return;
            for(let i = 0; i < this.numOfPlansLists; i++) {
                if(this['list' + i]) this['list' + i] = [];
                if(this['list' + i + 'Comp']) this['list' + i + 'Comp'] = [];
            }
            if(typeof(this.resetNarrow) == 'function') {
                this.resetNarrow();
            }
        }
    }
}