"use strict";

// this can probably be improved - globals are usually not a great idea
window.globalLanguage.sectionCriteriaKey = 'insubuy-' + window.globalLanguage.section + 'Criteria' || '';
window.globalSection={
    travelmedical:['visitorUSA','travelOutsideUSA','schengen','newImmigrants','studyAbroad','missionaryTravel', "covid19"],
    trip:['trip'],
    studentmedical:['student','opt','j1Visa'],
    expatriatehealth:['expatriate','missionary','marine'],
    multitrip:['multitrip'],
    flight:['flight'],
    grouptravelmedical:['groupTravelMedical']
};
window.globalLanguage.sectionNarrow = 'insubuy-' + window.globalLanguage.section + 'Narrow' || '';
// make sure window.ibJS is defined - used on some pages
window.ibJS = window.ibJS || {};

// Object.assign polyfill
require('es6-object-assign/auto');

global.jQuery = require('jquery');
global.$ = global.jQuery;

require('./third-party/minitip-tooltip');

// Own modules
import { accordion } from './modules/simple-accordion';
import polyfillInit from './polyfills';
import {
    getCookieItem,
    createCookie,
    eraseCookie
} from './helper-functions/services';
var cycle = require('./modules/rotator-cycle');
var eProtect = require('./modules/email-spam-protection');
var globalDataAttr = require('./modules/global-data-attributes');
var random = require('./modules/random-display');
var randomReviews = require('./modules/random-display-reviews');
var scrollToTop = require('./modules/scroll-to');
var tabs = require('./modules/simple-tabs');

// APP-960: Prevents Pushy event listeners from being loaded twice.
import { pushy } from './third-party/pushy-off-canvas';
if(!global.hasPushy) {
    $(document).ready(() => {
        accordion();
        pushy();
    });
}
global.hasPushy = true;

var spin = require('./third-party/spin');

// start polyfills
polyfillInit();

global.ibSpinOpts = {
  lines: 11 // The number of lines to draw
, length: 25 // The length of each line
, width: 6 // The line thickness
, radius: 21 // The radius of the inner circle
, scale: 1 // Scales overall size of the spinner
, corners: 1 // Corner roundness (0..1)
, color: '#555' // #rgb or #rrggbb or array of colors
, opacity: 0.45 // Opacity of the lines
, rotate: 0 // The rotation offset
, direction: 1 // 1: clockwise, -1: counterclockwise
, speed: 1.4 // Rounds per second
, trail: 60 // Afterglow percentage
, fps: 20 // Frames per second when using setTimeout() as a fallback for CSS
, zIndex: 2e9 // The z-index (defaults to 2000000000)
, className: 'spinner' // The CSS class to assign to the spinner
, top: '50%' // Top position relative to parent
, left: '50%' // Left position relative to parent
, shadow: false // Whether to render a shadow
, hwaccel: true // Whether to use hardware acceleration
, position: 'absolute' // Element positioning
};

// Import function from "helper-functions.js"
import {resetMobileMenu} from './helper-functions/helper-functions';

$(document).ready(function() {

    window.injectEmail = function(){
        $('.js-E-rev').each(function(){
            if(typeof($(this).ibEProtect) == 'function') $(this).ibEProtect();
        });
    
    };

    // Vars
    var $window = $(window);
    var $body = $('body');

    injectEmail();

    var tooltipSelector = $('[data-toggle="tooltip"]');
    var tooltipDirection = tooltipSelector.data("tooltip");

    // SENTRY-705 and SENTRY-807
    if(tooltipSelector && typeof tooltipSelector.miniTip === 'function') {
        tooltipSelector.miniTip({
            anchor: tooltipDirection ? tooltipDirection : 's',
            delay: 100
        });
    }

    if (typeof $('#lang_cycle_push').ibCycle === 'function') {
        $('#lang_cycle_push').ibCycle(4000);
    }
    if (typeof $('#lang_cycle_head').ibCycle === 'function') {
        $('#lang_cycle_head .o-Cycle__item').first().addClass('js-Active');
        $('#lang_cycle_head').ibCycle(4000);
    }
    if (typeof $('#phone_cycle_head').ibCycle === 'function') {
        $('#phone_cycle_head').ibCycle(4000);
    }
    if (typeof $('#phone_cycle_push').ibCycle === 'function') {
        $('#phone_cycle_push').ibCycle(4000);
    }
    if (typeof $('#phone_cycle_head-contact').ibCycle === 'function') {
        $('#phone_cycle_head-contact').ibCycle(4000);
    }
    if (typeof $('#phone_cycle_push-contact').ibCycle === 'function') {
        $('#phone_cycle_push-contact').ibCycle(4000);
    }

    try {
        $('#outro').ibRandom('.c-Outro__item');
    } catch (e) {}  

    try {
        $('#whyIB').ibRandom('.c-Why-IB__item');
    } catch (e) {}  

    try {
        $('#whyIBImage').ibRandom('.c-Why-IB__block-photo');
    } catch (e) {}  

    try {
        $('#reviews').ibRandomReviews('.c-Reviews__block');
    } catch (e) {}

    if (typeof $.fn.ibScrollToTop == "function") $('.js-ScrollToTop').ibScrollToTop();

    // Display the current page url in the print footer
    $("#urlSpan").text($(location).attr('href'));

    // Spinner
    var target = document.getElementById('ib-spinner');
    var spinner = new IBSpinner(ibSpinOpts).spin(target);

    //Hide mobile menu on resize to desktop breakpoint
    $window.resize(function() {
        resetMobileMenu();
    });

    var $jsSearch = $('#js-Search-drop');
    var $jsSearchWrap = $('#js-Search-wrap');
    var $jsSearchWrapActive = 'js-Search-drop-wrap--active';
    var $jsSearchActive = 'js-Search-drop--active';

    // Search Dropdown
    $('#js-Search').on('click', function(){
        if( $jsSearch.hasClass($jsSearchActive) ) {
            $jsSearch.removeClass($jsSearchActive);
            $jsSearchWrap.removeClass($jsSearchWrapActive);
        }else{
            $jsSearch.addClass($jsSearchActive);
            $jsSearchWrap.addClass($jsSearchWrapActive);
        }
    });

    $window.scroll(function(){
        let $scrollAmount = "500";
        if($window.scrollTop() >= $scrollAmount){
            $body.addClass('scrolled');
        }else if($window.scrollTop() <= $scrollAmount){
            $body.removeClass('scrolled');
        }
    });

    // Affilite no background title bar setting
    if(window && window.globalLanguage && window.globalLanguage.quoteformsBackground == false && $('#quoteFormBgCheck').length !== 0) {
        $("#titleBar").addClass('c-Head-M__title--no-quote-bg');
    }

    //online chat trigger
    $('#onlineChat, #onlineChatMobile, #contactUsChat, .o-onlineChat').on('click', function(){
        if (typeof window.Genesys === 'function') {
            Genesys("command", "Messenger.open", {},
                function(o){},  // if resolved
                function(o){    // if rejected
                    Genesys("command", "Messenger.close");
                }
            );
        }
    });

    // VFS - Tranlsate to French when user clicks on French lang bar while affiliate being vfs and navigaes to schengen visa 
    const googleCookieKey = "googtrans";
    const isGoogleLangSet = getCookieItem(googleCookieKey);

    //Check if googletrans is set
    //While the url has ln parameter the googtrans is set from the Backend.
    // PR is called as PT by google translator
    // Fn to Set / Delete Cookie
    function evenListGoogleLang (e) {
        // When a page is translated by Google and clicked on any other language
        // If it's EN / ES / ZH(CH) then remove the googletrans cookie
        // If it's PR / FR/ NL then remove the current cookie and set the new cookie
        const $currentReqLang = $(e.currentTarget);
        let currentReqLang = "";
        var date = new Date();
        date.setTime(date.getTime() + (0.085 * 24 * 60 * 60 * 1000));
        var expires = " expires=" + date.toGMTString();
        if ($currentReqLang && $currentReqLang.hasClass('c-Lang-H__link')) {
            currentReqLang = $currentReqLang.attr("data-lang");
        }
        // console.log(isGoogleLangSet);
        switch (currentReqLang) {
            case 'pr':
                // Portuguese
                createCookie(googleCookieKey, '/en/pt', 0.085, "");
                try {
                    document.cookie = 'googtrans=/en/pt;' + expires + '; path=' + window.location.pathname.substring(0, window.location.pathname.length - 1)+';SameSite=Lax';
                } catch (error) {
                    // Skip error;
                    console.log('.');
                }
                break;
            default:
                eraseCookie(googleCookieKey, "");
                eraseCookie(googleCookieKey, window.location.host.replace('www',''));
                try {
                    document.cookie = 'googtrans=-1;path=' + window.location.pathname.substring(0, window.location.pathname.length - 1)+';SameSite=Lax';
                } catch (error) {
                    console.log('.');
                }
                break;
        }
        if (currentReqLang != 'en' && currentReqLang != 'es' && currentReqLang != 'ch' && currentReqLang != 'zh' && currentReqLang != 'ja' && currentReqLang != 'ko' 
            && currentReqLang != 'ar' && currentReqLang != 'it' && currentReqLang != 'ru' && currentReqLang != 'ot') {
            // Once the cookie is set refresh the page to get the page translated
            const currentUrl = window.location.href;
            setTimeout(() => {
                window.location.href = currentUrl;
            }, 2500);
        }
    }

    if (isGoogleLangSet && isGoogleLangSet != -1) {
        // show the EN Lang bar at the menu
        $(".c-Lang-H__link--en").parent().removeClass('c-Lang-H__item--hidden');
        $(".c-Lang-H__lis").addClass('c-Lang-H__list--large'); // to accomodate 3+ langs on smaller breakponits
        $(".c-Lang-H__link").not('.c-Lang-H__link--br').on('click', evenListGoogleLang);

    } else {
        $(".c-Lang-H__link").not('.c-Lang-H__link--br').on('click', evenListGoogleLang);
    }

    // Pushy Mobil menu
    const mobMenuCount = $(".c-Lang-H__language .c-Lang-H__list li").not('.c-Lang-H__item--hidden').length;
    if(mobMenuCount > 4) {
        $(".c-Lang-H__language .c-Lang-H__list").addClass('c-Lang-H__list--large');
    }
    
    const isIE = /*@cc_on!@*/false || !!document.documentMode;
    if(isIE) {
        $(".browserSupport").hide();
    }

    // top menu dropdown style for affiliate
    setTopMenuDropdownForAffi();
    setGoogleTranslationId();

    $( window ).resize(function() {
        setTopMenuDropdownForAffi();
        setGoogleTranslationId();
    });

    // Change google translation iframe style
    $('#google_translate_element').on("click", function () {
        if(window.matchMedia && window.matchMedia('(max-width: 991px)').matches) {
            $('iframe.goog-te-menu-frame.skiptranslate').css(
                {
                    'max-width': '80vw',
                    'max-height': '50%',
                    'top': '35%',
                    'left': '50%',
                    'transform': 'translateX(-50%)'
                }
            )
            $('iframe').contents().find('.goog-te-menu2 td').css(
                {
                    'display': 'inline-block',
                    'width': '100%'
                }
            )
            $('iframe').contents().find('.goog-te-menu2').css(
                {
                    'max-width': '100%',
                    'max-height': '100%',
                    'overflow-y': 'scroll'
                }
            )
            $('iframe').contents().find('.goog-te-menu2-colpad').css(
                {
                    'display': 'none !important'
                }
            )
        } else if (window.matchMedia && window.matchMedia('(max-width: 1060px)').matches) {
            $('iframe.goog-te-menu-frame.skiptranslate').css(
                {
                    'max-width': '95vw',
                    'top': '35%',
                    'left': '50%',
                    'transform': 'translateX(-50%)'
                }
            )
            $('iframe').contents().find('.goog-te-menu2').css(
                {
                    'max-width': '100%',
                    'height': '99%',
                    'overflow-x': 'scroll'
                }
            )
        } 
    });

    // Wechat Share Qr cide
    const wechatShareContainer = document.getElementById('wechat-share-modal-container');
    const wechatShareModal = document.getElementById('wechat-share-modal');
    const wechatShareLink = document.getElementById('wechat-link-share');
    const wechatShareLink2 = document.getElementById('wechat-link-share2');      
    const wechatShareClose = document.getElementById('wechat-share-modal-close');        
    if(wechatShareModal && wechatShareContainer && wechatShareLink && wechatShareClose) {
        wechatShareModal.addEventListener('click', function(e){   
            if (!wechatShareContainer.contains(e.target)){
                // Clicked outside of box
                wechatShareModal.classList.add('u-Hidden');
            }  
        });
        if (wechatShareLink) wechatShareLink.addEventListener("click", openQrBlock, false);
        if (wechatShareLink2) wechatShareLink2.addEventListener("click", openQrBlock, false);
        if (wechatShareClose) wechatShareClose.addEventListener("click", closeQrBlock, false);
       

    }                      

    const el_qr = document.getElementById('qrHref');
    const path = window.location.pathname;
    if(el_qr && path && typeof QRCode == "function") {
        const qrcode = new QRCode(el_qr, {
            text: "https://chart.googleapis.com/chart?cht=qr&chs=104x104&choe=UTF-8&chld=L|0&chl=https://www.insubuy.com" + path,
            width: 128,
            height: 128,
            colorDark : '#000',
            colorLight : '#fff',
            correctLevel : QRCode.CorrectLevel.H
        });
    }

    function openQrBlock() {
        if(wechatShareModal?.classList.contains('u-Hidden')) {
            wechatShareModal.classList.remove('u-Hidden');
            wechatShareModal.classList.add('wechat-modal-open');
        } 
    }
    function closeQrBlock() {
        wechatShareModal.classList.add('u-Hidden');
    }
});


function setTopMenuDropdownForAffi () {
    const windowsWidth = $(window).width();
    const affiWidth = $('.c-Desk-H__logo-affi').width();
    if(windowsWidth < 1260) {
        $('.c-Nav-M__item--full-dropdown .c-Nav-M__child-list').css("padding-left",'.5rem');
    } else {
        $('.c-Nav-M__item--full-dropdown .c-Nav-M__child-list').css("padding-left",affiWidth+22+8+'px'); 
    }
}

let m = false;
function setGoogleTranslationId () {
    if(window.matchMedia && window.matchMedia('(max-width: 991px)').matches && m == false) {
        m = true;
        $('#google_translate_element').detach().appendTo('#m_google_translate_wrapper'); 
    } else if (window.matchMedia && window.matchMedia('(min-width: 992px)').matches && m == true) {
        m = false;
        $('#google_translate_element').detach().appendTo('#d_google_translate_wrapper');    
    }
}

String.prototype.includes = function (str) {
    var returnValue = false;
    try {
      if (this.indexOf(str) !== -1) {
          returnValue = true;
      }
    }catch(e) {}

    return returnValue;
}