class GU {
    constructor () {
        this.Diplomat_America = 6
        this.Diplomat_LT_Including_US = 7
        this.Diplomat_International = 31
        this.Diplomat_LT_Excluding_US = 32
        this.Group_Blanket = 36
        this.Diplomat_LT_Parent = 547
    }

    allInAppIds () {
        return [
            this.Diplomat_America,
            this.Diplomat_LT_Including_US,
            this.Diplomat_International,
            this.Diplomat_LT_Excluding_US,
            this.Diplomat_LT_Parent
        ]
    }

    isTripInsurance () {
        // No Trip plans for GU
        return [];
    }

    allPlanIds () {
        return [
            this.Diplomat_America,
            this.Diplomat_LT_Including_US,
            this.Diplomat_International,
            this.Diplomat_LT_Excluding_US,
            this.Group_Blanket,
            this.Diplomat_LT_Parent
        ]
    }
}

export default GU

