import Vue from 'vue';

// ZH date format. Auto insert '-' to date input "yyyy-mm-dd"
function zhDateFormat(val, oldVal, cursorIdx) {
    let newVal = val;
    if (oldVal.length < newVal.length) {
        // When editing inbetween '/'
        if ((cursorIdx < 4 && newVal.length > 4) || (cursorIdx == 6 && newVal[4] == '-' && newVal[6] == '-')) {
            return newVal;
        }
        if (newVal.length == 6) {
            if (newVal[5] > 1) {
            newVal = [newVal.slice(0, 5), 0, newVal.slice(5), '-'].join('');
            }
        }
        else if (newVal.length == 7) {
            if (newVal[6] == '-') {
            newVal = [newVal.slice(0, 5), 0, newVal.slice(5)].join('');
            }
            else if (newVal[5] == 1 && newVal[6] > 3) {
            newVal = [newVal.slice(0, 5), 0, newVal[5], 0, newVal[6]].join('');
            }
            else if (newVal[5] == 1 && newVal[6] == 3) {
            newVal = [newVal.slice(0, 5), 0, newVal[5], newVal[6]].join('');
            }
        }
        else if (newVal.length == 5 && newVal[4] != '-' && newVal[4] > 3) {
            newVal = [newVal.slice(0, 4), 0, newVal.slice(4)].join('');
        }
        else if ((newVal.length == 8 && newVal[7] > 3) || (newVal.length == 9 && newVal[8] > 3)) {
            newVal = [newVal.slice(0, 7), 0, newVal.slice(7)].join('');
        }
        if (newVal.length > 3) {
            let tempVal = newVal ? newVal.replace(/-/g, '') : '';
            newVal = tempVal.length < 6 ? [tempVal.slice(0, 4), '-', tempVal.slice(4)].join('') : [tempVal.slice(0, 4), '-', tempVal.slice(4, 6), '-', tempVal.slice(6)].join('');

            if (newVal.length > 10) {
            newVal = newVal.slice(0, 10);
            }
        }
    }
    return newVal;
}
// EN date format. Auto insert '/' to date input "mm/dd/yyyy"
function usDateFormat(val, oldVal, cursorIdx) {
    let newVal = val;
    if (oldVal.length < newVal.length) {
        if (newVal.length == 1) {
            if (newVal > 1) {
              newVal = [0, newVal, '/'].join('');
            }
        }
        // When editing inbetween '/'
        if ((cursorIdx == 1 && newVal[1] == '/') || (cursorIdx == 4 && newVal[2] == '/' && newVal[4] == '/')) {
            return newVal;
        }
        else if (newVal.length == 2) {
            if (newVal[1] == '/') {
              newVal = [0, newVal].join('');
            }
            else if (newVal[0] == 1 && newVal[1] > 3) {
              newVal = [0, newVal.slice(0, 1), 0, newVal.slice(1)].join('');
            }
            else if (newVal[0] == 1 && newVal[1] == 3) {
              newVal = [0, newVal.slice(0, 1), newVal.slice(1)].join('');
            }
        }
        else if (newVal.length == 3 && newVal[2] != '/' && newVal[2] > 3) {
            newVal = [newVal.slice(0, 2), 0, newVal.slice(2)].join('');
        }
          else if ((newVal.length == 4 && newVal[3] > 3) || (newVal.length == 5 && newVal[4] == '/')) {
            newVal = [newVal.slice(0, 3), 0, newVal.slice(3)].join('');
        }
          // Corrects format / move numbers
        if (newVal.length > 1) {
            let tempVal = newVal ? newVal.replace(/\//g, '') : '';
            newVal = tempVal.length < 4 ? [tempVal.slice(0, 2), '/', tempVal.slice(2)].join('') : [tempVal.slice(0, 2), '/', tempVal.slice(2, 4), '/', tempVal.slice(4)].join('');
    
            if (newVal.length > 10) {
              newVal = newVal.slice(0, 10);
            }
        }
    }
    return newVal;
}

// ES date format. Auto insert '/' to date input "dd/mm/yyyy"
function esDateFormat(val, oldVal, cursorIdx) {
    let newVal = val;
    if (oldVal.length < newVal.length) {
        if (newVal.length == 1) {
            if (newVal > 3) {
              newVal = [0, newVal, '/'].join('');
            }
        }
        // When editing inbetween '/'
        if ((cursorIdx == 1 && newVal[1] == '/') || (cursorIdx == 4 && newVal[2] == '/' && newVal[4] == '/')) {
            return newVal;
        }
        else if (newVal.length == 2) {
            if (newVal[1] == '/') {
              newVal = [0, newVal].join('');
            }
            else if (newVal[0] == 3 && newVal[1] > 1) {
              newVal = [0, newVal.slice(0, 1), 0, newVal.slice(1)].join('');
            }
        }
        else if (newVal.length == 3 && newVal[2] != '/' && newVal[2] > 1) {
            newVal = [newVal.slice(0, 2), 0, newVal.slice(2)].join('');
        }
        else if ((newVal.length == 4 && newVal[3] > 1) || (newVal.length == 5 && newVal[4] == '/')) {
            newVal = [newVal.slice(0, 3), 0, newVal.slice(3)].join('');
        }
        // Corrects format / move numbers
        if (newVal.length > 1) {
            let tempVal = newVal ? newVal.replace(/\//g, '') : '';
            newVal = tempVal.length < 4 ? [tempVal.slice(0, 2), '/', tempVal.slice(2)].join('') : [tempVal.slice(0, 2), '/', tempVal.slice(2, 4), '/', tempVal.slice(4)].join('');
    
            if (newVal.length > 10) {
              newVal = newVal.slice(0, 10);
            }
        }
    }
    return newVal;
}

var lang = window.globalLanguage.lang;

Vue.directive('date-insert', {
    update: function (el, binding, vnode) {
        const event = new Event('input');
        switch (lang) {
            case "zh": {
                el.value = zhDateFormat(el.value, binding.oldValue, el.selectionStart);
                break;
            }
            case "en": {
                el.value = usDateFormat(el.value, binding.oldValue, el.selectionStart);
                break;
            }
            case "es": {
                el.value = esDateFormat(el.value, binding.oldValue, el.selectionStart);
                break;
            }
        }
        el.dispatchEvent(event)
    }
})