<template>
    <div class="c-Print-plan__heading">
        <div v-cloak>
            <h3 v-for="(item, idx) of inputSummaryContent" :key="idx">
                <template v-if="item && item.label">
                    {{item.label}}
                </template>
            </h3>
        </div>
        <div v-cloak>
            <span v-for="(item, idx) of inputSummaryContent" :key="idx">
                <span v-if="item && item.content"  v-html="item.content"></span>
            </span>
        </div>
        <!-- <div>
            <h3>{{qResultsLang.coverageDate}}</h3>
            <h3>{{qResultsLang.coverageArea}}</h3>
            for group travel print 
            <h3 v-if="qResultsLang.ageGroup && ssCriteria.travelerInfos">{{qResultsLang.ageGroup}}</h3> 
            <h3 v-else>{{qResultsLang.age}}</h3>
        </div>
        <div>
            <span>
                {{ssCriteria.startDate}} {{qResultsLang.to}} {{ssCriteria.endDate}}
                <br />
                (<span v-if="ssCriteria.durationMonths > 0">{{ ssCriteria.durationMonths }} {{qResultsLang.months}} {{ ssCriteria.durationDays }} {{qResultsLang.days}}, </span>{{ ssCriteria.durationTotalDays }} {{qResultsLang.days}})
            </span>
            <span>
                <span v-if="ssCriteria.coverageArea === 2">{{qResultsLang.internationalIncludeUSA}}</span>
                <span v-else-if="ssCriteria.coverageArea === 1">{{qResultsLang.USA}}</span>
                <span v-else-if="ssCriteria.coverageArea === 3">{{qResultsLang.internationalExcludeUSA}}</span>
                <span v-else-if="ssCriteria.coverageArea === 5">{{qResultsLang.USAandDuringTravel}}</span>
            </span>
            <span>
                <span v-if="qResultsLang.ageGroup && ssCriteria.travelerInfos">
                    <span v-for="(traveler, index) in ssCriteria.travelerInfos">
                        <span v-if="traveler && traveler.ageGroup == 0">{{qResultsLang.ageGroupTravel0}}({{traveler.numOfTraveler}})</span>
                        <span v-else-if="traveler && traveler.ageGroup == 17">{{qResultsLang.ageGroupTravel17}}({{traveler.numOfTraveler}})</span>
                        <span v-else-if="traveler && traveler.ageGroup == 18">{{qResultsLang.ageGroupTravel18}}({{traveler.numOfTraveler}})</span>
                        <span v-else-if="traveler && traveler.ageGroup == 30">{{qResultsLang.ageGroupTravel30}}({{traveler.numOfTraveler}})</span>
                        <span v-else-if="traveler && traveler.ageGroup == 40">{{qResultsLang.ageGroupTravel40}}({{traveler.numOfTraveler}})</span>
                        <span v-else-if="traveler && traveler.ageGroup == 50">{{qResultsLang.ageGroupTravel50}}({{traveler.numOfTraveler}})</span>
                        <span v-else-if="traveler && traveler.ageGroup == 60">{{qResultsLang.ageGroupTravel60}}({{traveler.numOfTraveler}})</span>
                        <span v-else-if="traveler && traveler.ageGroup == 70">{{qResultsLang.ageGroupTravel70}}({{traveler.numOfTraveler}})</span>
                        <span v-else-if="traveler && traveler.ageGroup == 80">{{qResultsLang.ageGroupTravel80}}({{traveler.numOfTraveler}})</span>
                        <span v-if="index < ssCriteria.travelerInfos.length - 1">, </span>
                    </span>
                </span>
                <span v-else>
                    <span v-if="ssCriteria.primaryAge != null && ssCriteria.primaryAge > 0">{{ssCriteria.primaryAge}}</span><span v-if="ssCriteria.spouseAge != null && ssCriteria.spouseAge > 0">, {{ssCriteria.spouseAge}}</span>
                    <span v-if="ssCriteria.primaryBday != null && ssCriteria.primaryBday > 0">{{ssCriteria.primaryBday}}</span><span v-if="ssCriteria.spouseBday != null && ssCriteria.spouseBday > 0">, {{ssCriteria.spouseBday}}</span> {{qResultsLang.years}}<span v-if="ssCriteria.numChildren != 0">, {{ ssCriteria.numChildren }} {{qResultsLang.child}}</span>
                </span>
            </span> 
        </div> -->
    </div>
</template>

<script>
    export default {
        data: function () {
            return {
                qResultsLang: window.qResultsLang || null
            }
        },
        props: ['ss-criteria', 'input-summary-content']
    }
</script>
