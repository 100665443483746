export const showGeoModal = function (e, url) {
    var url = url || null;
    let target = e.target;
    if (!target.dataset || (target.dataset && !target.dataset.planId)) target = e.currentTarget;
    if (url != null && url.indexOf('$PLANID') > -1 && e && target && target.dataset) {
        url = url.replace('$PLANID', target.dataset.planId);
        url = url.replace('$PLANTYPE', target.dataset.planType);
        url = url.replace('$CURRENTLANG', target.dataset.currentLang);
    }
    this.showModal(url);
}

export const showTermsModal = function (e, url) {
    let target = e.currentTarget || e.target;
    const isOptionialCoverage = target.dataset.optionalCoverages ? (target.dataset.optionalCoverages == 'true') : false;
    if (!target.dataset || (target.dataset && !target.dataset.planId)) target = e.currentTarget;
    var url = url || null;
    if (url != null && (url.indexOf('$PLANID') > -1 || url.indexOf('$SECTION') > -1) && e && target && target.dataset) {
        url = url.replace('$PLANID', target.dataset.planId);
        url = url.replace('$CURRENTLANG', target.dataset.currentLang);
        url = url.replace('$SECTION', target.dataset.section);
    }
    if (isOptionialCoverage) url += '&termsModal=true';
    this.showModal(url);
}