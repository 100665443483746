import Vue from "vue";
import Vuelidate from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import {
    onlyNumbers,
    numbersWithComma
} from '../../helper-functions/helper-functions';
import radio from '../components.radio-button.vue';

Vue.component('quote-form-trip-age', {
    components: {
        'v-radio': radio,
    },
    data () {
        return {
            modalVisible: false,
            isFormSubmitted: false,
            showByAge: true,
            radioValue: 'age',
            form: {
                travelerInfos: [
                    {
                        age: null,
                        birthdate: '',
                        tripCost: null
                    }
                ]
            }
        }
    },
    validations () {
        let validations = {
            form: {
                travelerInfos: {
                    required,
                    $each: {
                        age: {
                            required,
                            onlyNumbers
                        },
                        tripCost: {
                            required,
                            numbersWithComma
                        }
                    }
                }
            }
        };

        return validations;
    },
    created () {
        this.$root.$on('quote-modal-trip-age', (val) => {
            this.form.travelerInfos = val.travelerInfos || [];
            this.modalVisible = true;
            $('body').addClass('is-Locked--quote');
            setTimeout(() => {
                this.$refs.age0[0].focus();
            }, 250);
        })
        this.$root.$on('reset-quote-modal-trip', ()=>{
            this.resetForm();
        });
    },
    watch: {
        radioValue (val) {
            this.showByAge = val == 'age' ? true : false;
        }
    },
    methods: {
        showModal: function (url) {
            this.$emit('show-modal', url);
        },
        closeModal (resetForm) {
            this.modalVisible = false;
            if (resetForm) { 
                this.resetForm();
                this.$root.$emit('set-quote-modal-trip-age', {}, true);
            }
            $('body').removeClass('is-Locked--quote')
        },
        submitForm () {
            this.isFormSubmitted = true;
            this.$v.form.$touch();
            if (!this.$v.form.$invalid) {
                this.$root.$emit('set-quote-modal-trip-age', JSON.parse(JSON.stringify(this.form.travelerInfos)));
                this.closeModal();
            }
        },
        resetForm () {
            this.$v.$reset();
            this.isFormSubmitted = false;
            this.form = {
                travelerInfos: [
                    {
                        age: null,
                        birthdate: '',
                        tripCost: null
                    }
                ]
            };
        },
        resetAgeValidation: function(index){
            if(this.form?.travelerInfos && this.form.travelerInfos.length && this.form.travelerInfos[index] && !(/^\d+$/.test(this.form.travelerInfos[index].age))){
                this.form.travelerInfos[index].age=null;
            }
        },
        deleteTraveler: function($index) {
            this.form.travelerInfos.splice($index, 1);
        },
        setAge: function (index) {
            if (this.form.travelerInfos && Array.isArray(this.form.travelerInfos) && this.form.travelerInfos.length) {
                this.form.travelerInfos[index].age = this.getAge(this.form.travelerInfos[index].birthdate);
                this.$v.form.travelerInfos.$each[index].age.$touch();
            }
        },
        getAge: function (dateString) {
            // this function will need to be internationalized when needed in other languages using our method createDateByLangStr in helper functions
            // this will also eventually be moved to helper functions
            var today = new Date();
            var birthDate = new Date(dateString);
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate()))
            {
              age--;
            }
            if ((typeof age === "number") && (Math.floor(age) === age) && (0 <= age && age < 200)) {
                return age;
            } else {
                return null;
            }
        },
        addTraveler: function() {
            var newTraveler = {
                tripCost: null,
                age: null,
                birthdate: ''
            };

            this.form.travelerInfos.push(newTraveler);
        }
    }
})