import Vue from "vue";
import Vuelidate from 'vuelidate';
import { required, between, requiredIf } from 'vuelidate/lib/validators';
import {
    onlyNumbers,
    maxNum,
    minNum,
} from '../../helper-functions/helper-functions';

Vue.use(Vuelidate);

Vue.component('quote-form-group-age', {
    data () {
        return {
            modalVisible: false,
            isAgeGrpError: false,
            isFormSubmitted: false,
            isDuplicateTraveler: false,
            form: {
                travelerInfos: [
                    {
                        ageGroup: null,
                        numOfTraveler: null
                    }
                ],
                citizenshipCountry: ''
            }
        }
    },
    validations () {
        let validations = {
            form: {
                travelerInfos: {
                    required,
                    $each: {
                        ageGroup: {
                            required,
                            onlyNumbers
                        },
                        numOfTraveler: {
                            required,
                            onlyNumbers,
                            maxNum: maxNum(30000),
                            minNum: minNum(1),
                        }
                    },
                    minTotal: function() {
                        return this.travelersTotal >= 2; 
                    }
                }
            }
        }

        return validations;
    },
    created () {
        this.$root.$on('quote-modal-group-age', (val) => {
            this.form.travelerInfos = val.travelerInfos || [];
            this.form.citizenshipCountry = val.citizenshipCountry;
            this.modalVisible = true;
            $('body').addClass('is-Locked--quote')
        })
        this.$root.$on('reset-quote-modal-group', ()=>{
            this.resetForm();
        });
    },
    methods: {
        showModal: function (url) {
            this.$emit('show-modal', url);
        },
        setId: function (e, value, travelIndex, dlt) {

            var isPresent = $("#"+value+"ageGroup");
            var $thisElem = e.currentTarget ? $(e.currentTarget) : e;
            var travelerIndex = travelIndex;

            $("#"+travelerIndex+"errorAgeGroup").attr("data-opt", value);
            if(isPresent.length && dlt !== "dlt"){                 
                $("#"+travelerIndex+"errorAgeGroup").addClass("showErrorAgeGroup");
                this.isDuplicateTraveler = true;
            } else {                
                $("#"+travelerIndex+"errorAgeGroup").removeClass("showErrorAgeGroup");
                this.isDuplicateTraveler = false;
            }
            
            if(dlt != "dlt") {
                $thisElem.attr("id",value+"ageGroup");
            } else {
                $thisElem.attr("id","");
            }

            var $error = $(".showErrorAgeGroup");
            if($error.length) {
                for(var i = 0 ; i < $error.length ; i++) {
                    var id = $($error[i]).attr("id");
                    var index = id.slice(0,$($error[i]).attr("id").indexOf("error"));
                    if(travelerIndex != index && (this.form.travelerInfos.length -1) >= index ) {
                        var ageGrp = this.form.travelerInfos[index].ageGroup;
                        if($("[id="+ageGrp+"ageGroup]").length >1) {

                        } else {
                            $($error[i]).removeClass("showErrorAgeGroup");
                            this.isDuplicateTraveler = false;
                        }                     
                    }
                }
            }

            var self = this;
            setTimeout(function () {
                var ids = [],errorEl,index; 
                var $elm = document.querySelectorAll(".c-Travelers__age-col_medium select");
                Array.prototype.forEach.call($elm, function(el,i) {
                    if(!el.id) {
                        if(self.form.travelerInfos[i].ageGroup != null) {
                            el.id = self.form.travelerInfos[i].ageGroup + "ageGroup";
                        }
                    }
                });

                $("[id*=ageGroup]").each(function(i,el){
                    if(ids.indexOf(el.id) == -1) ids.push(el.id);
                    else {errorEl = el;index = i}
                });
                if(errorEl) {
                    if(self.form.travelerInfos[index].ageGroup !== null){                    
                        $("#"+index+"errorAgeGroup").addClass("showErrorAgeGroup");
                        self.isDuplicateTraveler = true;
                        self.isAgeGrpError = true;
                    }
                }else{
                    self.isAgeGrpError = false;
                }                
            },10);
        },
        addTraveler: function() {
            var newTraveler = {
                ageGroup: null,
                numOfTraveler: null
            };
            if (!this.isDuplicateTraveler && !this.$v.form.travelerInfos.$each.$error) this.form.travelerInfos.push(newTraveler);
        },
        deleteTraveler: function($index,e, value) {
            this.form.travelerInfos.splice($index, 1);
            var elm = $(e.target).closest(".c-Travelers__input-wrap").find("select")
            $("#"+$index+"errorAgeGroup").removeClass("showErrorAgeGroup");
            this.isDuplicateTraveler = false;
            this.setId(elm, value, $index, "dlt");
        },
        closeModal (resetForm) {
            this.modalVisible = false;
            if (resetForm) { 
                this.resetForm();
                this.$root.$emit('set-quote-modal-group-age', {}, true);
            }
            $('body').removeClass('is-Locked--quote')
        },
        submitForm () {
            this.isFormSubmitted = true;
            this.$v.form.$touch();
            if (!this.$v.form.$invalid && !this.isDuplicateTraveler) {
                this.$root.$emit('set-quote-modal-group-age', JSON.parse(JSON.stringify(this.form.travelerInfos)));
                this.closeModal();
            }
        },
        resetForm () {
            this.$v.$reset();
            this.isFormSubmitted = false;
            this.isDuplicateTraveler = false;
            this.form = {
                travelerInfos: [
                    {
                        ageGroup: null,
                        numOfTraveler: null
                    }
                ]
            };
        }
    },
    computed: {
        travelersTotal: function() {
        	return this.form.travelerInfos.reduce((total, traveler) => {
        		if(isNaN(traveler.numOfTraveler)){
        			return total;
        		}
                return total + Number(traveler.numOfTraveler);
            }, 0);
        }
    }
});