<template>
    <span class="c-Plan-actions__options c-Plan-actions__options--filter" :class="{'c-Q-Res-Actions__item-disable':disable}">
        <label @click.prevent="$emit('triggernarrowfn')">
            {{globalLang.filterPlans}} <span>({{narrowCount}} {{globalLang.selected}})</span>                                                                        
        </label>                                
    </span>
</template>

<script>
export default {
    name: 'FilterQuote',
    props: ['narrowCount', 'disable'],
    data () {
        return {
            globalLang: window.globalLanguage
        }
    },
    methods: {

    }
}
</script>