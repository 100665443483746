
    import dayjs from 'dayjs';
    import accounting from 'accounting';
    import HandleDocument from './component.handle-documents.vue'
    import { 
        convertDateString,
        convertFormattedServerTime,
        getDateFromLanguage,
        } from '../../helper-functions/helper-functions';

    import {
        getItemSessionStorage,
        setItemSessionStorage
    } from '../../helper-functions/services';
    import Azimuth from '../../helper-functions/companies/azimuth-planids';

    const azimuthPlans = new Azimuth();
    
    export default {
        props:  {
            companyCode: {
                type: String,
                required: true
            },
            groupPolicy: {
                type: Boolean,
                required: true
            },
            allPersonList: {
                type: Array,
                required: true
            },
            showPersonTypeNum: {
                type: Boolean,
                default: true
            },
            showDocumentFiles: {
                type: Boolean,
                default: true
            },
            showPersonIndex: {
                type: Array,
                required: false,
                default: () => []
            },
            hasPersonLabel: Boolean,
            showTravelLabel: Boolean,
            showEmail: Boolean,
            showPolicyStatus: Boolean, // For logic to execute for changes in commit 65d51430e9905feb85638ae5eb2c430c575b0ae0  19 Jun 2020
            policyListCollectionItem: Object, // REQUIRED??
            showIdCard: Boolean,
            showVisaLetter: Boolean, 
            showThailandDoc: Boolean, 
            showStatement: Boolean, 
            showCertWord: Boolean, 
            showCoverageDates: Boolean,
            showTripCost: Boolean,
            showCancellationStatus: Boolean,
            showOptionalCoverage: Boolean,
            showRelationship: Boolean,
            visaLetterLanguageModal: Function, // Function for ARS Visa Letter download Modal
            showDocument: Function, // Download document function for myAccount page.
            planId: Number
        },
        components: {
            'v-document': HandleDocument
        },
        data:function(){
            return {
                qResultsLang: window.qResultsLang || null,
                allPersonLang: {},
                checkedIdCard: false,
                checkedVisaLetter: false,
                hasIdCard: false,
                hasVisaLetter: false,
                clientCenterForm: {
                    url: null,
                    type: null,
                    cert: null,
                    dob: null,
                    reqlang: null,
                    memberid: null,
                },
                showDocumentShare: false,
                showLang: true,
                myAccountLink: ''
            }
        },
        created: function() {
            // Get Lang From Session
            let allPersonTableLang = getItemSessionStorage(`all_person_${window.globalLanguage.lang}`);
            allPersonTableLang = allPersonTableLang ? JSON.parse(allPersonTableLang) : null;

            if (allPersonTableLang && allPersonTableLang.lang === window.globalLanguage.lang) {
                 this.allPersonLang = allPersonTableLang.allPersonLang;
            }else {
                // Get Language from API
                let requestURL = location.hostname;
                if (location.hostname === 'brokersnexustest.com') {
                    requestURL = 'insubuy20test';
                }
                else if (location.hostname.indexOf('brokersnexus') > -1) { // BN
                    requestURL = 'sample.brokersnexus.com';
                }
                else if (location.port !== '') {
                    requestURL = `${location.hostname}:${location.port}`;
                }
                this.$http.get('https://' + requestURL + '/api/get-properties/?fileName=all-person-table&lang=' + window.globalLanguage.lang).then(res => {
                    if (res && res.body?.allPersonTable) {
                        this.allPersonLang = res.body.allPersonTable;
                        // Store in Session
                        let langObj = {
                            allPersonLang: res.body.allPersonTable,
                            lang: window.globalLanguage.lang
                        }
                        setItemSessionStorage(`all_person_${window.globalLanguage.lang}`, JSON.stringify(langObj));
                    }
                });
            }
            
        },
        mounted () {
            if (azimuthPlans.isMeridianPlan(this.planId) && this.allPersonList.length > 1) {
                for (let i = 1; i < this.allPersonList.length; i++) {
                    this.allPersonList[i].planId = this.planId;
                    this.allPersonList[i].primaryCert = this.allPersonList[0].certNumber;
                    this.allPersonList[i].primaryDob = this.allPersonList[0].birthDate;
                }
            }

            if (window.qResultsLangGlobal.mobileApp) {
                this.myAccountLink = window.qResultsLangGlobal.myAccountLink;
            }
        },
        filters: {
            currencyNoCent: function (value) {
                if (value === null) {
                    return '';
                } else {
                    accounting.settings = {
                        currency: {
                            symbol : "$",   // default currency symbol is '$'
                            format: "%s%v", // controls output: %s = symbol, %v = value/number (can be object: see below)
                            decimal : ".",  // decimal point separator
                            thousand: ",",  // thousands separator
                            precision : 0   // decimal places 0 for none
                        },
                        number: {
                            precision : 0,  // default precision on numbers is 0
                            thousand: ",",
                            decimal : "."
                        }
                    }
                    return accounting.formatMoney(value);
                }
            },
            convertDates: function (value) {
                if (value == null) {
                    return '';
                } else {
                    return getDateFromLanguage(value);
                }
            }
        },
        computed: {
            showMobileViewDoc() {
                return (this.hasIdCard || this.hasVisaLetter || this.policyListCollectionItem?.thailandFormAvailable || this.showStatement);
            },
            isImgGroup() {
                return this.companyCode === 'IMG' && this.groupPolicy;
            }
        },
        methods: {
            calculateChildIndex(travelerList, index) {
                let difference = 0;
                let hasSpouse = false;
                if (travelerList && travelerList.length > 0) {
                    travelerList.forEach(e => {
                    if (e.travelerType === 'spouse') {
                        hasSpouse = true;
                    }
                    });
                }
                if (hasSpouse) difference++;
                return index - difference;
            },
            checkDocumentExists(document, personList) {
                if (!this.showDocumentFiles) return false
                let result = false;
                // Check if already scanned for idCards and visaLetter and return
                if (document === 'idCard' && this.checkedIdCard) return this.hasIdCard;
                else if (document === 'visaLetter' && this.checkedVisaLetter) return this.hasVisaLetter;
                else if (document === 'thailand') return this.policyListCollectionItem.thailandFormAvailable;
                else if (personList) { // Check if at least one person has the document and update
                    for (let i=0; i < personList.length; i++) {
                        if (document === 'idCard' && personList[i].idCardUrl) {
                            this.hasIdCard = true;
                            result = true;
                            break;
                        }
                        else if (document === 'visaLetter' && (personList[i].visaLetterdUrl || personList[i].visaLetterUrl)) {
                            this.hasVisaLetter = true;
                            result = true;
                            break;
                        }
                    }
                }
                if (document === 'idCard') this.checkedIdCard = true;
                else if (document === 'visaLetter') this.checkedVisaLetter = true;

                return result;
            },
            getURLForCertWordingInPersonalLevel(certWordUrl, policyList) {
                if (this.companyCode !== 'Trawick') return true;

                let policy = policyList[policyList.length-1];

                if (policy.companyCode === 'Trawick') {
                    let cutDate = dayjs('08/21/2016', this.defaultDateFormat);

                    if (typeof policy.transactionDate === 'number') {
                    console.debug('transaction date error : ', policy.transactionDate, policy.certificateNumber);
                    policy.transactionDate = new Date(policy.transactionDate).toISOString();
                    }
                    
                    let transactionDate = dayjs(convertDateString(policy.transactionDate, 'en'), this.defaultDateFormat);
                    if (transactionDate.isAfter(cutDate)) {
                    return certWordUrl;
                    } else {
                    return null;
                    }
                }
            },
            showCoverageDateInParent(x) {
                if(this.groupPolicy) {
                    if (x.policyList[0].productCode === 'WorldTrips_730' && x.personsHistory && x.personsHistory.length > 0) {
                    let effectiveDateList = new Set(x.personsHistory.map(e=>e.effectiveDate));
                    let expirationDateList = new Set(x.personsHistory.map(e=>e.expirationDate));
                    return effectiveDateList.size === 1 || expirationDateList.size === 1;
                    }
                }

                let personsHistory = x.personsHistory;
                let showPersonsHistory = x.showPersonsHistory;

                if (showPersonsHistory) {
                    if (personsHistory && personsHistory.length > 0) {
                    let effectiveDateList = new Set(personsHistory.map(e=>e.effectiveDate));
                    let expirationDateList = new Set(personsHistory.map(e=>e.expirationDate));
                    return effectiveDateList.size === 1 && expirationDateList.size === 1;
                    }
                }

                return !this.groupPolicy;
            },
            getCoverageDateByARSPerson(personHistory) {
                let beginDate = convertDateString(personHistory.effectiveDate);
                let finalDate = convertDateString(personHistory.expirationDate);
                return `${beginDate} - ${finalDate}`;
            },
            getDateRange(policy, application) { // Modified ver. of method in my-account-landing 
                let startDate = policy.effectiveDate;
                let endDate = policy.expirationDate;

                if (startDate && typeof startDate !== 'string') startDate = new Date(startDate).toISOString();
                if (endDate && typeof policy.expirationDate !== 'string') endDate = new Date(endDate).toISOString();
                if (!startDate || !endDate) return null;
                
                let beginDate = application.planId !== 730 ? convertDateString(startDate) : convertFormattedServerTime(startDate, 1);
                let finalDate = application.planId !== 730 ? convertDateString(endDate) : convertFormattedServerTime(endDate, 1);

                if (this.companyCode === "IMG" && application.planType !== 'tripplan') {
                    finalDate = `${finalDate} ${this.allPersonLang.formattedExpIMGDate}`
                }
                return `${beginDate} - ${finalDate}`;
            },
            getLanguageDate(d) {
                if (!d) {
                    return 'N/A';
                }
                if (d) {
                    if (typeof d === 'number') {
                    return convertDateString(new Date(d).toISOString());
                    } else {
                    if (d.indexOf('T') > 0) {
                        return convertDateString(d);
                    }
                    }
                }
                return getDateFromLanguage(d);
            },
            showCancellationSchedule(y) {
                return ((this.companyCode === 'HCCMIS' || this.companyCode === 'WorldTrips' || this.companyCode === 'ARS') && this.policyListCollectionItem?.cancellationSchedulePerPerson && y.cancellationSchedule)
            },
            //TODO: get lang text from api
            getPersonRiders(y, company) {
                let result = ''
                if (company === 'ARS') {
                    if (y.arsExtremeSportsRider) result = this.allPersonLang.extremeSports;
                    if (y.arsDentalRider) result = result ? result + ', ' + this.allPersonLang.dental : result + this.allPersonLang.dental;
                    return result;
                }
                else if (company === 'IMG') {
                    if (y.arsExtremeSportsRider) result = this.allPersonLang.extremeSports;
                    if (y.imgEvacPlusRider === 'yes') {
                    result = this.allPersonLang.evacPlus;
                    }
                    if (y.imgDeviceProtectionRider === 'yes') {
                    result = result ? result + ', ' + this.allPersonLang.deviceProtection : result + this.allPersonLang.deviceProtection 
                    }
                    return result;
                }
            },
            hasDependent(idx) {
                return (this.allPersonList.length == idx+1 || this.allPersonList[idx+1].personType === 'spouse' || this.allPersonList[idx+1].personType === 'child')
            },
            showCancellationPerson(y) {
                if(y.cancellationStatus && y.cancellationStatus === 'Pending' && this.policyListCollectionItem?.status === 'INACTIVE') return false;
                else if (y.cancellationStatus) return true;
                else return false;
            },
            toggleDocumentShare (handlDocs) {
                let appInfo = {}; 
                if(this.policyListCollectionItem.applicationList && this.policyListCollectionItem.applicationList[0]) {
                    if(this.policyListCollectionItem.applicationList[0].fullAppId) {
                        appInfo.fullAppId = this.policyListCollectionItem.applicationList[0].fullAppId;
                    } 
                    if(this.policyListCollectionItem.applicationList[0].retrieveAppUrl) {
                        appInfo.retrieveAppUrl = this.policyListCollectionItem.applicationList[0].retrieveAppUrl;
                    } 
                    if(this.policyListCollectionItem.applicationList[0].planId) {
                        appInfo.planId = this.policyListCollectionItem.applicationList[0].planId;
                    } 
                }

                this.$root.$emit('toggleDocumentShare', !this.showDocumentShare, handlDocs, appInfo);
            },
            handleDocuments (type, y, action, showLang) {
                let idNo = y.insuredID || y.memberId;
                
                // Direct Download / Share without language option
                if (action == 'download' && !showLang) {
                    this.showDocument(this.companyCode, type, y, null, null, idNo);
                } else if (action === 'share' && !showLang) {
                    let handlDocs = [this.companyCode, type, y, null, null, idNo];
                    this.toggleDocumentShare(handlDocs);
                }

                // Show Language option first
                if (showLang) {
                    this.visaLetterLanguageModal(true, this.policyListCollectionItem, null, y, showLang, action);
                }
            }
        },

    }
