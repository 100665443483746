(function($) {
    $('.js-Tabs .js-Tabs__tab-mobile').click(function(e){
        e.preventDefault();

        var $this = $(this);
        var tab_id = $this.attr('data-tab');
        var tab = $('.js-Tabs .js-Tabs__tab-mobile');
        var tab_content = $('.js-Tabs__content');
        var current_tab_class = 'is-Current';

        if ( $this.hasClass(current_tab_class) ) {
            removeTabClass();
        } else {
            removeTabClass();

            $this.addClass(current_tab_class);
            $("#"+tab_id).addClass(current_tab_class);
        }

        function removeTabClass () {
            tab.removeClass(current_tab_class);
            tab_content.removeClass(current_tab_class);
        }

    });

    $('.js-Tabs__tab-wrap').hover(
        function () {
            $(this).find('.c-Actions-H__dropdown').addClass('is-Current');
            $(this).find('.js-Tabs__tab').addClass('is-Current');
        }, function() {
            $(this).find('.c-Actions-H__dropdown').removeClass('is-Current');
            $(this).find('.js-Tabs__tab').removeClass('is-Current');
        }
    );
})(jQuery);
