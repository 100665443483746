import {
         showGeoModal,
         showTermsModal
        } from '../helper-functions/plan-details-functions';
import Checkbox from '../components/components.checkbox.vue'
import {ibSpinOpts} from '../helper-functions/applicationCommonValues';

export default {
    components:{
        'v-checkbox': Checkbox
    },
    props: {
        allMinimized: Boolean,
        hideCompare: Boolean,
        planid: {
            type: String,
            required: true,
        },
        planName: String,
        planColor: String,
        planCode: String,
        planType: String
    },
    data: function() {
        return {
            minimized: false,
            showPPO: true,
            showAcuteOnsetLink: false,
            acuteOnsetPreExisting: false,
            compare: false,
            isPlanDetailsVisible: false,
            isPriceBreakdownVisible: false,
            loading: false
        }
    },
    created: function(){
        this.setAcuteOnset()
        try{
            if(sessionStorage.getItem("allMinimized"))
                this.minimized = sessionStorage.getItem("allMinimized") === 'true';
        }catch(e){}
        this.$root.$on('toggle-compare', (compare) => {
            this.compare = compare || false;
        })
        this.$root.$on('toggle-compare-by-id', (compare, planId) => {
            if (this.planid === planId) this.compare = compare;
        })
    },
    mounted() {
        this.loading = false;
    },
    watch: {
        allMinimized: function(value) {
            this.minimized = value;
        },
        '$route'() {
            this.loading = false;
        }
    },
    methods: {
        showModal: function (url) {
            this.$emit('show-modal', url);
        },
        minimizeBlock: function() {
            this.minimized = true;
            this.$nextTick(() => {
                this.$emit('check-if-all-minimized')
            })
        },
        maximizeBlock: function() {
            this.minimized = false;
            this.$nextTick(() => {
                this.$emit('check-if-all-maximimzed')
            })
        },
        setAcuteOnset() {
            if (window.ibBackendData?.plansRendered?.length) {
                let plan = window.ibBackendData.plansRendered.filter(plan => plan.planName === this.planName)?.[0];
                if (plan) {
                    this.acuteOnsetPreExisting = plan.config.acuteOnsetPreExisting || false;
                } else {
                    this.acuteOnsetPreExisting = false;
                }
            }
        },
        showGeoModal,
        showTermsModal,
        toggleCompare: function () {
            var policyMaxi = 0;
            if(this.section ==='studentmedical'){
    		     if(this.policyMax >= 100000 && this.policyMax <= 125000){
    		                   policyMaxi=40;
    		        } else if(this.policyMax >= 130000 && this.policyMax <= 175000){
    		                   policyMaxi=50;
    		        }
            }            

            var plan = {
                planId: this.planid,
                displayName: this.planName,
                displayCode: this.planCode,
                planColor: this.planColor,                
                planType: this.planType                
            };

            if (this.compare) {
                this.$root.$emit('add-compare', plan);
            } else {
                this.$root.$emit('remove-compare', this.planid);
            }
        },
        toggleMobileTab: function(tab) {
            if (tab === "details") {
                this.isPlanDetailsVisible = !this.isPlanDetailsVisible

                this.isPriceBreakdownVisible = false;

            } else if (tab === "price") {
                this.isPriceBreakdownVisible = !this.isPriceBreakdownVisible

                this.isPlanDetailsVisible = false;
            }
        },
        quotePurchase() {
            this.loading = true;
            var spinTarget = this.$refs.quotePurchaseButton;
            var spinner = new IBSpinner(ibSpinOpts).spin(spinTarget);
            setTimeout(() => {
                this.loading = false;
            }, 3000)
        }   
    },
};