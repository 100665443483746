(function($) {
    $.fn.ibCycle = function(speed) {
        var $self = $(this);
        var items = $self.find('.o-Cycle__item');
        var i = 0;

        setInterval(function() {
            setActive(i);

            if (i === items.length - 1) {
                i = 0;
            } else {
                i++;
            }
        }, speed);

        var setActive = function(i) {
            items.removeClass('js-Active');
            items.eq(i).addClass('js-Active');
        };

        return this;

    };
})(jQuery);
