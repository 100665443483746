const clientsViewPreference = 'clients-view-preference';
const sessionKeysToRetain = [ clientsViewPreference,
                             'displayTabName',
                             'listLayout',
                             'sectionStorage',
                             'tabToView',
                             'allMinimized',
                             'thumbLayout',
                             'setclientCenterTasksList',
                             'setclientCenterInsurancePlansList',
                             'cts-quoteId',
                             'cts-applicationId',
                             'cts-certificateNumber',
                            'inbound-call',
                            'cts-username',
                            'plan-criteria',
                            'plan-criteria-stp2',
                            'fullQuoteId']
export {
  clientsViewPreference,
  sessionKeysToRetain,
}