class IMG {
    constructor () {
        this.Patriot_Multi_Trip = 24
        this.GeoGroup = 28
        this.Outreach_America = 401
        this.Outreach_International = 402
        this.Global_Medical_Insurance = 405
        this.Patriot_Exchange_Program = 406
        this.International_Marine_Medical_Insurance = 409
        this.Student_Health_Advantage_Parent = 421 
        this.Outreach_Group = 422
        this.Patriot_Group_Exchange_Program = 524
        this.Student_Health_Advantage_Standard = 532 
        this.Student_Health_Advantage_Platinum = 546 
        this.GlobeHopper_Senior_Single_Trip = 557
        this.GlobeHopper_Senior_Multi_Trip = 558
        this.Patriot_America_Plus = 563
        this.Patriot_America_Plus_Group = 564 
        this.MPPlus_International = 648
        this.Travel_Lite = 675
        this.Travel_LX = 676
        this.Travel_LX_All_Other_States = 677
        this.Travel_LX_Florida = 678
        this.Travel_LX_Kansas_Missouri_Virginia = 679
        this.Travel_LX_Washington = 680
        this.Travel_SE = 681 
        this.Travel_SE_All_Other_States = 682
        this.Travel_SE_Florida = 683
        this.Travel_SE_Kansas_Missouri_Virginia = 684
        this.Travel_SE_Washington = 685
        this.Patriot_America_Lite = 742
        this.Patriot_International_Lite = 743
        this.Visitors_Care_Parent = 744
        this.Patriot_America_Lite_Group = 745 
        this.Patriot_International_Lite_Group = 746 
        this.Visitors_Care_Lite = 747
        this.Visitors_Care_Plus = 748
        this.Visitors_Care_Platinum = 749
        this.Patriot_America_Platinum = 751
        this.Patriot_International_Platinum = 752
        this.Patriot_America_Platinum_Group = 753
        this.Patriot_International_Platinum_Group = 754
        this.Visitors_Protect = 755
    }

    allInAppIds () {
        return [
            this.Patriot_Exchange_Program,
            this.Student_Health_Advantage_Parent,
            this.Student_Health_Advantage_Standard,
            this.Student_Health_Advantage_Platinum,
            this.Patriot_America_Plus,
            this.Travel_Lite,
            this.Travel_LX,
            this.Travel_LX_All_Other_States,
            this.Travel_LX_Florida,
            this.Travel_LX_Kansas_Missouri_Virginia,
            this.Travel_LX_Washington,
            this.Travel_SE,
            this.Travel_SE_All_Other_States,
            this.Travel_SE_Florida,
            this.Travel_SE_Kansas_Missouri_Virginia,
            this.Travel_SE_Washington,
            this.Patriot_America_Lite,
            this.Patriot_International_Lite,
            this.Visitors_Care_Parent,
            this.Visitors_Care_Lite,
            this.Visitors_Care_Plus,
            this.Visitors_Care_Platinum,
            this.Patriot_America_Platinum,
            this.Patriot_International_Platinum,
            this.Visitors_Protect
        ]
    }

    isTripInsurance () {
        return [
            this.Travel_Lite,
            this.Travel_LX,
            this.Travel_LX_All_Other_States,
            this.Travel_LX_Florida, 
            this.Travel_LX_Kansas_Missouri_Virginia,
            this.Travel_LX_Washington,
            this.Travel_SE,
            this.Travel_SE_All_Other_States,
            this.Travel_SE_Florida,
            this.Travel_SE_Kansas_Missouri_Virginia,
            this.Travel_SE_Washington
        ];
    }

    allPlanIds () {
        return [
            this.Patriot_Multi_Trip,
            this.GeoGroup,
            this.Outreach_America,
            this.Outreach_International,
            this.Global_Medical_Insurance,
            this.Patriot_Exchange_Program,
            this.International_Marine_Medical_Insurance,
            this.Student_Health_Advantage_Parent,
            this.Outreach_Group,
            this.Patriot_Group_Exchange_Program,
            this.Student_Health_Advantage_Standard,
            this.Student_Health_Advantage_Platinum,
            this.GlobeHopper_Senior_Single_Trip,
            this.GlobeHopper_Senior_Multi_Trip,
            this.Patriot_America_Plus,
            this.Patriot_America_Plus_Group,
            this.MPPlus_International,
            this.Travel_Lite,
            this.Travel_LX,
            this.Travel_LX_All_Other_States,
            this.Travel_LX_Florida,
            this.Travel_LX_Kansas_Missouri_Virginia,
            this.Travel_LX_Washington,
            this.Travel_SE,
            this.Travel_SE_All_Other_States,
            this.Travel_SE_Florida,
            this.Travel_SE_Kansas_Missouri_Virginia,
            this.Travel_SE_Washington,
            this.Patriot_America_Lite,
            this.Patriot_International_Lite,
            this.Visitors_Care_Parent,
            this.Patriot_America_Lite_Group,
            this.Patriot_International_Lite_Group,
            this.Visitors_Care_Lite,
            this.Visitors_Care_Plus,
            this.Visitors_Care_Platinum,
            this.Patriot_America_Platinum,
            this.Patriot_International_Platinum,
            this.Patriot_America_Platinum_Group,
            this.Patriot_International_Platinum_Group,
            this.Visitors_Protect
        ]
    }
}

export default IMG;