(function($) {
    $.fn.ibEProtect = function() {
        var $self = $(this);    
        var original = $self.attr('href');
        if(original && original != "") {            
            var afterColon = original.substr(original.indexOf(":") + 1);
            var beforeColon = original.substr(0, 7);
        

            $self.on('mouseenter', function() {
                var charFlip = afterColon.split('').reverse().join('');
                var corrected = beforeColon + charFlip + '@insubuy.com';
                $self.attr('href', corrected);
            });

            $self.on('mouseleave', function() {
                $self.attr('href', original);
            });
        }
        return this;
    };
})(jQuery);
