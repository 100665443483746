
/* already import via main-vue */
// var datepicker = require('../../third-party/eternicode-datepicker');
// import datepicker from 'bootstrap-datepicker';
// require('bootstrap-datepicker/dist/locales/bootstrap-datepicker.es.min');
// require('bootstrap-datepicker/dist/locales/bootstrap-datepicker.zh-CN.min');
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import {createDateByLangStr, addOrSubtractDate, convertDateString, isChromeBrowser, dateInsert} from '../../helper-functions/helper-functions';
var lang = window.globalLanguage.lang;
if (lang === 'es') {
  dayjs.locale('es');
}
if (lang === 'ar') {
  dayjs.locale('es'); // arabic and spanish share same format
}
//if (lang === 'zh') {
  //dayjs.locale('zh-cn')
  //uses formal Chinese format 'YYYY[年]MM[月]DD[日]'
//}

export default {
    props: ['value', 'startDate', 'endDate', 'qResultsLang', 'disabled', 'datesDisabled', 'daysOfWeekDisabled'],
    mounted: function() {
        var self = this;
        var $el = $(this.$el);
        this.dateIcon = $el.find('span');
        var $dateInput = $el.find('input');
        $dateInput[0].type = 'tel';
        var startDate = $dateInput.attr("data-startdate");
        var endDate = $dateInput.attr("data-enddate");
        var startView = $dateInput.attr("data-startview");
        var todayHighlight = $dateInput.attr("data-todayhighlight");
        
        if (todayHighlight == "false") todayHighlight = false;
        else todayHighlight = true;

        var datepickerLang;
        $dateInput.attr("maxLength", 10);

        switch (lang) {
          case 'es':
            datepickerLang = 'es';
            break;
          case 'ar':
            datepickerLang = 'ar';
            break;
          case 'zh':
            datepickerLang = 'zh-CN';
            break;
          case 'ja':
            datepickerLang = 'zh-CN';
            break;
          case 'ko':
            datepickerLang = 'zh-CN';
            break;
          default:
            datepickerLang = 'en';
        }
        let imgTripPlan = ibJS.imgTripPlan;
        if(window.ibJS.planConfig && (["GU","Travelex","TravelInsured","Careington"].indexOf(window.ibJS.planConfig.companyCode) > -1 || imgTripPlan) && $dateInput[0].id.indexOf("birthDate") >= 0) {
          if($dateInput[0].id.indexOf("beneficiary") == -1) {
            var thisIndex = $el.attr("data-index");
            $el.find("input").attr("id",'traveler_'+thisIndex+'_birthDate');
            $el.find("input").attr("name",'travelersInfo['+thisIndex+'].birthDate');
            $el.find("input").attr("autocomplete",'form.traveler_'+thisIndex+'_birthDate');
          }
        }
        if(this.dateIcon && typeof this.dateIcon === 'object' && typeof this.dateIcon.datepicker === 'function') {
          let endDateDefault = datepickerLang == 'es' ? '31/12/9999' : datepickerLang.indexOf('zh') > -1 ? '9999-12-31' : '12/31/9999';
          this.dateIcon.datepicker({
              viewMode: 'years',
              autoclose: true,
              language: datepickerLang,
              todayHighlight: todayHighlight,
              startDate: startDate || this.startDate || '',
              startView: parseInt(startView) || 0,
              enableOnReadonly: this.disabled,
              daysOfWeekDisabled: this.daysOfWeekDisabled || [],
              endDate: endDate || this.endDate || endDateDefault
          })
          .on('show', function(e) {
              if($dateInput[0].id.toLowerCase().indexOf("birthdate") > -1) {
                $(".datepicker.dropdown-menu > div").hide();
                $(".datepicker-decades").show();
                $(".datepicker-decades .datepicker-switch").html("1900-1990");
                $(".datepicker-decades tbody tr td").html('<span class="decade old">1890</span><span class="decade">1900</span><span class="decade">1910</span><span class="decade">1920</span><span class="decade">1930</span><span class="decade">1940</span><span class="decade">1950</span><span class="decade">1960</span><span class="decade">1970</span><span class="decade focused">1980</span><span class="decade">1990</span><span class="decade new">2000</span>');
              }
          })
          .on('changeDate', function(e) {
              var newDate;
              switch (lang) {
                case 'es':
                  newDate = dayjs(e.date).format('DD/MM/YYYY');
                  break;
                case 'ar':
                  newDate = dayjs(e.date).format('DD/MM/YYYY');
                  break;
                case 'zh':
                  newDate = dayjs(e.date).format('YYYY-MM-DD');
                  break;
                case 'ja':
                  newDate = dayjs(e.date).format('YYYY-MM-DD');
                  break;
                case 'ko':
                  newDate = dayjs(e.date).format('YYYY-MM-DD');
                  break;
                default:
                  newDate = dayjs(e.date).format('MM/DD/YYYY');
              }
              // INSUBUY-4329 replaces old if conditions
              if(newDate !== this.value) {
                self.changeDate(newDate);
              }
              /*
              * Do not check conditions for id's instead add the following attributes to the input
              * date-type='single' (this is a default attr to know date-picker is used)
              * date-model="object.key" (this attr carries the v-model key) for ex: if you use form.birthdate then put date-model="birthdate"
              */
              if($dateInput[0].id === 'start' && $dateInput[0].form.__vue__){
                $dateInput[0].form.__vue__.form.startDate=newDate;
              }else if($dateInput[0].id === 'end' && $dateInput[0].form.__vue__){
                $dateInput[0].form.__vue__.form.endDate=newDate;
              }else if($dateInput[0].id === 'depart' && $dateInput[0].form.__vue__){
                $dateInput[0].form.__vue__.form.departureDate=newDate;
              }else if($dateInput[0].id === 'return' && $dateInput[0].form.__vue__){
                $dateInput[0].form.__vue__.form.returnDate=newDate;
              }else if($dateInput[0].id === 'deposit' && $dateInput[0].form.__vue__){
                $dateInput[0].form.__vue__.form.initialTripDepositDate=newDate;
              }else if($dateInput[0].id === 'arrival' && $dateInput[0].form.__vue__){
                $dateInput[0].form.__vue__.form.arrivalDateUS=newDate;
              }else if($dateInput[0].id === 'expiration' && $dateInput[0].form.__vue__){
                $dateInput[0].form.__vue__.form.intlInsuranceExpDate=newDate;
              }
              else{
                $dateInput[0].value=newDate;
                if($dateInput[0].id.indexOf("birthDate") >= 0){
                  self.$emit('reset_birth_date', $dateInput[0].id, "picker");
                  self.validateDynamic('birthDate', $(e.target.parentElement).find("[data-index]").attr("data-index"), ['required','birthDate']);
                }
              }
              if($dateInput[0].getAttribute("date-type") == "single"){
              if(self.$parent.form[$dateInput[0].getAttribute("date-model")] != undefined) {
                  self.$parent.form[$dateInput[0].getAttribute("date-model") ]= newDate;
              }
              }
              self.checking = true;
          });
        }
        //INSUBUY-4282
        if($dateInput[0]) {
          $dateInput[0].addEventListener('blur' , e => $dateInput[0].value = e.target.value.trim() );
        }
        // Auto insert '/' or '-' for dates
        if($dateInput[0]) {
          $dateInput.on("keydown", function(e) {
            $(this).data('oldVal', $(this).val());
            $(this).data('cursorIdx', $dateInput[0].selectionStart);
            $(".datepicker").hide();
          }).on("paste keyup", function(e) {
            if (e.type == 'paste') {
              e.preventDefault();
              const clipValue = e.originalEvent.clipboardData?.getData("text/plain");
              if (clipValue) {
                const cleanValue = clipValue.trim();
                $(this).val(cleanValue);
              }
            }
            let oldVal = ($(this).data('oldVal')); 
            let cursorIdx = ($(this).data('cursorIdx')); 
            let newVal = ($(this).val()); 
            if (newVal) {
              $dateInput[0].value = dateInsert(newVal, oldVal, cursorIdx);
              $dateInput[0].dispatchEvent(new Event('input'))
            }
          });
        }

        $el.find('.o-Icon-menu__date').click(function(e) {
            // use try catch to avoid edge unspecifired error here
            try{
              if($(".datepicker.datepicker-dropdown").hasClass("dp-sm-sd")){
                $(".datepicker.datepicker-dropdown").removeClass("dp-sm-sd");
              }
              self.dateIcon.datepicker('show');
              self.dateIcon.focus();
              var isPrevElem = $(e.target).prev().is("#start") || $(e.target).prev().is("#depart") || $(e.target).prev().is("#deposit");
              if(window.outerWidth < 415 && isPrevElem){
                $(".datepicker.datepicker-dropdown").addClass("dp-sm-sd");
              }
            }catch(e){}
        });

        $el.find('input').click(function(e) {
          try{
            $el.find('.o-Icon-menu__date').click();
          } catch (e) {
            // ignore.
          }
        });

        if (lang === 'ar') {
          this.dateIcon.datepicker.dates['ar'] = {
              days: ["الاحد", "الاثنين", "الثلاثاء", "الاربعاء", "الخميس", "الجمعة", "السبت"],
              daysShort: ["الاحد", "الاثنين", "الثلاثاء", "الاربعاء", "الخميس", "الجمعة", "السبت"],
              daysMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
              months: ["كانون الثاني", "شباط", "آذار", "نيسان", "أيار", "حزيران", "تموز", "آب", "أيلول", "تشرين الاول", "تشرين الثاني", "كانون الاول"],
              monthsShort: ["يناير", "شباط", "آذار", "نيسان", "أيار", "حزيران", "تموز", "آب", "أيلول", "أكتوبر", "نوفمبر", "ديسمبر"],
              today: "اليوم",
              clear: "احزف",
              format: "dd/mm/yyyy",
              titleFormat: "MM yyyy", /* Leverages same syntax as 'format' */
              weekStart: 0
          };
        }
    },
    data: function () {
        return {
            checking: false,
            setStartDate: null,
            dateIcon: null,
            isChrome: isChromeBrowser()
        }
    },
    created: function() {
      this.setSetStartDate();
    },
    methods: {
        doDate: function(){},
        changeDate: function (date) {
            this.$emit('input', date);
            
            // used to trigger/check on dynamic date range in Travelex
            if(window.ibJS.application && window.ibJS.application.companyCode === "Travelex") {
              if(typeof this.$parent.triggerCalculatePrice === 'function') {
                this.$parent.triggerCalculatePrice();
              }
            }
        },
        setSetStartDate: function() {
          if(window.ibJS && window.ibJS.policyDTO && window.ibJS.policyDTO.expirationDate) {
            // WorldTrips and HCCMIS are the same
            if(window.ibJS.companyCode === 'HCCMIS' || window.ibJS.companyCode === 'WorldTrips' || window.ibJS.companyCode === "Trawick" || window.ibJS.companyCode === "ARS" || (ibJS.extension && window.ibJS.companyCode === "IMG")) {
              let dateInp;

              // IMG monthly plans use different property for expiration date.
              if(window.ibJS.companyCode === "IMG" && window.ibJS.extension.billMode === "M") {
                dateInp = convertDateString(window.ibJS.policyDTO.policyCompanyDTO.expectedEndDate);
              } else {
                dateInp = convertDateString(window.ibJS.policyDTO.expirationDate);
              }
              
              if (window.ibJS.companyCode === "IMG") {
                // APP-2950
                this.setStartDate = addOrSubtractDate(dateInp, "d",  0, "+",); 
              } else {
                this.setStartDate = addOrSubtractDate(dateInp, "d",  1, "+",);
              }

            } else {
              this.setStartDate = convertDateString(window.ibJS.policyDTO.expirationDate);
            }
          }
        },
        handleInputEvt: function (e) {
          if(e.target.value && typeof(e.target.value) == 'string') { // remove illegal characters
            const regex = /[^0-9-\/]/g;
            e.target.value = e.target.value.replace(regex, '');
          }
          if(e.target.id.includes('birthDate')){
            this.$emit('reset_birth_date', e.target.id);
          }
          else{
            /* if(this.$el.firstChild.form.__vue__.$v.form.startDate && this.$el.firstChild.form.__vue__.$v.$invalid){
                this.$el.firstChild.form.__vue__.$v.form.startDate.$reset();
                this.$el.firstChild.form.__vue__.$v.form.endDate.$reset();
            }
            if(this.$el.firstChild.form.__vue__.$v.form.departureDate && this.$el.firstChild.form.__vue__.$v.$invalid){
              this.$el.firstChild.form.__vue__.$v.form.departureDate.$reset();
              this.$el.firstChild.form.__vue__.$v.form.returnDate.$reset();
              if(this.$el.firstChild.form.__vue__.$v.form.initialTripDepositDate){
                this.$el.firstChild.form.__vue__.$v.form.initialTripDepositDate.$reset();
              }
            } */
            // check empty string to fix ie 9-11 input event issues
            //  commenting it for now @TODO: need to find out what the IE 9-11 issue is.
            // if (e.target.value !== '') {
            //     this.$emit('input', e.target.value);
            //     this.checking = true;
            // } else if (this.checking) {
                this.$emit('input', e.target.value);
            // }            
          }
        },
        keymonitor: function(evt) {
          evt = (evt) ? evt : window.event;
          var charCode = (evt.which) ? evt.which : evt.keyCode;
          if ((charCode < 47 || (charCode > 57 && charCode < 96) || charCode >105 ) && charCode !== 111 && charCode !== 191 && charCode !== 8 && charCode !== 46 ) {
            evt.preventDefault();
          } else if( lang =='zh' && (charCode !== 109 || charCode !== 189)) {
            evt.preventDefault();
          } else {
            return true;
          }
        },
        doformatdate:function(tmpdate) {
        var check_date;
        var tempDate;

        if(createDateByLangStr(tmpdate) != 'Invalid Date'){

          tmpdate = createDateByLangStr(tmpdate).toISOString();

        switch (lang) {
            case 'es':
              if(dayjs(tmpdate, 'DD/MM/YYYY').format() != 'Invalid date'){
                tmpdate=dayjs(tmpdate).format('DD/MM/YYYY');
                tempDate = tmpdate.split('/');
                check_date = new Date(tempDate[2], tempDate[1] - 1, tempDate[0]);
              }else{
                check_date = new Date(tmpdate);
              }
              break;
            case 'ar':
              if(dayjs(tmpdate, 'DD/MM/YYYY').format() != 'Invalid date'){
                tmpdate=dayjs(tmpdate).format('DD/MM/YYYY');
                tempDate = tmpdate.split('/');
                check_date = new Date(tempDate[2], tempDate[1] - 1, tempDate[0]);
              }else{
                check_date = new Date(tmpdate);
              }
              break;
            case 'zh':
              if(dayjs(tmpdate).format('YYYY-MM-DD') != 'Invalid date'){
                tmpdate=dayjs(tmpdate).format('YYYY-MM-DD');
                tempDate = tmpdate.split('-');
                check_date = new Date(tempDate[0], tempDate[1] - 1, tempDate[2]);
              }else{
                check_date = new Date(tmpdate);
              }
              break;
            default:
              if(dayjs(tmpdate).format('MM/DD/YYYY') != 'Invalid date'){
                tmpdate=dayjs(tmpdate).format('MM/DD/YYYY');
                tempDate = tmpdate.split('/');
                check_date = new Date(tempDate[2], tempDate[0] - 1, tempDate[1]);
              }else{
                check_date = new Date(tmpdate);
              }
          }
          //check_date = new Date(check_date);
          var month = check_date.getMonth();
          month = eval("month += 1");
          var date = check_date.getDate();
          var year = check_date.getFullYear();
          var month_leading_zero = "";
          var date_leading_zero = "";
          if (month < 10) {
            month_leading_zero = 0;
          }
          if (date < 10) {
            date_leading_zero = 0;
          }
          var formatdate;
          if(lang === 'en'){
            formatdate = "" + month_leading_zero + month + "/" + date_leading_zero
              + date + "/" + year;
          }else if(lang==='es' || lang === 'ar'){
            formatdate = "" + date_leading_zero + date + "/" + month_leading_zero
              + month + "/" + year;
          } else {
            formatdate = "" + year + "-" + month_leading_zero + month + "-" + date_leading_zero
              + date ;
          }
          return formatdate;
          }
          else{
            return "";
          }
        },
        validateDynamic: function(field,index,methods){
          this.$emit('input', $(this.$el).find("input").val());
          if(this.$parent.hasOwnProperty("validateDynamic") && typeof this.$parent.validateDynamic == "function") {
            this.$parent.validateDynamic(field,index,methods);
          }
        },
        inputChange(numOfTraveler) {
          var date = document.getElementById('travelerBdate' + numOfTraveler);
          this.$emit('input-change', date.value);
        },
        inputChangeTrip(numOfTraveler) {
          var date = document.getElementById('tripBdate' + numOfTraveler);
          this.$emit('input-change', date.value);
        },
        focus(element) {
          this.$emit('focus', element);
        }
    },
    watch: {
      startDate: function () {
        if(this.dateIcon && typeof this.dateIcon === 'object' && typeof this.dateIcon.datepicker == 'function') {
          this.dateIcon.datepicker('setStartDate', this.startDate);
        }
      },
      datesDisabled () {
        this.dateIcon.datepicker('setDatesDisabled', this.datesDisabled || []);
      },
      daysOfWeekDisabled () {
        this.dateIcon.datepicker('setDaysOfWeekDisabled', this.daysOfWeekDisabled || []);
      }
    }
}
