//Applies to step 5 on all applications.
import { 
    isChromeBrowser, 
    checkAgent,
    getStateDictionary,
    getStateCode,
    getAddrLength
} from '../../../helper-functions/helper-functions';
import { LoginModalMixin } from './loginModal';

export default {
    data() {
        return {
            currentLang: window.globalLanguage.lang,
            isChrome: isChromeBrowser(),
            isLoggedIn: ibJS?.affiliateValues?.myAccountLogin || false
        }
    },
    mixins: [
        LoginModalMixin
    ],
    computed: {
        cardType: function() {
            // eCheck
            if(this.form.paymentMethod === 'echeck'){
                return 'eCheck';
            }
            // visa
            var re = new RegExp("^4");

            // Sentry-1226
            if (!this.form.creditCardNumber) {
                return "";
            }

            if (this.form.creditCardNumber.match(re) != null)
                return "Visa";

            // Mastercard
            // Updated for Mastercard 2017 BINs expansion
            re = new RegExp ("^(5[1-5]|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)");

            if (this.form.creditCardNumber.match(re) != null)
                return "Master Card";

            // AMEX
            re = new RegExp("^3[47]");
            if (this.form.creditCardNumber.match(re) != null)
                return "AMEX";

            // Discover
            re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
            if (this.form.creditCardNumber.match(re) != null)
                return "Discover";

            // Updated Diners Club to match 30 38 36
            // Diners Club
            re = new RegExp("^3(?:0[0-5]|[68][0-9])");
            if (this.form.creditCardNumber.match(re) != null)
                return "Diners Club";

            // JCB
            re = new RegExp("^35(2[89]|[3-8][0-9])");
            if (this.form.creditCardNumber.match(re) != null)
                return "JCB";

            return "";
        },
        cardTypeClass: function() {
            switch (this.cardType) {
                case 'Visa': return 'is-visa';
                case 'Master Card': return 'is-mastercard';
                case 'AMEX': return 'is-amex';
                case 'Discover': return 'is-discover';
                case 'Diners Club' : return 'is-diners';
                case 'JCB' : return 'is-jcb';
                default: return 'is-default';
            }
        },
        creditCardNumLength: function() {
            if(!this.form.creditCardNumber) return 0;
            var creditCardNum = this.form.creditCardNumber.replace(/[\s\t]+/g,'');
            return creditCardNum.length;
        },
        planResultQuoteDuration () {
            return this.application?.planResult?.quotedDuration || null;
        }
        
    },
    mounted: function() {
        checkAgent.call(this, [true]);
    },
    methods: {
        setPaymentFields: function () {
        // && ibJS.retrievedApp && ibJS.retrievedApp === "true"
        //    commented out for now
            if(ibJS.application.status === "PAUSED"){
                this.form = {
                    ...this.form,
                    creditCardNumber : ibJS.application.ccNumber,
                    ccExpMonth : ibJS.application.ccExpMonth,
                    ccExpYear : ibJS.application.ccExpYear,
                    creditCardCVV : ibJS.application.creditCardCVV,
                    insubuyAgreement: true
                };

                switch (ibJS.application.companyCode) {
                    case 'IMG':


                }

            }
        },
        getAddrStateCode() {
            global.eventHub.$on('states-obtained', (val) => {
                const stateDictionary = getStateDictionary(val);
                if(this.billingAddrBook && this.billingAddrBook.length) {
                    for(let i = 0; i < this.billingAddrBook.length; i++) {
                        this.billingAddrBook[i].stateCode = getStateCode(this.billingAddrBook[i].state, this.billingAddrBook[i].country, stateDictionary);
                    }
                    
                    this.billingAddrs = getAddrLength(this.billingAddrBook);
                }
            });
        }
    }
};