
//Select 2 Country selector directive
//dropdownParent for INSUBUY-4115.
(function () {
    if ( typeof window.CustomEvent === "function" ) return false; //If not IE

    function CustomEvent ( event, params ) {
        params = params || { bubbles: false, cancelable: false, detail: undefined };
        var evt = document.createEvent( 'CustomEvent' );
        evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
        return evt;
    }

    CustomEvent.prototype = window.Event.prototype;

    window.Event = CustomEvent;
})();

import Vue from 'vue';
    Vue.directive("select2", {
    inserted: function (el, b, vnode) {
        function format(option) {
            if (!option.id) {
                return option.text;
            }
            else if(option.element !== undefined && option.element.getAttribute('img-src') !== null ){
                return '<span class="c-ibApp__form-cntry-code-flag"><img src=/assets/img/flags/' + option.element.getAttribute("img-src") + ' />' + option.text + ' (+' + option.element.getAttribute("isd-code") + ')</span>';
            }
             else if(el.id === "imgFulfillment" || el.id === "Plan1" || el.id === "Plan2"){
                return '<span>' + option.text + '</span>';
            }

            var ob = '<span class="c-ibApp__form-cntry-code-flag"><img src=/assets/img/flags/' + option.element.getAttribute("img-src") + ' />' + option.text + ' (+' + option.element.getAttribute("isd-code") + ')</span>';
            return ob;
        };

        if (typeof $.fn.select2 === "function") {
            $("#phnCode-goaway").select2({
                placeholder: window.globalLanguage.selectPlaceholder,
                width: "50%",
                templateResult: format,
                dropdownParent: $("#phnCode-parent"),
                templateSelection: function (option) {
                    if (option.id.length > 0) {
                        return '<span class="c-ibApp__form-cntry-code-flag"><img src=/assets/img/flags/' + option.element.getAttribute("img-src") + ' /> +' + option.element.getAttribute("isd-code") + '</span>';
                    } else {
                        return option.text;
                    }
                },
                escapeMarkup: function (m) {
                    return m;
                }
            }).on("select2:select", function (e) {
                var event = new Event('change', {target: e.target});
                el.dispatchEvent(event);
            });
        }

        $("#phnCode-goaway").on("select2:open", function (e) {
            var $elem = $(".select2-dropdown");
            $elem.hide();
            $elem.find("input").on("keydown", setWidth);
            setTimeout(function () {
                var resultOptions = $("ul.select2-results__options");
                $elem.addClass("c-ibApp__form-cntry-code-select-list")
                    .css({width: $(".c-ibApp__form-cntry-code-phone").width()}).show();
                $elem.find("input").focus();
                var scrollTo = $("li.select2-results__option[aria-selected=true]");
                if(scrollTo.length) {
                    resultOptions.animate({
                        scrollTop: scrollTo.offset().top - resultOptions.offset().top + resultOptions.scrollTop()
                    }, 0);
                }
            }, 10);
        });

        if (typeof $.fn.select2 === "function") {
            $("#phnCode").select2({
                placeholder: window.globalLanguage.selectPlaceholder,
                width: "50%",
                templateResult: format,
                dropdownParent: $("#phnCode-parent"),
                templateSelection: function (option) {
                    if (option.id.length > 0) {
                        return '<span class="c-ibApp__form-cntry-code-flag"><img src=/assets/img/flags/' + option.element.getAttribute("img-src") + ' /> +' + option.element.getAttribute("isd-code") + '</span>';
                    } else {
                        return option.text;
                    }
                },
                escapeMarkup: function (m) {
                    return m;
                }
            }).on("select2:select", function (e) {
                var event = new Event('change', {target: e.target});
                el.dispatchEvent(event);
            });
        }
        
        var setWidth = function (elem) {
            var $elem = $(".select2-dropdown");

            setTimeout(function () {
                $elem.css({width: $(".c-ibApp__form-cntry-code-phone").width()});
            }, 2);
        };

        $("#phnCode").on("select2:open", function (e) {
            var $elem = $(".select2-dropdown");
            $elem.hide();
            $elem.find("input").on("keydown", setWidth);
            setTimeout(function () {
                var resultOptions = $("ul.select2-results__options");
                $elem.addClass("c-ibApp__form-cntry-code-select-list")
                    .css({width: $(".c-ibApp__form-cntry-code-phone").width()}).show();
                $elem.find("input").focus();
                var scrollTo = $("li.select2-results__option[aria-selected=true]");
                if(scrollTo.length) {
                    resultOptions.animate({
                        scrollTop: scrollTo.offset().top - resultOptions.offset().top + resultOptions.scrollTop()
                    }, 0);
                }
            }, 10);
        });

        if (typeof $.fn.select2 === "function") {
            $("#phnCode2").select2({
                placeholder: "Select something!!",
                width: "50%",
                templateResult: format,
                dropdownParent: $("#phnCode2-parent"),
                templateSelection: function (option) {
                    if (option.id.length > 0) {
                        return '<span class="c-ibApp__form-cntry-code-flag"><img src=/assets/img/flags/' + option.element.getAttribute("img-src") + ' /> +' + option.element.getAttribute("isd-code") + '</span>';
                    } else {
                        return option.text;
                    }
                },
                escapeMarkup: function (m) {
                    return m;
                }
            }).on("select2:select", function (e) {
                var event = new Event('change', {target: e.target});
                el.dispatchEvent(event);
            });
        }

        var setWidth = function (elem) {
            var $elem = $(".select2-dropdown");

            setTimeout(function () {
                $elem.css({width: $(".c-ibApp__form-cntry-code-phone").width()});
            }, 2);
        };

        $("#phnCode2").on("select2:open", function (e) {
            var $elem = $(".select2-dropdown");
            $elem.hide();
            $elem.find("input").on("keydown", setWidth);
            setTimeout(function () {
                var resultOptions = $("ul.select2-results__options");
                $elem.addClass("c-ibApp__form-cntry-code-select-list")
                    .css({width: $(".c-ibApp__form-cntry-code-phone").width()}).show();
                $elem.find("input").focus();
                var scrollTo = $("li.select2-results__option[aria-selected=true]")
                //SENTRY-504
                try {
                    resultOptions.animate({
                        scrollTop: scrollTo.offset().top - resultOptions.offset().top + resultOptions.scrollTop()
                    }, 0);
                } catch(e) {console.log('animation failed')}
            }, 10);
        });

        if (typeof $.fn.select2 === "function") {
            $("#phnCode3").select2({
                placeholder: "Select something!!",
                width: "50%",
                templateResult: format,
                dropdownParent: $("#phnCode3-parent"),
                templateSelection: function (option) {
                    if (option.id.length > 0) {
                        return '<span class="c-ibApp__form-cntry-code-flag"><img src=/assets/img/flags/' + option.element.getAttribute("img-src") + ' /> +' + option.element.getAttribute("isd-code") + '</span>';
                    } else {
                        return option.text;
                    }
                },
                escapeMarkup: function (m) {
                    return m;
                }
            }).on("select2:select", function (e) {
                var event = new Event('change', {target: e.target});
                el.dispatchEvent(event);
            });
        }

        var setWidth = function (elem) {
            var $elem = $(".select2-dropdown");

            setTimeout(function () {
                $elem.css({width: $(".c-ibApp__form-cntry-code-whatsapp").width()});
            }, 2);
        };

        $("#phnCode3").on("select2:open", function (e) {
            var $elem = $(".select2-dropdown");
            $elem.hide();
            $elem.find("input").on("keydown", setWidth);
            setTimeout(function () {
                var resultOptions = $("ul.select2-results__options");
                $elem.addClass("c-ibApp__form-cntry-code-select-list")
                    .css({width: $(".c-ibApp__form-cntry-code-whatsapp").width()}).show();
                $elem.find("input").focus();
                var scrollTo = $("li.select2-results__option[aria-selected=true]")
                //SENTRY-504
                try {
                    resultOptions.animate({
                        scrollTop: scrollTo.offset().top - resultOptions.offset().top + resultOptions.scrollTop()
                    }, 0);
                } catch(e) {console.log('animation failed')}
            }, 10);
        });

        if (typeof $.fn.select2 === "function") {
            $("#imgFulfillment").select2({
                placeholder: "Select something!!",
                width: "50%",
                //dropdownParent: $("#imgSelectParent"),
                templateResult: format,
                templateSelection: function (option) {
                        return option.text;
                },
                escapeMarkup: function (m) {
                    return m;
                }
            }).on("select2:select", function (e) {
                var event = new Event('change', {target: e.target});
                el.dispatchEvent(event);
            });
        }

        var setWidth = function (elem) {
            var $elem = $(".select2-dropdown");
            setTimeout(function () {
                $elem.css({width: $(".c-ibApp__form-wrap--half-r").width()});
            }, 2);
        };

        $("#imgFulfillment").on("select2:open", function (e) {
            var $elem = $(".select2-dropdown");
            $elem.hide();
            $elem.find("input").on("keydown", setWidth);
            setTimeout(function () {
                var resultOptions = $("ul.select2-results__options");
                $elem.addClass("c-ibApp__form-select").css({width: "100%",
                                                            lineHeight: "1",
                                                            padding: '0',
                                                            fontSize: window.innerWidth > 768 ? '.875em' : '.8em',
                                                            border: "1px solid #5e92d6"})
                                                            .show();
            $('.select2.select2-container--focus').css({border: "1px solid #5e92d6"})
                $elem.find("input").focus();
                $('.select2-search--dropdown').css({display: 'none'})
            }, 10);
        });

        $("#imgFulfillment").on("select2:close", () => {
            setTimeout(() => {
            $(".select2-dropdown").css({border: "initial"})
            $('.select2.select2-container--focus').css({border: "1px solid rgba(0, 0, 0, 0.15)"})
            },10);
        })

        $(".select2").on("keydown", function (e) {
            if (e.keyCode == 40) {
                $(e.target).parent().closest(".select2").prev().select2('open');
            }
        });

        //START CLIENT CENTER
            if (typeof $.fn.select2 === "function") {
                $("#plan1").select2({
                    placeholder: qResultsLang.plan1Placeholder,
                    width: "50%",
                    dropdownParent: $("plan1-parent"),
                    templateResult: format,
                    templateSelection: function (option) {
                        $('.custom-select').css({paddingRight: '0'})
                        $('.selection').css({width: '100%'})
                        $('.select2-selection--single').css({padding: '15px 0'})
                        return option.text;
                    },
                    escapeMarkup: function (m) {
                        return m;
                    }
                }).on("select2:select", function (e) {
                    var event = new Event('change', {target: e.target});
                    el.dispatchEvent(event);
                });
            }

        var setWidth = function (elem) {
            var $elem = $(".select2-dropdown");

            setTimeout(function () {
                $elem.css({width: $(".c-ibApp__form-cntry-code-phone").width()});
            }, 2);
        };

        $("#plan1").on("select2:open", function (e) {
            var $elem = $(".select2-dropdown");
            $elem.hide();
            $elem.find("input").on("keydown", setWidth);
            $('.select2-search--dropdown').css({display: 'none'})
            setTimeout(function () {
                var resultOptions = $("ul.select2-results__options");
                $elem.addClass("c-ibApp__form-cntry-code-select-list")
                    .css({width: $(".c-ibApp__form-cntry-code-phone").width()}).show();
                $elem.find("input").focus();
            }, 10);
        });

        if (typeof $.fn.select2 === "function") {
            $("#plan2").select2({
                placeholder: "Choose second insurance plan",
                width: "50%",
                dropdownParent: $("plan2-parent"),
                templateResult: format,
                templateSelection: function (option) {
                    $('.custom-select').css({paddingRight: '0'})
                    $('.selection').css({width: '100%'})
                    $('.select2-selection--single').css({padding: '15px 0'})
                    return option.text;
                },
                escapeMarkup: function (m) {
                    return m;
                }
            }).on("select2:select", function (e) {
                var event = new Event('change', {target: e.target});
                el.dispatchEvent(event);
            });
        }

        var setWidth = function (elem) {
            var $elem = $(".select2-dropdown");

            setTimeout(function () {
                $elem.css({width: $(".c-ibApp__form-cntry-code-phone").width()});
            }, 2);
        };

        $("#plan2").on("select2:open", function (e) {
            var $elem = $(".select2-dropdown");
            $elem.hide();
            $elem.find("input").on("keydown", setWidth);
            $('.select2-search--dropdown').css({display: 'none'})
            setTimeout(function () {
                var resultOptions = $("ul.select2-results__options");
                $elem.addClass("c-ibApp__form-cntry-code-select-list")
                    .css({width: $(".c-ibApp__form-cntry-code-phone").width()}).show();
                $elem.find("input").focus();
            }, 10);
        });
    }
});