//Applies to step 6 on all applications.
import Vue from 'vue';
import {getDateFromLanguage, isInPlanList} from '../../../helper-functions/helper-functions';
import allPersonTable from '../../../components/tables/component.all-person-table.vue';
import WTPlanIds from '../../../helper-functions/companies/worldtrips-planids';
import TrawickPlanIds from '../../../helper-functions/companies/trawick-planids';

const trawickPlanIds = new TrawickPlanIds();
const wtPlanIds = new WTPlanIds();

const trawickConfig = {
    planId: ibJS.planId ? ibJS.planId : (ibJS.application ? ibJS.application.planId : ''),
    //Plan Lists
    tripProtectPlans: [506,583], //these are discontinued, but will delete at a later time
    nationWidePlans: [
        trawickPlanIds.Safe_Travels_Single_Trip_Parent, 
        trawickPlanIds.Safe_Travels_First_Class_Parent,
        trawickPlanIds.Safe_Travels_First_Class_All_Other_States,
        trawickPlanIds.Safe_Travels_First_Class_Penn_Alaska_Missouri,
        trawickPlanIds.SafeTreker_Extreme,
        trawickPlanIds.SafeTreker_Extreme_Plus,
        trawickPlanIds.SafeTreker_Parent
    ],
    exchangeGuardGrpPlans: [wtPlanIds.ExchangeGuard_Group_Choice, wtPlanIds.ExchangeGuard_Group_Essential],
    //Plan List Functions
    isTripProtectPlans: function() { return isInPlanList(this.tripProtectPlans, this.planId) },
    isNationWidePlans: function() { return isInPlanList(this.nationWidePlans, this.planId) },
    isATripPlan: function () { return this.isNationWidePlans() || this.isTripProtectPlans() },
    isExchangeGuardGrp: function() { return isInPlanList(this.exchangeGuardGrpPlans, this.planId) },
};

export default {
    name: "Step6Mixin",
    components:{
        'all-person-table': allPersonTable
    },
    data: function() {
        return {
            travelerListType: [],
            currentLang: window.globalLanguage.lang,
            fullAppId: window.ibJS.application.fullAppId,
            showCareingtonModal: true,
            showTripModal: true,
        }
    },
    created: function() {
        this.travelerText();
        const usPrimaryDestination = window.ibJS.application.destinationCountries.findIndex(dst => dst.country === 'USA');
        if (ibJS.application?.mailingAddress && ['VT', 'WA'].indexOf(ibJS.application.mailingAddress.state) > -1) {
            this.showCareingtonModal = false;
        } else if (!ibJS.application?.mailingAddress && ibJS.application.billingAddress && ['VT', 'WA'].indexOf(ibJS.application.billingAddress.state) > -1) {
            this.showCareingtonModal = false;
        } else if(usPrimaryDestination === -1){
            this.showCareingtonModal = false;
        }

        const residenceAddr = ibJS.application?.mailingAddress || ibJS.application?.homeCountryAddress;
        const residentOfUS = residenceAddr?.country === 'USA' ? true : false; 

        if (!residentOfUS || (ibJS.application?.quoteCriteriaList && ibJS.application.quoteCriteriaList[0].coverageArea != 3)) {
            this.showTripModal = false;
        }

        this.$root.$on('showAlert', ()=> {
            this.showAlert();
        })
    },
    mounted () {
        this.showDentalModal();
        this.showTripQuoteModal();
    },
    methods: {
        travelerText: function() {
            //not ready for 'others' logic like in seven corners. Will need additional logic if we re-add it.
            const travelers = ibJS.application.travelerInfos;
            const admin = ibJS.backEndPlan.config.administrator;
            const company = ibJS.application.companyCode ? ibJS.application.companyCode : "";
            const planId = ibJS.application.planId;

            for(let i=0; i<travelers.length; i++) {
                const tvlClone = { ...travelers[i] }
                // tvlClone.birthDate = getDateFromLanguage(tvlClone.birthDate);

                if(travelers.length === 1) { //One traveler, no label
                    tvlClone.text = '';
                    this.travelerListType.push(tvlClone)
                    return;
                } else if(travelers[1].travelerType === '') { //1st traveler is empty '', insured + index
                    if (trawickConfig.isATripPlan() || company === 'Travelex'){
                        tvlClone.text = qResultsLang.summaryTravelerTrip + ' ' + (i+1);
                    } else if (company === 'Careington'){
                        tvlClone.text = qResultsLang.member + ' ' + (i+1);
                    } else if(this.isExchangeGuardGrp) {
                        tvlClone.text = qResultsLang.egGroup.grpMember + ' ' + (i+1);
                    } else{
                        tvlClone.text = qResultsLang.summaryInsured + ' ' + (i+1);
                    }
                    this.travelerListType.push(tvlClone)
                } else {
                    switch(travelers[i].travelerType) { // Traveler type specific labels (primary, spouse, etc)
                        case 'spouse':
                            if(admin === 'Global Underwriters') {
                                tvlClone.text = qResultsLang.summaryAdditional  + ' ' + qResultsLang.summaryInsured;
                            } else {
                                tvlClone.text = qResultsLang.summarySpouse;
                            }
                            this.travelerListType.push(tvlClone)
                            break;

                        case 'child':
                            let child = qResultsLang.summaryChild;

                            if(travelers[1].travelerType === 'spouse' && travelers.length > 3) {
                                tvlClone.text = child + ' ' + (i-1);
                            } else if(travelers[1].travelerType !== 'spouse' && travelers.length > 2) {
                                tvlClone.text = child + ' ' +i;
                            } else {
                                tvlClone.text = child
                            }

                            this.travelerListType.push(tvlClone)
                            break;

                        default: 
                            if (company === 'WorldTrips' && planId != 517 && planId != 714 && !ibJS.application.groupCertNumber) { //edge case for WT
                                tvlClone.text = qResultsLang.summaryInsured + ' ' + (i+1);
                                this.travelerListType.push(tvlClone)
                            }
                            else {//primary
                                tvlClone.text = qResultsLang.summaryPrimary + ' ' + qResultsLang.summaryInsured;
                                this.travelerListType.push(tvlClone)
                            }
                            break;
                    }
                }
            }
        },
        showAlert () {
            if (this.$children && this.$children.length) {
                this.$children[0].$emit('close');
            }
        },
        printConfirmation () {
            this.printView = false;
            Vue.nextTick(()=>{
                if(window.print) {window.print()};return false;
            })
        },
        showDentalModal () {
            // APP-4083 Show Modal: Cross sell dental plan
            const includesUSDesti = ibJS.application?.destinationCountries?.filter(item=>item.country === 'USA');
            if (ibJS.affiliateValues?.dental && this.showCareingtonModal
                && ((includesUSDesti && includesUSDesti.length) || (ibJS.application.destInclUS && ibJS.application.destInclUS.toLowerCase() == 'yes'))
                && (ibJS.application.planType == 'travelmedical' || ibJS.application.planType == 'studentmedical')) {
                    if (this.planId != 755 || (this.planId == 755 && window.ibJS.application.destinationCountries[0].country == 'USA')) {
                        setTimeout(() => {
                            global.eventHub.$on('show-modal-rendered', (rendered)=>{
                                if (rendered) {
                                    const $dentalVisonModal = document.querySelector('#dentalVisionModalPurchase');
                                    if ($dentalVisonModal) {
                                        $dentalVisonModal.setAttribute('href', '/myaccount/careington-repurchase/?certificateNumber='+this.plan.travelersInfo[0].certNumber+'&birthDate='+this.plan.travelersInfo[0].birthDate); 
                                    }
                                }
                            })
                            this.showModal(`/guide/terms?section=dentalapplicationmodal&planName=${ibJS.planConfig.displayNamePlain}`);
                        }, 3500);
                    }
            }
        },
        showTripQuoteModal () {
            // ENCMT-50	 Show Modal: Cross sell Trip plan
            const includesUSDesti = ibJS.application?.destinationCountries?.find(item=>item.country === 'USA');
            const residenceAddr = ibJS.application?.mailingAddress || ibJS.application?.homeCountryAddress;
            const residentOfUS = residenceAddr?.country === 'USA' ? true : false; 
            if ((ibJS.affiliateValues?.tripInsurance || ibJS.affiliateid === 'none') && this.showTripModal && residentOfUS
                && (!includesUSDesti || (!ibJS.application.destInclUS && ibJS.application.destInclUS.toLowerCase() != 'yes'))
                && ibJS.application.planType == 'travelmedical') {
                    setTimeout(() => {
                        this.showModal(`/guide/terms?section=tripapplicationmodal&planName=${ibJS.planConfig.displayNamePlain}`);
                    }, 3500);
            }
        },
        mobileAppViewAll (e) {
            if (window.qResultsLangGlobal.mobileApp) {
                e.preventDefault();
                e.stopPropagation();
                window.location.href = window.qResultsLangGlobal.myAccountLink;
            }
        }
    }
};
