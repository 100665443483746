
import Vue from 'vue';
import Multiselect from 'vue-multiselect'

export default {
    components: {
        Multiselect
    },
    props: {
        allCountries: {
            type: Array,
            required: true
        },
        defaultVal: {
            type: String,
            required: false
        },
        refName: {
            type: String,
            required: true
        },
        disable: {
            type: Boolean,
            default: false
        },
        trackBy: {
            type: String,
            default: 'newCode',
            required: false
        },
        maxHeight: {
            type: Number,
            default: 300
        },
        changeMethod: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        disableCountryCode: {
            type: Boolean,
            default: false
        },
        lengthLimit: {
            type: Number,
            default: 20
        },
        inputId: {
            type: String,
            default: 'phoneNumberInput'
        }
    },
    data () {
        return {
            selectedCountry: null,
            default: {"country":"United States","newCode":"USA","isdCode":"1","falgFileName":"united-states.jpg","unified": "United State USA 1"},
            show: false,
            telNumber: '',
            constructedNumber: ''
        }
    },
    created () {
        if (!this.defaultVal) {
            this.selectedCountry = JSON.parse(JSON.stringify(this.default));
        } else {
            this.constructTelNumb();
        }
    },
    watch: {
        'telNumber' (val) {
            this.hideCntrCode();
        },
        defaultVal (val) {
            if (!val) {
                this.telNumber = '';
                // this.selectedCountry = JSON.parse(JSON.stringify(this.default));
            }
            else if (val) {
                this.constructTelNumb();
            }
        },
        selectedCountry: {
            deep: true,
            handler: function () {
                if (this.$parent && typeof this.$parent?.changeCountry === 'function') {
                    this.$emit('change-country', this.selectedCountry);
                }
            }
        }
    },
    methods: {
        constructTelNumb() {
            this.constructedNumber = this.defaultVal;
            const { isdCode, phoneNumber } = this.extractIsdNPhoneNumber(this.defaultVal);
            this.telNumber = phoneNumber;
            if (isdCode && isdCode.toString() != '1') {
                for (let i = 0; i < this.allCountries.length; i++) {
                    const country = this.allCountries[i];
                    if (country.isdCode === isdCode) {
                        this.selectedCountry = country;
                        break;
                    }
                }
            } else if (!isdCode || (isdCode && isdCode.toString() === '1')) {
                this.selectedCountry = JSON.parse(JSON.stringify(this.default));
            }
        },
        customLabel ({ country, newCode }) {
            return `${country} – ${newCode}`
        },
        showCountryCode () {
            if (!this.disable) {
                this.show = true;
                this.$refs[this.refName].activate();
            }
        },
        hideCntrCode () {
            setTimeout(() => {
                this.show = false;
                if (this.selectedCountry && this.selectedCountry.isdCode && this.telNumber) {
                    this.constructedNumber = `+${this.selectedCountry.isdCode}-${this.telNumber}`;
                // } else if(this.selectedCountry && this.selectedCountry.isdCode) {
                //     this.constructedNumber = `+${this.selectedCountry.isdCode}-`;
                } else {
                    this.constructedNumber = '';
                }
                this.$emit('input', this.constructedNumber);
                this.$emit('change', this.constructedNumber);
                this.$emit('change-tel-number', this.telNumber);
            }, 120);
        },
        extractIsdNPhoneNumber (defaultVal) {
            let phoneNo = defaultVal;
            let phoneNumber = null;

            let list = phoneNo.indexOf('-') > -1 && phoneNo.indexOf('+') === 0 ? phoneNo.split('-') : null;
            let isdCode = null;
            if (list) {
                isdCode = list[0].substring(1);
            list = list.splice(1);
            }

            if (list) phoneNumber = list.join('-');
            else phoneNumber = phoneNo;

            return {
                isdCode,
                phoneNumber
            }
        },
        changeEvent () {
            if (this.changeMethod) {
                this.$emit(this.changeMethod, this.constructedNumber);
            }
        },
        onPaste (evt) {
            setTimeout(()=>{
                let phnNo = this.telNumber;
                if (phnNo.indexOf('+') === 0) {
                    const rgxCode = phnNo.match(/(\+\d{1,}\s.*?)/);
                    if (rgxCode && rgxCode.length) {
                        const countryCode = rgxCode[0].trim();
                        const ccwithoutplus = countryCode.replace('+','');
                        for (let i = 0; i < this.allCountries.length; i++) {
                            const country = this.allCountries[i];
                            if (country.isdCode === ccwithoutplus) {
                                this.selectedCountry = country;
                                break;
                            }
                        }
                        const newPhnNo = phnNo.replace(countryCode,'').replace(/\D/g, '');
                        this.telNumber = newPhnNo;
                    }
                }
            },150)
        },
        triggerCntryCode () {
            if (!this.disableCountryCode) {
                !this.show ? this.showCountryCode() : this.hideCntrCode();
            }
        }
    }
}
