import Vue from 'vue';
import VueResource from 'vue-resource';
import {
    getCurrentTime,
    addMins,
    convertToCSTTime,
    copyToClipboard
} from '../../../helper-functions/helper-functions';
import dayjs from 'dayjs';
import 'dayjs/locale/zh';

Vue.use(VueResource);

export default {
    name: 'navHeader',
    data () {
        return {
            currentOfficeTime: '',
            currentOfficeDateTime: '',
            onLoadTime: '',
            isOfficeOpen: null,
            officeDescrption: '',
            regularHours: window.globalLanguage?.regularHours || [],
            holidayHours: window.globalLanguage?.holidayHours || [],
            listOfRegHrs: [], // grouped regular hours
            breakDownOfRegHrs: [], // breakdown of all 7 days regular hrs
            todayStdStartTime: null, // todays regular hours
            todayStdEndTime: null,
            todayModStartTime: null, // todays modified hours i.e. Holiday hrs if there is setTime (open/close)
            todayModEndTime: null,
            todayHolidayObj: null,
            days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            daysOfWeekDisabled: [],
            allSevenDays: '',
            activeTime: 0
       }
    },
    created () {
        // this.getCurrentCSTTime();
        this.setRegTime();
    },
    mounted () {
        this.toggleOfficeHours();
    },
    methods: {
        getCurrentCSTTime () {
            let requestURL = location.host;
            if (location.host.indexOf('brokersnexus.com') > -1) {
                requestURL = 'sample.brokersnexus.com';
            } else if (location.host.indexOf('brokersnexustest.com') > -1) {
                requestURL = 'insubuy20test';
            }
            this.$http.get('https://' + requestURL + '/api/current-central-time/').then(res=>{
                if (res.body?.data && res.body.data.currentCTSDate) {
                    this.currentOfficeDateTime = res.body.data.currentCTSDate;
                    this.onLoadTime = res.body.data.currentCTSDate;
                    this.currentOfficeTime = getCurrentTime(res.body.data.currentCTSDate);
                    if (this.regularHours && this.regularHours.length === 1) {
                        const stTime = this.translateMeridiem(convertToCSTTime(this.onLoadTime).format('MM/DD/YYYY')+' '+this.regularHours[0].startTime, globalLanguage.lang)?.replace(':00', '');
                        const edTime = this.translateMeridiem(convertToCSTTime(this.onLoadTime).format('MM/DD/YYYY')+' '+this.regularHours[0].endTime, globalLanguage.lang)?.replace(':00', '');
                        if(stTime && edTime) this.allSevenDays = `${stTime} - ${edTime}`;
                    }
                    this.getHolidayForToday();
                    this.setRegTime();
                    this.checkIfOpen();
                    const getSec = new Date(res.body.data.currentCTSDate).getSeconds();
                    let remainingInterval = 59 - getSec;
                    setTimeout(() => {
                        const newDateTime = addMins(res.body.data.currentCTSDate, 1);
                        this.currentOfficeDateTime = newDateTime.fullTime;
                        this.currentOfficeTime = newDateTime.hhmm;
                        this.checkIfOpen();
                        this.increamentTime(newDateTime.fullTime);
                    }, 1000*remainingInterval);
                }
            })
        },
        getHolidayForToday () {
            const formatedCurrTime = convertToCSTTime(this.currentOfficeDateTime).format('MM/DD/YYYY');
            this.todayHolidayObj = null;
            for (let i = 0; i < this.holidayHours.length; i++) {
                const holidayHour = this.holidayHours[i];
                if (holidayHour.date === formatedCurrTime) {
                    if (holidayHour.status == 2) {
                        this.todayModStartTime = holidayHour.startTime;
                        this.todayModEndTime = holidayHour.endTime;
                    }
                    this.todayHolidayObj = holidayHour;
                    break;
                }
            }
        },
        increamentTime (currentCTSDate) {
            setInterval(() => {
                const newDateTime = addMins(currentCTSDate, 1);
                this.currentOfficeTime = newDateTime.hhmm;
                this.currentOfficeDateTime = newDateTime.fullTime;
                this.checkIfOpen();
                currentCTSDate = newDateTime.fullTime;
                const isNextDay = convertToCSTTime(this.onLoadTime).diff(newDateTime.fullTime) < 0;
                if (isNextDay) {
                    this.getHolidayForToday();
                    this.setRegTime();
                }
            }, (1000*60));
        },
        checkIfOpen () {
            const startTime = this.todayModStartTime || this.todayStdStartTime;
            const endTime = this.todayModEndTime || this.todayStdEndTime;
            const formatedDate = convertToCSTTime(this.currentOfficeDateTime).format('MM/DD/YYYY');
            
            this.isOfficeOpen = this.compareDiffBtwTimes(this.currentOfficeDateTime, `${formatedDate} ${startTime}`, `${formatedDate} ${endTime}`);
            let officeDescrption = this.isOfficeOpen ? window.globalLanguage.officeOpen : window.globalLanguage.officeClosed;

            if (this.todayHolidayObj && this.todayHolidayObj.descriptions) {
                let descriptions = {};
                this.todayHolidayObj.descriptions.forEach(des=>{
                    switch(des.langId) {
                        case 2:
                            descriptions.zh = des.description;
                            break;
                        case 3:
                            descriptions.es = des.description;
                            break;
                        default: 
                            descriptions.en = des.description;
                            break;
                    }
                });
                let finalDesc;
                if (descriptions.hasOwnProperty(globalLanguage.lang)) finalDesc = descriptions[globalLanguage.lang];
                else finalDesc = descriptions['en'];
                switch(this.todayHolidayObj.status) {
                    case 0:
                        // closed
                        officeDescrption = `${window.globalLanguage.officeClosed} ${finalDesc}`;
                        this.isOfficeOpen = false;
                        break;
                    case 3: {
                        // closed at set hours
                        if (this.compareDiffBtwTimes(this.currentOfficeDateTime, `${formatedDate} ${this.todayHolidayObj.startTime}`, `${formatedDate} ${this.todayHolidayObj.endTime}`)) {
                            const translStartTime = this.translateMeridiem(`${formatedDate} ${this.todayHolidayObj.startTime}`, globalLanguage.lang);
                            const translEndTime = this.translateMeridiem(`${formatedDate} ${this.todayHolidayObj.endTime}`, globalLanguage.lang);
                            officeDescrption = `${window.globalLanguage.officeClosed} ${this.replaceZeroMins(translStartTime)} - ${this.replaceZeroMins(translEndTime)} ${finalDesc}`;
                            this.isOfficeOpen = false;
                        }
                        break;
                    }
                    default: {
                        if (this.isOfficeOpen) {
                            // open at reg or set hours
                            const translStartTime = this.translateMeridiem(`${formatedDate} ${startTime}`, globalLanguage.lang);
                            const translEndTime = this.translateMeridiem(`${formatedDate} ${endTime}`, globalLanguage.lang);
                            officeDescrption = `${window.globalLanguage.officeOpen} ${this.replaceZeroMins(translStartTime)} - ${this.replaceZeroMins(translEndTime)} ${finalDesc}`;
                        } else if (!this.isOfficeOpen && this.todayHolidayObj.status == 2) {
                            officeDescrption = `${window.globalLanguage.officeClosed} ${finalDesc}`;
                        }
                        break;
                    }
                       
                }
            }
            this.officeDescrption = officeDescrption;
        },
        compareDiffBtwTimes (dateOne, startDate, endDate) {
            const convertedCSTDate = new Date(convertToCSTTime(dateOne).format('MM/DD/YYYY hh:mm a')).getTime();
            const convertedStartDate = new Date(dayjs(startDate).format('MM/DD/YYYY hh:mm a')).getTime();
            const convertedEndDate = new Date(dayjs(endDate).format('MM/DD/YYYY hh:mm a')).getTime();
            
            const diffStartDate = convertedCSTDate > convertedStartDate;
            const diffEndDate = convertedCSTDate < convertedEndDate;
            return diffStartDate && diffEndDate;
        },
        setRegTime () {
            const currentDate = convertToCSTTime(this.currentOfficeDateTime);
            const formatedDate = currentDate.format('MM/DD/YYYY');
            const selectedDay = dayjs(formatedDate).day();
            const weekDay = this.days[selectedDay];
            this.listOfRegHrs = [];
            this.breakDownOfRegHrs = [];
            const hypen = window.globalLanguage.lang != 'zh' ? '-' : '至';
            
            if (this.regularHours.length > 1) {
                for (let i = 0; i < this.regularHours.length; i++) {
                    const rg = this.regularHours[i];
                    let thisDay = '';
                    if (rg.dayOfWeek.indexOf('-') > -1) {
                        const weekDays = rg.dayOfWeek.split(' - ');
                        let firstDay = this.days.indexOf(weekDays[0]);
                        let lastDay = this.days.indexOf(weekDays[1]);
                        let daysWithin = [];
                        if (lastDay == 0) {
                            daysWithin = this.days.slice(firstDay, this.days.length);
                            daysWithin.push(this.days[lastDay]);
                        } else daysWithin = this.days.slice(firstDay, (lastDay+1));
                        daysWithin.forEach((wk,i)=>{
                            this.breakDownOfRegHrs.push({
                                day: wk,
                                startTime: rg.startTime,
                                endTime: rg.endTime,
                                status: rg.status
                            });
                            if (weekDay === wk) {
                                this.todayStdStartTime = rg.startTime;
                                this.todayStdEndTime = rg.endTime;
                            }
                            if (i == 0) thisDay += window.globalLanguage.weekDays[wk.toLowerCase()];
                            else if (i == (daysWithin.length-1)) thisDay = thisDay + hypen + window.globalLanguage.weekDays[wk.toLowerCase()];
                        });
                    } else {
                        this.breakDownOfRegHrs.push({
                            day: rg.dayOfWeek,
                            startTime: rg.startTime,
                            endTime: rg.endTime,
                            status: rg.status
                        });
                        thisDay = window.globalLanguage.weekDays[rg.dayOfWeek.toLowerCase()];
                        if (weekDay === rg.dayOfWeek) {
                            this.todayStdStartTime = rg.startTime;
                            this.todayStdEndTime = rg.endTime;
                        }
                    }
                    if (window.globalLanguage.lang === 'en') {
                        const stendTime = rg.status != 0 ? `${this.replaceZeroMins(rg.startTime)} - ${this.replaceZeroMins(rg.endTime)}` : `${window.globalLanguage.closed}`
                        this.listOfRegHrs.push(`${thisDay}: ${stendTime}`);
                    } else {
                        let lgStartTime = '';
                        let lgEndTime = '';
                        if (rg.status != 0) {
                            lgStartTime = this.translateMeridiem(`${formatedDate} ${rg.startTime}`,window.globalLanguage.lang);
                            lgEndTime = this.translateMeridiem(`${formatedDate} ${rg.endTime}`, window.globalLanguage.lang);
                        }
                        const stendTime = rg.status != 0 ? `${this.replaceZeroMins(lgStartTime)} - ${this.replaceZeroMins(lgEndTime)}` : `${window.globalLanguage.closed}`
                        this.listOfRegHrs.push(`${thisDay}: ${stendTime}`);
                    } 
                    // else if (window.globalLanguage.lang === 'es') {
                    //     let esStartTime = '';
                    //     let esEndTime = '';
                    //     if (rg.status != 0) {
                    //         esStartTime = this.translateMeridiem(`${formatedDate} ${rg.startTime}`, 'es');
                    //         esEndTime = this.translateMeridiem(`${formatedDate} ${rg.endTime}`, 'es');
                    //     }
                    //     const stendTime = rg.status != 0 ? `${this.replaceZeroMins(esStartTime)} - ${this.replaceZeroMins(esEndTime)}` : `${window.globalLanguage.closed}`
                    //     this.listOfRegHrs.push(`${thisDay}: ${stendTime}`);
                    // }
                };
                this.daysOfWeekDisabled = this.breakDownOfRegHrs.filter(item=>item.status===0).map(item=>this.days.indexOf(item.day));
            } else if (this.regularHours?.length == 1) {
                this.todayStdStartTime = this.regularHours[0].startTime;
                this.todayStdEndTime = this.regularHours[0].endTime;
            }
        },
        translateMeridiem (dateTime, lang) {
            let formatedTime = null;
            if (lang == 'zh') {
                const ampm = dayjs(dateTime).format('A');
                formatedTime = dayjs(dateTime).format('h:mm') + '点';
                formatedTime =  (ampm.toLowerCase() === 'am' ? '早上' : '晚上') + formatedTime;
            } else {
                formatedTime = dayjs(dateTime).locale(lang).format('h:mm A');
            }
            return formatedTime;
        },
        replaceZeroMins (minsStr) {
            if(!minsStr) return '';
            return minsStr.replace(':00', '');
        },
        toggleOfficeHours () {
            // To toggle in the source id container
            // Toggle only when there is more than one office time.
            setInterval(() => {
                let activeTime = this.activeTime;
                this.activeTime = activeTime === ( this.listOfRegHrs && this.listOfRegHrs.length-1) ? 0 : ++activeTime;
            }, 3500);
        },
        copyContact () {
            const copyBtn = document.querySelector('.c-Nav-M__action--copy');
            if (copyBtn) {
                let classList = copyBtn.classList;
                if (classList) classList = Array.from(classList);
                if (classList.indexOf('c-Nav-M__action--copied') == -1) {
                    copyBtn.style.minWidth = copyBtn.clientWidth+'px';
                }
            }
            let itemsToCopy = document.querySelectorAll('.contact-us-list .contact-us-item .cliped');
            let finalText = '';
            if (itemsToCopy) itemsToCopy = Array.from(itemsToCopy);
            if (itemsToCopy && itemsToCopy.length) {
                for (let i = 0; i < itemsToCopy.length; i++) {
                    const listItem = itemsToCopy[i];
                    if (i > 0) finalText += '\n';
                    finalText += listItem.innerText;
                }
            }
            copyToClipboard(finalText);
            copyBtn.classList.add('c-Nav-M__action--copied');
            setTimeout(() => {
                copyBtn.classList.remove('c-Nav-M__action--copied');
            }, 1500);
        }
    }
}