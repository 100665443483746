"use strict";

//fix merge problem

import {createUpdateQuoteService} from "../helper-functions/services";
import {validateEmail, isChromeBrowser} from '../helper-functions/helper-functions';

import Vue from 'vue';
import VueResource from 'vue-resource';
import Vuelidate from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import {ibSpinOpts} from '../helper-functions/applicationCommonValues';

Vue.use(VueResource);
Vue.use(Vuelidate);

//override global spinner
const ibSpinOpts1 = ibSpinOpts;

Vue.component('share-form', {
    data: function () {
        return {
            emailSending: false,
            emailSent: false,
            form: {
                message: '',
                recipientEmailList: [
                    {recipientEmail: ''},
                    {recipientEmail: ''}
                ],
                referralURL: window.globalLanguage.referralUrl, 
                senderName: '',
                senderEmail: '',
                subject: '',
                verificationCode: ''
            },
            maxRecipients: 10,
            shareFormSSErrors: {
                fieldErrors: [],
                globalErrors: []
            },
            minimumRecipient : false,
            validPersonalEmail: true,
            validEmail: true,
            showMobileError: false,
            isChrome: isChromeBrowser(),
            myAccountLogin: window.globalExtra ? window.globalExtra.myAccountLogin : false,
        }
    },
    computed: {
        emailFormShownC: function() {
            if (this.emailSent) {
                return false;
            } else {
                return true;
            }
        }
    },
    mounted: function() {
        Vue.nextTick(() => {
            this.resetCaptcha();
        })
    },
    validations :  function () {
        var validations = {
            form : {
                senderName: {required},
                senderEmail : {required, email},
                verificationCode : {required},
                recipientEmailList: {
                    $each: {
                        recipientEmail: {
                            isNotDuplicateEmail
                        }
                    }
                }
            }
        };

        function isNotDuplicateEmail () {
            let flag = true;
            if(this.form.recipientEmailList.length && this.validEmail) {
                let allEmails = [];
                for(let i = 0; i < this.form.recipientEmailList.length; i++) {
                    const thisEmail = this.form.recipientEmailList[i].recipientEmail ? this.form.recipientEmailList[i].recipientEmail.trim() : '';
                    if(allEmails.indexOf(thisEmail) === -1 && thisEmail) {
                        allEmails.push(thisEmail);                        
                    } else if(thisEmail) {
                        flag = false;
                        break;
                    }
                }
            }
            return flag;
        }

        if (this.myAccountLogin) {
            delete validations.form.verificationCode;
        }

        return validations;
    },
    methods: { 
        addRecipient: function() {
            this.form.recipientEmailList.push({recipientEmail: ''});
        },
        sendEmail: function() {
            this.showMobileError = false;
            var self = this;
            // Spinner
            var spinTarget = self.$refs.btnspinner;
            var spinner = new IBSpinner(ibSpinOpts1).spin(spinTarget);
            self.emailSending = true;
            var data = self.form;
            this.updateEmailVal();

            self.$v.form.$touch();
            if (!this.$v.$invalid && this.minimumRecipient && this.validEmail) {
                self.shareFormSSErrors.globalErrors = [];
                self.shareFormSSErrors.fieldErrors = [];

                let emailArr = [];
                //loop uses 'in; instead of 'of' because IE throws Symbol error
                for(let i in data.recipientEmailList) {
                    let x = data.recipientEmailList[i];
                    if(x.recipientEmail.length > 0) {
                        emailArr.push(x);
                    }
                }
                data.recipientEmailList = emailArr;
                // Spinner
                // var spinTarget = document.getElementById('email-spinner');
                // var spinner = new IBSpinner(ibSpinOpts).spin(spinTarget);
                // self.emailSending = true;

                self.$http.post('/script/referfriend/', data)
                    .then(
                        function (response) {
                            if(response) {
                                var responseBody = response.body;

                                if (responseBody.status === 'error') {
                                    self.emailSending = false;
                                    self.resetCaptcha();
                                    self.form.verificationCode = '';

                                    if (responseBody.data.globalErrors) {
                                        self.shareFormSSErrors.globalErrors = responseBody.data.globalErrors;
                                    }
                                    if (responseBody.data.fieldErrors) {
                                        self.shareFormSSErrors.fieldErrors = responseBody.data.fieldErrors;
                                    }
                                    if (self.form.recipientEmailList.length === 0) {
                                      
                                        self.form.recipientEmailList = [
                                            {recipientEmail: ''},
                                            {recipientEmail: ''}
                                        ];
                                    }

                                    $("#modal-body").animate({scrollTop: 0}, "fast");
                                } else {
                                    //Email sent successfully
                                    self.emailSending = false;
                                    self.emailSent = true;
                                    self.$v.form.$reset();
                                    self.form.recipientEmailList = [
                                        {recipientEmail: ''},
                                        {recipientEmail: ''}
                                    ];

                                    self.shareFormSSErrors.globalErrors = [];
                                    self.shareFormSSErrors.fieldErrors = [];
                                }
                            }                            
                        },
                        function (response) {
                            self.emailSending = false;
                        }
                    );
            } else {
                this.showMobileError = true;
                self.emailSending = false;
                $("#modal-body").animate({ scrollTop: 0 }, "fast");
            }
        },
        scrollingToTop: function(e) {
            $("#modal-body").animate({scrollTop: 0}, "fast");
                e.preventDefault();
                $("#close_button").focus();
                // this.$refs.closebtn.focus();
        },
        resetCaptcha: function () {
            this.form.verificationCode = '';

            if (this.$refs && this.$refs.captchaimg) {
                let captchaSrc = this.$refs.captchaimg.src;
                let indexOfSrc = captchaSrc.indexOf('?');
    
                if(indexOfSrc > -1) {
                    captchaSrc = captchaSrc.slice(0, indexOfSrc);
                }
                this.$refs.captchaimg.src = captchaSrc + '?' + new Date().valueOf();
            }
        },
        updateEmailVal: function(index){
            this.validEmail=true;     
            for(let i = 0; i < this.form.recipientEmailList.length; i++ ){
                var validated = validateEmail(this.form.recipientEmailList[i].recipientEmail);
                if(this.form.recipientEmailList[i].recipientEmail.length > 0 && !validated){
                    this.validEmail = false;
                    break;
                } 
            }
            for(let i = 0; i < this.form.recipientEmailList.length; i++ ){
                if(this.form.recipientEmailList[i].recipientEmail != ''){
                    this.minimumRecipient = true;                    
                    break;
                }
                else this.minimumRecipient = false;
            }
            
            let indexedInput = document.getElementsByClassName('index-share-recipients')[index];
            
            if(indexedInput){
                this.form.recipientEmailList[index].recipientEmail = indexedInput.value;
            }

        }
    }
});

Vue.component('share-modal', {
    data: function () {
        return {
        }
    },
    mounted: function() {
        // $('#js-ShareModal-wrap').click(e => {
        //     if(e.target.id == 'js-ShareModal-wrap') {
        //         $('body').removeClass('is-Locked');
        //         this.$emit('close');
        //     }
        // });
        $("#sender_name").focus();
    },
    methods: {
        sendForm: function () {
            self.$http.get(this.modalUrl)
                .then(
                    function (response) {
                        if (response.status === 200) {
                            self.content = response.body;
                        } else {
                            self.content = problemMsg;
                        }
                    },
                    function (response) {
                        self.content = problemMsg;
                    }
                );
        },
        scrollToTop: function () {
            this.$refs.modalbody.scrollTop = 0;
        }
    }
});
