<template>
    <div v-if="qResultsLang">
        <!-- Timezone -->
        <div :class="type === 'general' ? 'c-ibApp__form-wrap--full' : 'c-ibApp__form-wrap--half'" :style="type === 'general' ? 'margin-bottom: 1.25em;' : ''">
            <label class="c-ibApp__form-label">{{qResultsLang.timezone}}</label>
            <select id="timezone" v-model="timezoneDis" class="form-control form-control-text form-control--custom-arrow c-Contact-form__time-field" :disabled="disabled">
                <option selected="selected" value="auto" style="font-weight:bold">{{qResultsLang.timezoneAuto}}</option>
                <option value="Pacific/Chatham">(GMT +12:45) - {{qResultsLang.chathamIslands}}</option>
                <option value="Pacific/Fiji">(GMT +12:00) - {{qResultsLang.fiji}}</option>
                <option value="Pacific/Noumea">(GMT +11:00) - {{qResultsLang.noumea}}</option>
                <option value="Australia/Lord_Howe">(GMT +10:30) - {{qResultsLang.lordHoweIsland}}</option>
                <option value="Australia/Brisbane">(GMT +10:00) - {{qResultsLang.brisbane}}</option>
                <option value="Australia/Adelaide">(GMT +09:30) - {{qResultsLang.southAustralia}}</option>
                <option value="Asia/Tokyo">(GMT +09:00) - {{qResultsLang.japan}}</option>
                <option value="Australia/Eucla">(GMT +08:45) - {{qResultsLang.westernAustralia}}</option>
                <option value="Asia/Shanghai">(GMT +08:00) - {{qResultsLang.china}}</option>
                <option value="Asia/Bangkok">(GMT +07:00) - {{qResultsLang.bangkok}}</option>
                <option value="Asia/Rangoon">(GMT +06:30) - {{qResultsLang.yangon}}</option>
                <option value="Asia/Dhaka">(GMT +06:00) - {{qResultsLang.bangladesh}}</option>
                <option value="Asia/Kathmandu">(GMT +05:45) - {{qResultsLang.nepal}}</option>
                <option value="Asia/Kolkata">(GMT +05:30) - {{qResultsLang.india}}</option>
                <option value="Asia/Ashgabat">(GMT +05:00) - {{qResultsLang.turkmenistan}}</option>
                <option value="Asia/Dubai">(GMT +04:00) - {{qResultsLang.dubai}}</option>
                <option value="Iran">(GMT +03:30) - {{qResultsLang.Iran}}</option>
                <option value="Asia/Baghdad">(GMT +03:00) - {{qResultsLang.baghdad}}</option>
                <option value="Africa/Tripoli">(GMT +02:00) - {{qResultsLang.libya}}</option>
                <option value="CET">(GMT +01:00) - {{qResultsLang.cut}}</option>
                <option value="Etc/Greenwich">(GMT +00:00) - {{qResultsLang.gmt}}</option>
                <option value="Atlantic/Cape_Verde">(GMT -01:00) - {{qResultsLang.cabo}}</option>
                <option value="Atlantic/South_Georgia">(GMT -02:00) - {{qResultsLang.sg}}</option>
                <option value="America/Sao_Paulo">(GMT -03:00) - {{qResultsLang.saoPaulo}}</option>
                <option value="America/Cuiaba">(GMT -04:00) - {{qResultsLang.atlantic}}</option>
                <option value="America/New_York">(GMT -05:00) - {{qResultsLang.eastern}}</option>
                <option value="America/Chicago">(GMT -06:00) - {{qResultsLang.central}}</option>
                <option value="America/Denver">(GMT -07:00) - {{qResultsLang.mountain}}</option>
                <option value="America/Los_Angeles">(GMT -08:00) - {{qResultsLang.pacific}}</option>
                <option value="US/Alaska">(GMT -09:00) - {{qResultsLang.alaska}}</option>
                <option value="Pacific/Marquesas">(GMT -09:30) - {{qResultsLang.marquesasIslands}}</option>
                <option value="Pacific/Tahiti">(GMT -10:00) - {{qResultsLang.hawaii}}</option>
                <option value="Pacific/Niue">(GMT -11:00) - {{qResultsLang.niue}}</option>
            </select>
        </div>
        <!-- Date -->
        <div :class="type === 'general' ? 'c-ibApp__form-wrap--half' : 'c-ibApp__form-wrap--half-r'">
            <div class="date-field">
                <label for="date" class="c-ibApp__form-label">{{qResultsLang.date}}</label>
                <date-picker v-model="date" :start-date="startDate" :q-results-lang="qResultsLang" :disabled="disabled" inline-template>
                    <div class="c-ibApp__form-rel">
                        <input type="text" class="form-control" @input="handleInputEvt" :value="value || ''" :disabled="disabled"
                            maxlength="10" v-bind:placeholder="qResultsLang.dateFormat"/>
                        <span class="o-Icon-menu__date"></span>
                    </div>
                </date-picker>
            </div>

            <div class="o-Valid-msg" :class="{'inValid': $v.date.$error || noCallBack}" v-cloak>
                <!-- <p class="error-check" v-if="$v.date.$error && !$v.date.required">
                    {{qResultsLang.dateError}}
                </p> -->
                <p class="error-check" v-if="$v.date.$error && !$v.date.numbersWithDashSlash">
                    {{qResultsLang.alphaNumeric}}
                </p>
                <p class="error-check" v-else-if="$v.date.$error && !$v.date.noAlphabets">
                    {{qResultsLang.noAlphabets}}
                </p>
                <p class="error-check" v-else-if="$v.date.$error && !$v.date.monthDate">
                    {{qResultsLang.wrongDateFormat}}
                </p>
                <p class="error-check" v-else-if="$v.date.$error && !$v.date.invalidDate">
                    {{qResultsLang.invalidDate}}
                </p>

                <p class="error-check" v-else-if="$v.date.$dirty && isPast">
                    {{qResultsLang.mustBeInPast}}
                </p>
                <p class="error-check" v-else-if="isTwoWeeksLater || beError === 'CALLBACK_REQUEST_INVALID'">
                    {{qResultsLang.cannotBeTwoWeeksLater}}
                </p>
                <p class="error-check" v-else-if="!isPast && noCallBack">
                    {{qResultsLang.reqCallHoliay}}
                </p>
            </div>
        </div>
        <!-- Time -->
        <div :class="timeToLeft ? 'c-ibApp__form-wrap--half' : 'c-ibApp__form-wrap--half-r'">
            <label class="c-ibApp__form-label">{{qResultsLang.time}}</label>
            <div class="time-field">
                <div class="c-ibApp__form-wrap--half" style="margin-top: 0; margin-bottom: 0.5em;">
                    <select v-model="timeHour" class="form-control" :disabled="!date || disabled || isPast || disableTime">
                        <option selected="selected" disabled value="">hh</option>
                        <option :key="hour" v-for="hour in timeHours">{{hour}}</option>
                    </select>
                </div>
                <div class="c-ibApp__form-wrap--half-r">
                    <select v-model="timeMinute" class="form-control" :disabled="!date || disabled || isPast || disableTime">
                        <option selected="selected" disabled value="">mm</option>
                        <option :key="minute" v-for="minute in timeMinutes">{{minute}}</option>
                    </select>
                </div>
            </div>
            <div class="o-Valid-msg" :class="{'inValid': $v.timeHour.$error}" v-cloak>
                <p class="error-check" v-if="$v.timeHour.$error && !$v.timeHour.required && !isPast">
                    {{qResultsLang.timeErrorMsg}}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import dayjs from 'dayjs';
    import utc from 'dayjs/plugin/utc';
    import timezone from 'dayjs/plugin/timezone';
    import { required } from 'vuelidate/lib/validators';

    import datePicker from '../../components/forms/component.date-picker.vue';
    
    dayjs.extend(utc);
    dayjs.extend(timezone);
    
    import {
        numbersWithDashSlash,
        noAlphabets,
        monthDate,
        convertDateToEN
    } from '../../helper-functions/helper-functions';

    const Mapp = require('es6-map'); // pollyfill for Map SENTRY-1069

    export default {
        props: ['timeToLeft', 'type', 'disabled', 'beError', 'disabledDatesTime', 'daysOfWeekDisabled'], 
        components: {
            'date-picker': datePicker
        },
        data: function () {
            return {
                date: '',
                startDate: new Date(),
                timeHour: '',
                timeMinute: '',
                timezoneDis: 'auto',
                timezoneCal: '',
                timeHours: [],
                timeMinutes: [],
                qResultsLang: window.qResultsLangGlobal && typeof window.qResultsLangGlobal == "object" && window.qResultsLangGlobal.contactTime ? window.qResultsLangGlobal.contactTime : {},
                AM: 'AM',
                PM: 'PM',
                timeFormat: 'YYYY-MM-DDTHH:mm:ss',
                isPast: false,
                isTwoWeeksLater: false,
                hoursMinutes: typeof Map === "function" ? new Map() : new Mapp(),
                curDestTime: '',
                disableTime: false,
                disabledDates: [],
                allHoursMins: [],
                noCallBack: false
            }
        },
        validations: function(){
            let validations = {
                date: {
                    numbersWithDashSlash,
                    noAlphabets,
                    monthDate,
                    invalidDate
                },
                timeHour: {}
            };

            if(this.date) {
                validations.timeHour.required =  required => { return this.timeHour.length > 0 && this.timeMinute.length > 0;}
            }

            function invalidDate (date) {
                let isvalid = true;
                if (date && dayjs(date,'MM/DD/YYYY').isValid()) {
                    if (this.disabledDates.indexOf(date) > -1) isvalid = false;
                }
                return isvalid;
            }

            return validations;
        },
        watch: {
            date: function(newDate) {
                this.isPast = false;
                this.isTwoWeeksLater = false;
                const date2 = dayjs(this.getFormattedDate(newDate)).format('YYYY-MM-DD');
                try {
                    let userTime = dayjs.tz(`${date2}`, this.timezoneDis);
                    this.isTwoWeeksLater = dayjs().tz(this.timezoneDis).diff(userTime, 'days') < -14;
                } catch (error) {
                    //ignore.
                }
                // this.getHours();
                this.fetchHoursMins();
                try {
                    this.curDestTime = dayjs.tz(new Date(), this.timezoneCal);
                } catch (error) {
                    //ignore.
                }
                let datepickerLang = window.globalLanguage.lang;
                 switch (window.globalLanguage.lang){
                    case 'ar':
                        datepickerLang = 'es';
                        break;
                    case 'ja':
                        datepickerLang = 'zh';
                        break;
                    case 'ko':
                        datepickerLang = 'zh';
                        break;
                };
                let enteredDateInEN = convertDateToEN(this.date, datepickerLang);
                let enteredDate = dayjs(new Date(enteredDateInEN));


                if(this.curDestTime && enteredDate.diff(this.curDestTime.format('MM/DD/YYYY')) < 0) {
                    this.isPast = true;
                } else {
                    this.isPast = false;
                }
            },
            timezoneDis: function(val) {
                if (val === 'auto') {
                    this.detectTimezone();
                } else {
                    this.timezoneCal = val;
                }
                this.date = '';
                this.noCallBack = false;
                try {
                    this.curDestTime = dayjs.tz(new Date(), this.timezoneCal);
                } catch (error) {
                    //ignore.
                }
                if (this.curDestTime) {
                    this.startDate = new Date(this.curDestTime.format('MM/DD/YYYY HH:mm'));
                }
            },
            timeHour: function(val) {
                if (!this.date) return;
                // this.getMinutes(val);
                this.timeMinute = '';
                for(let i = 0; i < this.allHoursMins.length; i++) {
                    const thisHr = this.allHoursMins[i];
                    if (val === thisHr.hour) {
                        this.timeMinutes = thisHr.minutes;
                        break;
                    }
                }
            }
        },
        mounted: function() {
            if (this.disabledDatesTime && this.disabledDatesTime.length) {
                this.disabledDates = this.disabledDatesTime.filter(item=>item.status===0).map(item=>new Date(item.date));
            }
            this.detectTimezone();
        },
        methods: {
            getResult() {
                if(this.$v.$invalid){
                    return;
                }

                const s = this.timeHour.split(' ');
                if (s.length === 2) {
                    let hour = s[0]*1;
                    // convert to 24 hrs
                    if (s[1] === this.PM && hour != 12) {
                        hour += 12;
                    }
                    else if (s[1] === this.AM && hour == 12) {
                        hour = 0;
                    }
                    const date = dayjs(this.getFormattedDate(this.date)).format('MM/DD/YYYY');
                    const time = (hour < 10 ? '0' + hour : hour) + ':' + (this.timeMinute.length === 1 ? '0' + this.timeMinute : this.timeMinute);
                    let userTimeStr = `${date} ${time}`;

                    const date2 = dayjs(this.getFormattedDate(this.date)).format('YYYY-MM-DD');
                    try {
                        let userTime = dayjs.tz(`${date2}`, this.timezoneDis);
                        this.isTwoWeeksLater = dayjs().tz(this.timezoneDis).diff(userTime, 'days') < -14;
                    } catch (error) {
                        //ignore.
                    }

                    return {
                        userTimeStr,
                        timezone: this.timezoneDis,
                        isPast: this.isPast,
                        isTwoWeeksLater: this.isTwoWeeksLater
                    }
                }
            },
            createTimezoneOption: function(detectedTimezone) {
                let select = document.getElementById("timezone");
                let option = document.createElement("option");
                option.text = detectedTimezone;
                option.value = detectedTimezone;
                if (select) {
                    select.insertBefore(option, select.firstChild);
                }
                this.timezoneDis = detectedTimezone;
            },
            detectTimezone: function() {
                let detectedTimezone;
                try {
                    detectedTimezone = dayjs.tz.guess();
                } catch (error) {
                    detectedTimezone = 'America/Chicago';
                }
                
                if (this.isOptionExist(detectedTimezone)) {
                    this.timezoneDis = detectedTimezone;
                } else {
                    this.createTimezoneOption(detectedTimezone);
                }

                this.timezoneCal = detectedTimezone;
            },
            isOptionExist: function(val) {
                return document.querySelectorAll('#timezone option[value="' + val + '"]').length > 0;
            },
            getMinutes: function(val) {
                this.timeMinutes = [];
                this.timeMinute = '';
                if (this.hoursMinutes.get(val)) {
                    this.hoursMinutes.get(val).forEach(e=>{this.timeMinutes.push(e)});
                }
            },
            getFormattedDate: function(date) {
                let dateParts, year, month, day;
                const lang = window.globalLanguage.lang || 'en';
                if(lang === 'zh' || lang === 'ko' || lang === 'ja') {
                    dateParts = date.split('-');
                    year = dateParts[0];
                    month = dateParts[1];
                    day = dateParts[2];
                    return `${month}/${day}/${year}`
                } else if (lang === 'es' || lang === 'ar') {
                    dateParts = date.split('/');
                    day = dateParts[0];
                    month = dateParts[1];
                    year = dateParts[2];
                    return `${month}/${day}/${year}`
                } else if(lang === 'en') {
                    return date;
                } else {
                    return dayjs(date).format('MM/DD/YYYY')
                }
            },
            reset: function() {
                this.date = '';
                this.timeHour = '';
                this.timeMinute = '';
                this.timeHours = [];
                this.timeMinutes = [];

                this.detectTimezone();
                this.$v.$reset();
            },
            fetchHoursMins () {
                if (this.date && this.date.length === 10 && dayjs(this.getFormattedDate(this.date)).isValid()) {
                    this.timeHour = '';
                    this.noCallBack = false;
                    this.timeMinute = '';
                    const userTimezone = this.timezoneCal;
                    const userTimeStr = this.getFormattedDate(this.date);
                    this.$http.get('/api/request-call-back/', {params: {
                        userTimezone,
                        userTimeStr
                    }}).then(res=>{
                        const responseBody = res.body;
                        if (responseBody && responseBody.success) {
                            if (responseBody.callBackHours && responseBody.callBackHours.length) {
                                this.allHoursMins = responseBody.callBackHours;
                                this.timeHours = this.allHoursMins.map(hr=>hr.hour);
                            } else {
                                this.noCallBack = true;
                                this.allHoursMins = [];
                                this.timeHours = [];
                                this.timeMinutes = [];
                            }
                        }
                    })
                }
            }
        }
    }
</script>