export default {
    data () {
       return {
            loadedFromSession: false
       }
    },
    computed: {
        /**
         * This method is used to set the class "c-Quote-Form__success" when the following conditions are met.
         * 1. When a user clicks on Get Quotes button on our quote landing pages.
         * 2. Only when the form is completed without errors.
         * 3. Once per user, which is tracked by session values.
         * @returns Boolean | true/false
         */
        initialFormSuccess() {
            /**
             * $invalid = no errors in the form
             * isResultsPage = only on the quote landing page
             * formSending = prevent duplicate clicks
             * loadedFromSession = only once per session
             */
            const triggerGTM = !this.$v.$invalid && !this.isResultsPage && !this.formSending && !this.loadedFromSession;

            return triggerGTM;
        }
    }
}