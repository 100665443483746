import accounting from 'accounting';

import { clientsViewPreference } from './helper-functions/constants-configs';
import { changeInSessionStorage } from './helper-functions/services';

import { 
    accountingSettings,
    checkAgent,
} from './helper-functions/helper-functions';

export default {
    data() {
        return {
            qResultsLang: window.qResultsLang, 
            lang: window.globalLanguage.lang,
            affiliatevalues: window.ibJS.affiliatevalues,
            listLayout: true, //multiple
            thumbLayout: false, //multiple
            minimized: false, //multiple
            allMinimized: false, //multiple
            selectedNetwork: 1, // multiple
            isPlanDetailsVisible: false, // multiple
            isPriceBreakdownVisible: false, // multiple
        }
    },
    created() {
        // INSUBUY-5026
        window.globalLanguage.referralUrl = location.href;

        // using global for work around with rendering summary and regular $emit not working
        if(global && global.eventHub){ 
            global.eventHub.$on('showModalFromSummary', this.showModal);
        } 
        if(navigator.cookieEnabled && sessionStorage !== undefined && sessionStorage !== null && sessionStorage.length) { 
            let storageSection = sessionStorage.getItem('storageSection');

            // Needs reworking for multiple pages that will use list and thumb layout
            if ( storageSection === 'static' ) {
               this.listLayout = sessionStorage?.getItem('listLayout') === "false" ? false : true;
               this.thumbLayout = sessionStorage?.getItem('thumbLayout') === "true" ? true : false
            } else {
               this.listLayout = true;
               this.thumbLayout = false;
            }
        }

        //Travel Insurance by Destination/Activity, health insurace by Destination set to thumb layout by default
        var currentPage = window.location.pathname;
        if(currentPage=="/travel-insurance-coverage-by-destination/" || 
            currentPage=="/lvyou-baoxian-mudidi-chengbao/" || 
            currentPage=="/cobertura-de-seguro-de-viaje-por-destino/" || 
            currentPage=="/travel-insurance-coverage-by-activity/" || 
            currentPage=="/lvyou-baoxian-huodong-chengbao/" || 
            currentPage=="/cobertura-de-seguro-de-viaje-por-actividad/" ||
            currentPage=="/international-health-insurance-by-destination/" || 
            currentPage=="/seguro-de-salud-internacional-por-destino/" || 
            currentPage=="/an-mudidi-huafende-guoji-jiankang-baoxian/" ||
            currentPage=="/schengen-visa-compliant-travel-insurance/" || 
            currentPage=="/seguro-de-viaje-compatible-con-la-visa-schengen/" || 
            currentPage=="/shengen-qianzheng-lvyou-baoxian/" ||
            (window.ibJS.currentPage && (currentPage==window.ibJS.currentPage.africa ||
            currentPage==window.ibJS.currentPage.africaCountries ||
            currentPage==window.ibJS.currentPage.africaCities ||
            currentPage==window.ibJS.currentPage.asia ||
            currentPage==window.ibJS.currentPage.asiaCountries ||
            currentPage==window.ibJS.currentPage.asiaCities ||
            currentPage==window.ibJS.currentPage.caribbean ||
            currentPage==window.ibJS.currentPage.centralAmerica ||
            currentPage==window.ibJS.currentPage.europe ||
            currentPage==window.ibJS.currentPage.europeCountries ||
            currentPage==window.ibJS.currentPage.europeCities ||
            currentPage==window.ibJS.currentPage.oceania ||
            currentPage==window.ibJS.currentPage.southAmerica ||
            currentPage==window.ibJS.currentPage.unitedStates ||
            currentPage==window.ibJS.currentPage.mexico ||
            currentPage==window.ibJS.currentPage.usCities ||
            currentPage==window.ibJS.currentPage.usStates ||
            currentPage==window.ibJS.currentPage.usNationalParks ||
            currentPage==window.ibJS.currentPage.sports ||
            currentPage==window.ibJS.currentPage.groundSports ||
            currentPage==window.ibJS.currentPage.athleticSports ||
            currentPage==window.ibJS.currentPage.airSports ||
            currentPage==window.ibJS.currentPage.mountainSports ||
            currentPage==window.ibJS.currentPage.waterSports ||
            currentPage==window.ibJS.currentPage.canada ||
            currentPage==window.ibJS.currentPage.schengenVisa))){
            
            if(navigator.cookieEnabled && sessionStorage !== undefined && sessionStorage !== null && sessionStorage.length) {
                let storageSection = sessionStorage.getItem('storageSection');
                
                if ( storageSection === 'static' ) {
                   this.listLayout = sessionStorage.getItem('listLayout') === "true" ? true : false;
                   this.thumbLayout = sessionStorage.getItem('thumbLayout') === "false" ? false : true;
                } else {
                   this.listLayout = false;
                   this.thumbLayout = true;
                }
            } else {
               this.listLayout = false;
               this.thumbLayout = true;
            }
        }  
    },
    mounted() {
        // multiple pages including guide and  video library
        let $primCat = document.querySelector(".js-toggleCat"),
             timeout = false, // holder for timeout id
             delay = 250, // delay after event is "complete" to run callback
             calls = 0;
        let self = this;

         // window.resize callback function
        function getDimensions() {
            setTimeout(() => {                            
                const innerWidth = window.innerWidth;
                $primCat = document.querySelectorAll(".js-toggleCat");
                const isMobileDev = innerWidth <= 543;
                let hasClass = !isMobileDev ? true : false;
                let totalCount = $primCat.length;
                let count = 0;
                for (let i = 0; i < totalCount; i++) {
                    hasClass = $primCat[i].classList.contains('cat-toggle');
                    if (!isMobileDev && !hasClass) {
                        count += 1;
                    } else if (isMobileDev && hasClass) {
                        count += 1;
                    }
                }

                if (!isMobileDev && count === 0) {
                    self.allMinimized = true;
                } else if (!isMobileDev && count === totalCount) {
                    self.allMinimized = false;
                } else if (isMobileDev && count === 0) {
                    self.allMinimized = false;
                } else if (isMobileDev && count === totalCount) {
                    self.allMinimized = true;
                }
            },1000);
            calls += 1;
        }

         // window.resize event listener
         window.addEventListener('resize', function () {
             // clear the timeout
             clearTimeout(timeout);
             // start timing for event "completion"
             timeout = setTimeout(getDimensions, delay);
         });

         getDimensions();
         $(".js-toggleCat").on('click', getDimensions);
        
        setTimeout(() => {
            if (window.ibJS.isFrame) {
                checkAgent.call(this, [false]);
            }
        }, 1500);
        
        // z-index issue
        let elem = document.getElementsByClassName('c-Head-M__prim-desk c-Desk-H');
        if (elem && elem[0]) {
            elem[0].style.zIndex = "2";
        }
    },
    destroyed() {
        window.removeEventListener("resize", this.handleWindowResize);  // multiple pages including guide and  video library 
    },
    computed: {
        linkModalType: function () { //multiple pages
            let type = null;
            let articleLinkType = window.globalLanguage.articleUrlType;
            let buttonList = $(".c-Plan-h__btn-wrap .c-Plan-h__btn-wrap--multi .c-Article__mutli-btn");
            let hasThreeBtn = false;
            if(buttonList && buttonList.length == 3) {
                hasThreeBtn = true;
            }
            let typeList = [];
            buttonList.each((i, e) => {
                if ($(e).attr("href") === articleLinkType.travelHealthInsurance || 
                    $(e).attr("href") === articleLinkType.covid19Insurance) {
                    type = "travelout";
                    typeList.push(type);
                } else if ($(e).attr("href") === articleLinkType.visitorMedicalInsurance) {
                    type = "visitors";
                    typeList.push(type);
                } else if ($(e).attr("href") === articleLinkType.tripInsurance) {
                    type = "trip";
                    typeList.push(type);
                } else if ($(e).attr("href") === articleLinkType.schengenInsurance) {
                    type = "schengen";
                    typeList.push(type);
                } else if ($(e).attr("href")) {
                    type = "other";
                    typeList.push(type);
                }
            });

            if (typeList.length == 2 && typeList.includes('travelout') && typeList.includes('trip')) {
                return type = 'travelmeddiff';
            } else if (typeList.length == 2 && typeList.includes('visitors') && typeList.includes('trip')) {
                return type = 'visitorsdiff';
            } else if (typeList.length == 2 && typeList.includes('visitors') && typeList.includes('travelout')) {
                return type = 'visitortravelmeddiff';
            } else if (typeList.length == 2 && typeList.includes('schengen') && typeList.includes('trip')) {
                return type = 'schengenquotes';
            }  else if (typeList.length == 3 && typeList.includes('visitors') && typeList.includes('travelout')&& typeList.includes('trip')) {
                return type = 'visitortravelmedtripdiff';
            }
            return null;
        },
    },
    filters: {
        currency: function(value) {
            if(value === null) return '';
  
            accounting.settings = accountingSettings(true);
            return accounting.formatMoney(value);
        }
    },
    methods: {
        goToMyAcc: function() { // multiple
            changeInSessionStorage(clientsViewPreference, 'login');
            window.location.href = window.globalLanguage.myAccUrl;
        },
        showTermsModal: function (e, url) {
            var url = url || null;
            var target = e.currentTarget || e.target;
            // SENTRY-783
            if (url != null && (url.indexOf('$PLANID') > -1 || url.indexOf('$SECTION') > -1) && target.dataset) {
                url = url.replace('$PLANID', target.dataset.planId);
                url = url.replace('$SECTION', target.dataset.section);
            }
            if(target && target.dataset && target.dataset.optionalCoverages == 'true') {
                url += '&termsModal=true';
            }
            this.showModal(url);
        },
        showGeoModal: function (e, url) {
            var url = url || null;
            var target = e.currentTarget || e.target;
            if (url != null && url.indexOf('$PLANID') > -1 && target.dataset) {
                url = url.replace('$PLANID', target.dataset.planId);
                url = url.replace('$PLANTYPE', target.dataset.planType);
                url = url.replace('$CURRENTLANG', target.dataset.currentLang);
            }
            this.showModal(url);
        },
        toggleList: function() { //multiple
            if( this.thumbLayout ) {
                this.thumbLayout = false;
                this.listLayout = true;
                if (navigator.cookieEnabled) {
                    sessionStorage.setItem("storageSection", this.section);
                    sessionStorage.setItem("listLayout", this.listLayout);
                    sessionStorage.setItem("thumbLayout", this.thumbLayout);
                }
            }
        },
        toggleThumb: function() { //multiple
            if( this.listLayout ) {
                this.listLayout = false;
                this.thumbLayout = true;
                if (navigator.cookieEnabled) {
                    sessionStorage.setItem("storageSection", this.section);
                    sessionStorage.setItem("thumbLayout", this.thumbLayout);
                    sessionStorage.setItem("listLayout", this.listLayout);
                }
            }
        },
        toggleMobileTab: function(tab) { // multiple
            if (tab === "details") {
                this.isPlanDetailsVisible = !this.isPlanDetailsVisible

                this.isPriceBreakdownVisible = false;

            } else if (tab === "price") {
                this.isPriceBreakdownVisible = !this.isPriceBreakdownVisible

                this.isPlanDetailsVisible = false;
            }
        },   
    }
}