export const accordion = function() {

  //$(document).ready(function() {
    var allPanels = $('.js-Accord .js-Accord__item').removeClass('js-Accord__is-open');

    $('.js-Accord .js-Accord__heading').click(function(e) {
        e.preventDefault();
        var $this = $(this);
        var $target =  $this.closest('.js-Accord__item');

        if ($target.hasClass('js-Accord__is-open')) {
           $target.removeClass('js-Accord__is-open');

           allPanels.not($target).removeClass('js-Accord__is-open');
        } else {
            $target.addClass('js-Accord__is-open').siblings().removeClass('js-Accord__is-open');
        }

      return false;
    });

    $('.js-Accord .js-Accord__Child').click(function(e){
        e.preventDefault();
        var $this = $(this);
        var $target =  $this.closest('.js-Accord__item');

        if ($target.hasClass('js-Accord__is-open')) {
           $target.removeClass('js-Accord__is-open');
           // allPanels.not($target).addClass('js-Accord__is-open');
        } else {
            $target.addClass('js-Accord__is-open');
        }

      return false;
    });

 // });

};
