import Vue from "vue";
import Vuelidate from 'vuelidate';
import { required, between } from 'vuelidate/lib/validators';
import {dateNotInFuture, spouseAgeValidation, onlyNumbers, monthDate} from '../../helper-functions/helper-functions';
import checkbox from '../components.checkbox.vue';
import radio from '../components.radio-button.vue';
import ageSwitch from './component.age-birthdate-input-switch.vue';

Vue.use(Vuelidate);

Vue.component('quote-form-age', {
    props: {
        
    },
    components: {
        'v-checkbox': checkbox,
        'v-radio': radio,
        'age-switch': ageSwitch
    },
    data: function () {
        return {
            modalVisible: false,
            isFormSubmitted: false,
            defaultValues: {
                startDate: '',
                validationStartDate: null
            },
            form: {
                primaryBday: '',
                primaryAge: '',
                spouseAge: '',
                spouseBday: '',
                numChildren: '',
                showSpouseAge: false,
                showNumChildren: false,
                numChildrenUnder10: '',
                numChildren10_17: '',
                f1Insurance: '-1',
                j1Insurance: '-1'
            },
            primaryByAge: true,
            spouseByAge: true,
            priMinWithKid: false,
            spouseMinorAge: false,
            primaryMinorAge: false,
            section: '',
            visaType: '',
            studyDest: '',
            isStudent: false
        }
    },
    validations () {
        let validations = {
            form: {
                primaryAge: {
                    onlyNumbers
                },
                primaryBday: {},
                spouseAge: {
                    spouseAgeValidation
                },
                spouseBday: {},
                numChildren: {
                    between: typeof between === 'function' ? between(1,7) : null
                },
                numChildrenUnder10: {
                    between: typeof between === 'function' ? between(1,7) : null
                },
                numChildren10_17: {
                    between: typeof between === 'function' ? between(1,7) : null
                },
                f1Insurance: {},
                j1Insurance: {}
            }
        }

        if (this.primaryByAge) {
            validations.form.primaryAge.required = required;
            delete validations.form.primaryBday.required;
            delete validations.form.primaryBday.monthDate;
            delete validations.form.primaryBday.dateNotInFuture;
            if (this.section != 'multitrip') delete validations.form.primaryBday.startDateMissing;
        }
        else {
            delete validations.form.primaryAge.required;
            validations.form.primaryBday.required = required;
            validations.form.primaryBday.monthDate = monthDate;
            validations.form.primaryBday.dateNotInFuture = dateNotInFuture;
            if (this.section != 'multitrip') {
                validations.form.primaryBday.startDateMissing = ()=>{
                    return this.defaultValues.startDate && !this.defaultValues.validationStartDate.$invalid ? true : false;
                }
            }
        }

        if(this.form.showSpouseAge && !this.isStudentF2) {
            if (this.spouseByAge) {
                validations.form.spouseAge.required = required;
                validations.form.spouseAge.onlyNumbers = onlyNumbers;

                delete validations.form.spouseBday.required;
                delete validations.form.spouseBday.monthDate;
                delete validations.form.spouseBday.dateNotInFuture;
                if (this.section != 'multitrip') delete validations.form.spouseBday.startDateMissing;
            } else {
                validations.form.spouseBday.required = required;
                validations.form.spouseBday.monthDate = monthDate;
                validations.form.spouseBday.dateNotInFuture = dateNotInFuture;
                if (this.section != 'multitrip') {
                    validations.form.spouseBday.startDateMissing = ()=>{
                        return this.defaultValues.startDate && this.defaultValues.validationStartDate && !this.defaultValues.validationStartDate.$invalid ? true : false;
                    }
                }

                delete validations.form.spouseAge.required;
                delete validations.form.spouseAge.onlyNumbers;
            }            
        }

        if (this.isStudentF2) {
            validations.form.f1Insurance.isrequired = (val)=>{return val != -1 && val != '';};
        }

        if (this.isStudentJ2) {
            validations.form.j1Insurance.isrequired = (val)=>{return val != -1 && val != '';};
        }

        if(this.form.showNumChildren) {
            if (!this.isStudent) validations.form.numChildren.required = required;
            else {
                validations.form.numChildren10_17.required = required;
                validations.form.numChildrenUnder10.required = required;
            }
        }

        if(this.form.numChildren > 0) {
            delete validations.form.numChildren.required;
        }
        if(this.form.numChildrenUnder10 > 0 || this.form.numChildren10_17 > 0){
            if(validations.form.numChildren10_17) {
                delete validations.form.numChildren10_17.required;
                delete validations.form.numChildren10_17.between;
            }
            if(validations.form.numChildrenUnder10) {
                delete validations.form.numChildrenUnder10.required;
                delete validations.form.numChildrenUnder10.between;
            }
        }

        return validations;
    },
    watch: {
        'form.showNumChildren': function (val) {
            if (!val) {
                this.form.numChildrenUnder10 = '';
                this.form.numChildren10_17 = '';
            }
        }
    },
    created () {
        this.$root.$on('quote-modal', (val) => {
            this.modalVisible = val.showModal;
            this.defaultValues.startDate = val.startDate;
            this.defaultValues.validationStartDate = val.validationStartDate;
            this.form.primaryBday = val.form.primaryBday;
            this.form.primaryAge = val.form.primaryAge;
            this.form.spouseAge = val.form.spouseAge != -1 ? val.form.spouseAge : '';
            this.form.spouseBday = val.form.spouseBday;
            this.form.numChildren = val.form.numChildren != -1 && val.form.numChildren != 0 ? val.form.numChildren : '';
            this.form.showSpouseAge = val.form.showSpouseAge;
            this.form.showNumChildren = val.form.showNumChildren;
            
            // Student Visa
            this.section = val.form.section;
            if (this.section === 'student' || this.section === 'opt' || this.section === 'j1Visa') {
                if (this.section === 'student') this.form.f1Insurance = val.form.f1Insurance || '-1';
                if (this.section === 'j1Visa') this.form.j1Insurance = val.form.j1Insurance || '-1';
                this.form.numChildren10_17 = val.form.numChildren10_17;
                this.form.numChildrenUnder10 = val.form.numChildrenUnder10;
                this.visaType = val.form.visaStatus;
                this.studyDest = val.form.studyCountry;
                this.isStudent = true;
            } else {
                delete this.form.numChildren10_17;
                delete this.form.f1Insurance;
                delete this.form.j1Insurance;
                delete this.form.numChildrenUnder10;
            }
            $('body').addClass('is-Locked--quote')
        });
        this.$root.$on('reset-quote-modal', ()=>{
            this.resetForm();
        });
    },
    computed: {
        calculateAges: function() {
            var isVisible = false,
                showSpouseAge = this.form.showSpouseAge,
                primaryAge = this.form.primaryAge,
                spouseAge = this.form.spouseAge,
                numOfChildren = this.form.numChildren;

                this.priMinWithKid = false;
                this.spouseMinorAge = false;
                this.primaryMinorAge = false;

            var showChildren = this.form.showNumChildren;

            if (primaryAge && (typeof primaryAge != 'number' || isNaN(primaryAge))) {
                primaryAge = +primaryAge;
            }

            if (spouseAge && (typeof spouseAge != 'number' || isNaN(spouseAge))) {
                spouseAge = +spouseAge;
            }

            let isStudentCondition = true;
            
            if (this.visaType == 'F2' || this.visaType == 'J2') {
                isStudentCondition = false;
                showChildren = this.form.numChildrenUnder10 > 0 || this.form.numChildren10_17 > 0;
                numOfChildren = this.form.numChildrenUnder10 || this.form.numChildren10_17;
            }

            if (numOfChildren && (typeof numOfChildren != 'number' || isNaN(numOfChildren))) {
                numOfChildren = +numOfChildren;
            }

            if(showSpouseAge && primaryAge && isStudentCondition){
                if(primaryAge < 18){
                    isVisible = true;
                    this.primaryMinorAge = true;
                }
                else if(spouseAge && spouseAge !== -1 && spouseAge < 18 && !isVisible) {
                    this.primaryMinorAge = false;
                    isVisible = true;
                    this.spouseMinorAge = true;
                } else{
                    this.spouseMinorAge = false;
                }
            }
            if(showChildren && primaryAge){
                if(primaryAge < 18 && numOfChildren > 0){
                    isVisible = true;
                    this.priMinWithKid = true;
                }else{
                    this.priMinWithKid = false;
                }
            }

            return isVisible;
        },
        isStudentF2 () {
            let showBlock = false;

            if (this.visaType ==='F2' && this.studyDest ==='USA') {
                showBlock = true;
            }

            return showBlock;
        },
        isStudentJ2 () {
            let showBlock = false;

            if (this.visaType ==='J2') {
                showBlock = true;
            }

            return showBlock;
        }
    },
    methods: {
        showModal: function (url) {
            this.$emit('show-modal', url);
        },
        handleAgeInfoUpdate: function (ageInfo) {
            var person = ageInfo.person.toLowerCase();
            if (person === 'f2 visa holder' || person === 'j2 visa holder' || person === 'f1 visa holder' || person === 'j1 visa holder') person = 'primary';
            if (typeof ageInfo.age !== 'undefined') {
                this.form[person + 'Age'] = ageInfo.age;
            }
            if (typeof ageInfo.bday !== 'undefined') {
                this.form[person + 'Bday'] = ageInfo.bday;
            }
        },
        closeModal (resetForm) {
            this.modalVisible = false;
            if (resetForm) { 
                this.resetForm();
                this.$root.$emit('set-quote-age', {}, true);
            }
            $('body').removeClass('is-Locked--quote')
        },
        submitForm () {
            this.isFormSubmitted = true;
            this.$v.form.$touch();
            this.$root.$emit('set-quote-age', JSON.parse(JSON.stringify(this.form)));
            if (!this.$v.form.$invalid && !this.calculateAges) {
                this.closeModal();
                this.isFormSubmitted = false;
            }
        },
        inputTypeAgeBday (obj) {
            if (obj.person === 'f2 visa holder' || obj.person === 'j2 visa holder' || obj.person === 'f1 visa holder' || obj.person === 'j1 visa holder') obj.person = 'primary';
            this[obj.person+'ByAge'] = obj.inputType;
            if (!this[obj.person+'ByAge']) {
                this.$v.form[obj.person+'Bday'].$touch();
            }
        },
        resetForm () {
            this.$v.$reset();
            this.isFormSubmitted = false;
            this.form = {
                primaryBday: '',
                primaryAge: '',
                spouseAge: '',
                spouseBday: '',
                numChildren: '',
                showSpouseAge: false,
                showNumChildren: false,
                numChildrenUnder10: '',
                numChildren10_17: '',
                f1Insurance: '-1',
                j1Insurance: '-1'
            };
            this.primaryByAge = true;
            this.spouseByAge = true;
        }
    }
});