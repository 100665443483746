"use strict";

import Vue from 'vue';

Vue.component('plan-compare-block', {
    props: ['plan'],
    template: '#compare-plan-templ',
    data: function() {
        return {

        }
    },
    methods: {
        removeCompare: function() {
            this.$emit('remove-compare', this.plan.planId);
        }
    }
});
