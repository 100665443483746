import storagePolyfill from './storage-polyfill';
import fillPolyfill from './fill-polyfill';
import includesPolyfill from './includes-polyfill';
import findPolyfill from './find-polyfill';
import startsWithPolyfill from './startsWith-polyfill';

function polyfillInit() {
  storagePolyfill();
  fillPolyfill();
  includesPolyfill();
  findPolyfill();
  startsWithPolyfill();
}

export default polyfillInit;
