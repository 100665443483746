//Applies to step 5 on all applications.
require('../../../components/forms/component.pci');

export default {
    data() {
        return {
            showPayment: false,
            resetSubmitted: false,
            paymentSuccess: ibJS?.application?.pciPaymentFlow ? false : true,
            paymentError: false,
            paymentInternal: ibJS?.application?.pciPaymentFlow ? ibJS.application.pciPaymentFlow : false, // if false, then show CC details in step5 else if true, hide CC details
            currentSection: 'application'
        }
    },                
    mounted () {
    },
    watch: {
    },
    created () {
        if(ibJS?.application?.pciPaymentFlow) {
            this.form.paymentMethod = ibJS.application.paymentMethod || 'credit';    
        }
    },
    computed: {       
        showDefault () {
            return this.paymentSuccess == false && this.paymentError == false;
        },
        showPaymentSuccess () {
            return this.paymentSuccess && !this.paymentError;
        },
        showPaymentError () {
            return this.paymentError && !this.paymentSuccess;
        },
        pciBillingBlock () {
            return (this.paymentSuccess || this.paymentError);            
        },
        planResultQuoteDuration () {
            return this.application?.planResult?.quotedDuration || null;
        }
    },
    methods: {
        setPaymentSuccess(val) {
            this.paymentSuccess = val;
        },
        setPaymentError(val) {
            this.paymentError = val;
        },
        setResetSubmitted(val) {
            this.resetSubmitted = val;
        },
        showPaymentLink: function() {
            this.showPayment = true;
        },
        setProcessInternally () {
            this.paymentInternal = true;
            this.$http.get('/api/application/process/?id=' +this.application.fullAppId + '&internal=' + this.paymentInternal).then(res => {
                const responseBody = res.body;
                if (responseBody?.status == 'success') {
                    window.location.reload();
                }
            });
        },
        setProcessExternally () {
            this.paymentInternal = false;
            this.$http.get('/api/application/process/?id=' +this.application.fullAppId + '&internal=' + this.paymentInternal).then(res => {
                const responseBody = res.body;
                if (responseBody?.status == 'success') {
                    window.location.reload();
                }
            });
        },
        resetPayment() {
            if(this.$refs.pci && this.$refs.pci.resetPayment && typeof this.$refs.pci.resetPayment == 'function') {
                this.$refs.pci.resetPayment();
            }
        }
    }
};