<template>    
    <div class="o-Content-drop__age">
    <label for="PrimaryAge" class="o-Content-drop__input-label u-Pos-rel o-Content-drop__input-label--with-down-arrow" v-cloak>   

        <div v-if="!showRadio" v-show="ageSwitchShown" 
            class="o-Age-switch">
            <span th:text="#{TripCriteria.enterBy}"
                class="o-Age-switch__enter-by-label">
                {{qResultsLang.enterBy}}
            </span>
            <span @click="toggleAge"
                class="o-Age-switch__enter-by-opt">
                {{qResultsLang.birthDate}}
            </span>
        </div>

        <div v-if="!showRadio" v-show="bdaySwitchShown"
            class="o-Age-switch">
            <span class="o-Age-switch__enter-by-label">
                {{qResultsLang.enterBy}}
            </span>
            <span @click="toggleAge"
                class="o-Age-switch__enter-by-opt">
                {{qResultsLang.age}}
            </span>
        </div>
        
        <span v-if="!showRadio" class="o-Down-arrow" v-show="ageShown" @click="ageSwitchShown = !ageSwitchShown">	
            {{showPersonType}} {{qResultsLang.age}}	
        </span>	
        <span v-if="!showRadio" class="o-Down-arrow-icon" v-show="ageShown" @click="ageSwitchShown = !ageSwitchShown"></span>	
        <span v-if="!showRadio" class="o-Down-arrow" v-show="!ageShown" @click="bdaySwitchShown = !bdaySwitchShown">	
            {{showPersonType}} {{qResultsLang.birthDate}}	
        </span>	
        <span v-if="!showRadio" class="o-Down-arrow-icon" v-show="!ageShown" @click="bdaySwitchShown = !bdaySwitchShown"></span>

        <span v-if="showRadio" class="c-qModal-form__person-wrap">{{showPersonType}}</span>
        
        <div v-if="showRadio" class="o-Content-drop__radio">
            <v-radio v-model="radioValue" :default="radioValue" :name="personType+'Age'" :label="'age'">
                {{qResultsLang.age}}
            </v-radio>
            <v-radio v-model="radioValue" :default="radioValue" :name="personType+'Age'" :label="'birthDate'">
                {{qResultsLang.birthDate}}
            </v-radio>
        </div>

        <span v-if="personType == 'Primary' && ageShown" @click.prevent="showModal('/guide/terms?section=primaryage')"
            class="o-Question c-Ages-drop__checkbox-q-modal c-Ages-drop__checkbox-q-modal--dropdown">
        </span>
        <span v-if="personType == 'Primary' && !ageShown" @click.prevent="showModal('/guide/terms?section=birthdate')"
            class="o-Question c-Ages-drop__checkbox-q-modal c-Ages-drop__checkbox-q-modal--dropdown">
        </span>

        <span v-if="personType == 'Spouse' && ageShown" @click.prevent="showModal('/guide/terms?section=spouseage')"
            class="o-Question c-Ages-drop__checkbox-q-modal c-Ages-drop__checkbox-q-modal--dropdown">
        </span>
        <span v-if="personType == 'Spouse' && !ageShown" @click.prevent="showModal('/guide/terms?section=birthdate')"
            class="o-Question c-Ages-drop__checkbox-q-modal c-Ages-drop__checkbox-q-modal--dropdown">
        </span>

        <span v-if="personType == 'F1 Visa Holder' && ageShown" @click.prevent="showModal('/guide/terms?section=primaryage')"
            class="o-Question c-Ages-drop__checkbox-q-modal c-Ages-drop__checkbox-q-modal--dropdown">
        </span>
        <span v-if="personType == 'F1 Visa Holder' && !ageShown" @click.prevent="showModal('/guide/terms?section=birthdate')"
            class="o-Question c-Ages-drop__checkbox-q-modal c-Ages-drop__checkbox-q-modal--dropdown">
        </span>

        <span v-if="personType == 'F2 Visa Holder' && ageShown" @click.prevent="showModal('/guide/terms?section=primaryage')"
            class="o-Question c-Ages-drop__checkbox-q-modal c-Ages-drop__checkbox-q-modal--dropdown">
        </span>
        <span v-if="personType == 'F2 Visa Holder' && !ageShown" @click.prevent="showModal('/guide/terms?section=birthdate')"
            class="o-Question c-Ages-drop__checkbox-q-modal c-Ages-drop__checkbox-q-modal--dropdown">
        </span>

        <span v-if="personType == 'J1 Visa Holder' && ageShown" @click.prevent="showModal('/guide/terms?section=primaryage')"
            class="o-Question c-Ages-drop__checkbox-q-modal c-Ages-drop__checkbox-q-modal--dropdown">
        </span>
        <span v-if="personType == 'J1 Visa Holder' && !ageShown" @click.prevent="showModal('/guide/terms?section=birthdate')"
            class="o-Question c-Ages-drop__checkbox-q-modal c-Ages-drop__checkbox-q-modal--dropdown">
        </span>

        <span v-if="personType == 'J2 Visa Holder' && ageShown" @click.prevent="showModal('/guide/terms?section=primaryage')"
            class="o-Question c-Ages-drop__checkbox-q-modal c-Ages-drop__checkbox-q-modal--dropdown">
        </span>
        <span v-if="personType == 'J2 Visa Holder' && !ageShown" @click.prevent="showModal('/guide/terms?section=birthdate')"
            class="o-Question c-Ages-drop__checkbox-q-modal c-Ages-drop__checkbox-q-modal--dropdown">
        </span>
    </label>

    <input
        v-show="ageShown"
        type="tel"
        number
        class="o-Field o-Field--full o-Content-drop__input"
        @input="handleAgeInput"
        :value="age"
        :placeholder="showAgePlaceHolder"
        maxlength="3"
        @blur="$emit('validate')"
        @keyup.enter="$emit('submit')"
        :disabled ="disabled" 
        ref="age"
    />
    <input
        v-show="!ageShown"
        v-date-insert="bday"
        maxlength="10"
        type="tel"
        class="o-Field o-Field--full o-Content-drop__input"
        @input="handleBdayInput"
        :value="bday"
        :placeholder="showPlaceHolder"
        @blur="setAge"
        @keyup.enter="$emit('submit')"
        :disabled ="disabled"
        ref='birthDate'
    />
    </div>
</template>

<script>
import { createDateByLangStr } from '../../helper-functions/helper-functions';
import dateInsert from '../../directives/directive.date-insert';
import radio from '../components.radio-button.vue';

import dayjs from 'dayjs';
import 'dayjs/locale/es';
var lang = window.globalLanguage.lang;
if (lang === 'es') {
  dayjs.locale('es');
}

export default {
    props: {
        personType: '',
        age: null,
        spouseAge: null,
        visaStatus: '',
        bday: '',
        travelStartDate: '',
        travelStartDateValidate: null,
        disabled: '',
        showRadio: false
    },
    components: {
        'v-radio': radio
    },
    data: function () {
        return {
            qResultsLang: window.qResultsLang || '',            
            ageSwitchShown: false,
            bdaySwitchShown: false,
            currentLang:window.globalLanguage.lang,
            radioValue: 'age',
            ageShown: true
        }
    },
    watch: {
        visaStatus: function(newVal) {
            if (newVal === 'J2' || newVal === 'F2') {
                this.$emit('input', {
                    person: 'Spouse',
                    age: ''
                });
            }
        },
        travelStartDate: function (newVal) {
            if (this.age != null && this.bday != '') {
                this.setAge();
            }
        },
        radioValue (val) {
            this.ageShown = val == 'age' ? true : false;
            this.$emit('input-type', {person: this.personType.toLowerCase(), inputType: this.radioValue == 'age'});
        }
    },
    mounted () {
        this.$refs.age.focus();
        this.$watch('ageShown',
            (val) => {
                val == false && this.bday != ''? this.$refs.birthDate.focus() : this.$refs.age.focus()
            }
        )
    },
    methods: {
        handleAgeInput: function (event) {
            let ageInfo = {
                person: this.personType,
                age: event.target.value
            };

            if(!(/^\d+$/.test(ageInfo.age))){
                ageInfo.age=null;
                event.target.value = null;
            }

            this.$emit('input', ageInfo);
        },
        handleBdayInput: function (event) {
            var ageInfo = {
                person: this.personType,
                bday: event.target.value
            };

            this.$emit('input', ageInfo);
        },
        toggleAge: function () {
            /*  INSUBUY-3967
             if (this.travelStartDate === '' && this.travelStartDateValidate) {
               this.travelStartDateValidate.$touch();
            } */
            this.ageSwitchShown = false;
            this.bdaySwitchShown = false;
            if (!this.showRadio) this.ageShown = !this.ageShown;
        },
        setAge: function () {
            var age;
            if(window.globalLanguage.section === 'multitrip' 
               || window.globalLanguage.section === 'expatriate'
               || window.globalLanguage.section === 'marine'
               || window.globalLanguage.section === 'missionary'
               ){
                var newDate;
                switch(lang){
                    case 'es':
                    newDate = dayjs().format('DD/MM/YYYY');
                    break;
                    case 'zh':
                    newDate = dayjs().format('YYYY-MM-DD');
                    break;
                    default:
                    newDate = dayjs().format('MM/DD/YYYY');
                }
                age = this.getAgeAtTimeOfTravel(this.bday, newDate);
            }else{
                age = this.getAgeAtTimeOfTravel(this.bday, this.travelStartDate);
            }
            var ageInfo;
            if (age != null) {
                ageInfo = {
                    person: this.personType,
                    age: age,
                    bday: this.bday
                };
            }else{
                ageInfo = {
                    person: this.personType,
                    age: null,
                };
            }

            this.$emit('input', ageInfo);
            this.$emit('validate');

        },
        getAgeAtTimeOfTravel: function (birthdate, travelStartDate) {
            if (!birthdate || !travelStartDate) {
                return null;
            }
            if(lang =='zh'){
                travelStartDate = new Date(dayjs(travelStartDate).format('MM/DD/YYYY'));
            }else if (travelStartDate) {
                travelStartDate = createDateByLangStr(travelStartDate);
            }
            var birthDate = this.doformatdate(birthdate);
            if(birthDate == 'NaN/NaN/NaN' || birthDate == 'NaN-NaN-NaN'){
                return null;
            }else{
                if(lang == 'es'){
                    birthDate = createDateByLangStr(birthDate);
                }else if (birthDate) {
                    birthDate = new Date(birthDate);
                }
            }
            var age = travelStartDate.getFullYear() - birthDate.getFullYear();
            var m = travelStartDate.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && travelStartDate.getDate() < birthDate.getDate()))
            {
              age--;
            }
            if ((typeof age === "number") && (Math.floor(age) === age) && (0 <= age && age < 200)) {
                return age;
            } else {
                return null;
            }
        },
        showModal: function (url) {
            this.$emit('show-modal', url);
        },
        doformatdate:function(tmpdate) {
        var check_date;
        var tempDate;

        switch (lang) {
            case 'es':
              if(dayjs(tmpdate, 'DD/MM/YYYY').format() != 'Invalid date'){
                tempDate = tmpdate.split('/');
                check_date = new Date(tempDate[2], tempDate[1] - 1, tempDate[0]);
              }else{
                return 'NaN/NaN/NaN';
              }
              break;
            case 'zh':
              if(dayjs(tmpdate).format('YYYY-MM-DD') != 'Invalid date'){
                tempDate = tmpdate.split('-');
                check_date = new Date(tempDate[0], tempDate[1] - 1, tempDate[2]);
              }else{
                check_date = new Date(tmpdate);
              }
              break;
            default:
              if(dayjs(tmpdate).format('MM/DD/YYYY') != 'Invalid date'){
                tempDate = tmpdate.split('/');
                check_date = new Date(tempDate[2], tempDate[0] - 1, tempDate[1]);
              }else{
                check_date = new Date(tmpdate);
              }
          }
          //check_date = new Date(check_date);
          var month = check_date.getMonth();
          month = eval("month += 1");
          var date = check_date.getDate();
          var year = check_date.getFullYear();
          var month_leading_zero = "";
          var date_leading_zero = "";
          if (month < 10) {
            month_leading_zero = 0;
          }
          if (date < 10) {
            date_leading_zero = 0;
          }
          var formatdate;
          if(lang === 'en'){
            formatdate = "" + month_leading_zero + month + "/" + date_leading_zero
              + date + "/" + year;
          }else if(lang==='es'){
            formatdate = "" + date_leading_zero + date + "/" + month_leading_zero
              + month + "/" + year;
          } else {
            formatdate = "" + year + "-" + month_leading_zero + month + "-" + date_leading_zero
              + date ;
          }
          return formatdate;
        }
    },
    computed: {
        showPlaceHolder: function(){
            return qResultsLang.dateFormat;
        },
        showPersonType: function(){
            if(this.personType === 'Primary'){
                return qResultsLang.primary;
            }else if(this.personType === 'Spouse'){
                return qResultsLang.spouse;
            }else if(this.personType === 'F1 Visa Holder'){
                return qResultsLang.f1VisaHolder;
            }else if(this.personType === 'F2 Visa Holder'){
                return qResultsLang.f2VisaHolder;
            }else if(this.personType === 'J1 Visa Holder'){
                return qResultsLang.j1VisaHolder;
            }else if(this.personType === 'J2 Visa Holder'){
                return qResultsLang.j2VisaHolder;
            }
        },
        showAgePlaceHolder: function(){
            return qResultsLang.age;
        }
    }
}
</script>

<style lang="scss">
@import '../../../scss/settings/all-global-variables';

.o-Age-switch {
    background: $white;
    box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.2);
    max-width: 10rem;
    padding: 2px;
    position: absolute;
    text-align: center;
    top: 105%; left: 0; right: 0;
    z-index: 1;
}

.o-Age-switch__enter-by-label,
.o-Age-switch__enter-by-opt {
    display: block;
}

.o-Age-switch__enter-by-label {
    background: $ib-blue--lightest;
    font-weight: 500;
    padding: 0.1rem;
}

.o-Age-switch__enter-by-opt {
    cursor: pointer;
    padding: 0.5em 0.1rem;

    &:hover {
        background: $white--darker;
    }
}
</style>