function storagePolyfill() {
  try{
    if(typeof window.sessionStorage == "undefined" || typeof window.localStorage == "undefined") {
      var storageConst = {
          length: 0,
          setItem: function(key, value) {
              try {
                document.cookie = key + '=' + value + '; path=/; SameSite=Lax';
              } catch (error) {
                console.log('error occurred.');
              }
              this.length++;
          },
          getItem: function(key) {
              var keyEQ = key + '=';
              var ca = document.cookie.split(';');
              for (var i = 0, len = ca.length; i < len; i++) {
                  var c = ca[i];
                  while (c.charAt(0) === ' ') c = c.substring(1, c.length);
                  if (c.indexOf(keyEQ) === 0) return c.substring(keyEQ.length, c.length);
              }
              return null;
          },
          removeItem: function(key) {
              this.setItem(key, '', -1);
              this.length--;
          },
          clear: function() {
              // Caution: will clear all persistent cookies as well
              var ca = document.cookie.split(';');
              for (var i = 0, len = ca.length; i < len; i++) {
                  var c = ca[i];
                  while (c.charAt(0) === ' ') c = c.substring(1, c.length);
                  var key = c.substring(0, c.indexOf('='));
                  this.removeItem(key);
              }
              this.length = 0;
          },
          key: function(n) {
              var ca = document.cookie.split(';');
              if (n >= ca.length || isNaN(parseFloat(n)) || !isFinite(n)) return null;
              var c = ca[n];
              while (c.charAt(0) === ' ') c = c.substring(1, c.length);
              return c.substring(0, c.indexOf('='));
          }
      }
      if(typeof window.sessionStorage == "undefined") window.sessionStorage = storageConst;
      if(typeof window.localStorage == "undefined") window.localStorage = storageConst;
    }
  } catch(e){
    console.log("For easy access please enable your cookies in browser settings.");
    var $newNode = document.createElement("DIV");
    var errorMsg = window.globalLanguage.cookieEnableMessage;
    $newNode.innerHTML = '<div class="c-Head-M__title-wrap-ie9" style="z-index: 999999;position: relative;top: 0px;left: 0px;width: 100%;"><div class="o-Layout"><p class="c-Head-M__pg-title-ie9">'+errorMsg+'</p></div></div>';
    var $body = document.getElementsByTagName("body")[0];

    if(!GlobalVue) $body.insertBefore($newNode, $body.firstChild);
  }
}

export default storagePolyfill;
