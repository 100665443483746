"use strict";

import {validateEmail, isChromeBrowser} from '../helper-functions/helper-functions';

import Vue from 'vue';
import VueResource from 'vue-resource';
import Vuelidate from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import {ibSpinOpts} from '../helper-functions/applicationCommonValues';

Vue.use(VueResource);
Vue.use(Vuelidate);

//override global spinner
const ibSpinOpts1 = ibSpinOpts;

Vue.component('feedback-form', {
    data: function () {
        return {
            emailSending: false,
            emailSent: false,
            form: {
                feedback: '',
                email: '',
                articleTitle: ibJS.article?.articleTitle ? ibJS.article?.articleTitle : '',
                articleUrl: ibJS.article?.articleUrl ? ibJS.article?.articleUrl : '',
            },
            shareFormSSErrors: {
                fieldErrors: [],
                globalErrors: []
            },
            validEmail: true,
            showMobileError: false,
            isChrome: isChromeBrowser(),
        }
    },
    computed: {
        emailFormShownC: function() {
            if (this.emailSent) {
                return false;
            } else {
                return true;
            }
        }
    },
    validations :  function () {
        var validations = {
            form : {
                email : {required, email},
                feedback: {required}
            }
        };
        return validations;
    },
    methods: { 
        sendEmail: function() {
            this.showMobileError = false;
            var self = this;
            // Spinner
            var spinTarget = self.$refs.btnspinner;
            var spinner = new IBSpinner(ibSpinOpts1).spin(spinTarget);
            self.emailSending = true;
            var data = self.form;

            self.$v.form.$touch();
            if (!this.$v.$invalid && this.validEmail) {
                self.shareFormSSErrors.globalErrors = [];
                self.shareFormSSErrors.fieldErrors = [];

                self.$http.post('/api/blog-feedback/', data)
                    .then(
                        function (response) {
                            if(response) {
                                var responseBody = response.body;

                                if (responseBody.status === 'error') {
                                    self.emailSending = false;

                                    if (responseBody.data.globalErrors) {
                                        self.shareFormSSErrors.globalErrors = responseBody.data.globalErrors;
                                    }
                                    if (responseBody.data.fieldErrors) {
                                        self.shareFormSSErrors.fieldErrors = responseBody.data.fieldErrors;
                                    }

                                    $("#modal-body").animate({scrollTop: 0}, "fast");
                                } else {
                                    //Email sent successfully
                                    self.emailSending = false;
                                    self.emailSent = true;
                                    self.$v.form.$reset();
                                    self.shareFormSSErrors.globalErrors = [];
                                    self.shareFormSSErrors.fieldErrors = [];
                                }
                            }                            
                        },
                        function (response) {
                            self.emailSending = false;
                        }
                    );
            } else {
                this.showMobileError = true;
                self.emailSending = false;
                $("#modal-body").animate({ scrollTop: 0 }, "fast");
            }
        },
        scrollingToTop: function(e) {
            $("#modal-body").animate({scrollTop: 0}, "fast");
                e.preventDefault();
                $("#close_button").focus();
                // this.$refs.closebtn.focus();
        },
    }
});

Vue.component('feedback-modal', {
    data: function () {
        return {
        }
    },
    mounted: function() {
        $("#sender_email_share_modal").focus();
    },
    methods: {
        sendForm: function () {
            self.$http.get(this.modalUrl)
                .then(
                    function (response) {
                        if (response.status === 200) {
                            self.content = response.body;
                        } else {
                            self.content = problemMsg;
                        }
                    },
                    function (response) {
                        self.content = problemMsg;
                    }
                );
        },
        scrollToTop: function () {
            this.$refs.modalbody.scrollTop = 0;
        }
    }
});
