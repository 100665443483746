'use strict';
import Vue from 'vue';
import VueResource from 'vue-resource';
require('./main');
require('./third-party/minitip-tooltip');
import datepicker from 'bootstrap-datepicker';
require('bootstrap-datepicker/dist/locales/bootstrap-datepicker.es.min');
require('bootstrap-datepicker/dist/locales/bootstrap-datepicker.zh-CN.min');

import {
    validateEmail,
    atleastOneNumber,
    onlyAlphaNumeric,
    onlyAlpha,
    getCategoryName,
    convertDateString,
    getParameterByName,
    getCurrentTime,
    addMins,
    getCountriesFromStorage,
    matchTimezone,
    convertTimeForCookie,
    mobileAppLogout
} from './helper-functions/helper-functions';
import {ibSpinOpts} from './helper-functions/applicationCommonValues';
require("./third-party/select2");
require('./section-specific/applications/directives/select2');

import {
    getCookieItem,
    getItemSessionStorage,
    setItemSessionStorage
} from './helper-functions/services';

import { accounting } from 'accounting';

import * as Sentry from '@sentry/vue';
import NavHeader from './section-specific/applications/mixins/office-hours';
import {
    IgnoreErrors,
    IgonreUrls,
    WhiteListUrls
} from './helper-functions/white-lists';

import { 
    GoAwayMixin
 } from './section-specific/applications/mixins/GoAwayCapture';

Sentry.init({
    Vue,
    dsn: 'https://259738f6b26e407680524b4282c13121@sentry.io/1234549',
    integrations: [
        Sentry.replayIntegration(),
    ],
    whitelistUrls: WhiteListUrls,
    denyUrls : IgonreUrls,
    ignoreErrors: IgnoreErrors,
    shouldSendCallback: function (data) {
        const userAgentCopy = window.navigator.userAgent;
        return !/^(.*CloudFlare-AlwaysOnline.*|.*Opera Mini.*|.*UC Browser.*|.*Yandex Browser.*|.+MSIE 8\.0;.+)$/.test(userAgentCopy);
    },
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

__webpack_public_path__ = '/assets/build/js/'+window.buildNumber+'/';
// __webpack_public_path__ = '/assets/build/js/';

Vue.use(VueResource);

Vue.http.interceptors.push((request, next) => {
    if (request && request.url) request.url += (request.url?.indexOf('?') > 0 ? '&' : '?') + 'cb=' + new Date().getTime();
    next();
});

Vue.filter('money', function(val) {
    if (!val) return val;
    return accounting.formatMoney(val);
})

require('./components/component.main-modal').default;
require('./components/component.share-modal').default;
require('./components/component.feedback-modal').default;

// only use this eventHub when absolutely necessary because it makes it harder to follow the flow of the code
global.eventHub = new Vue();
if(typeof GlobalVue === 'undefined' || !GlobalVue) {
    GlobalVue = new Vue({
        el: '#globalVue',
        mixins: [NavHeader, GoAwayMixin],
        data: {
            shareModalShown: false,
            feedbackModalShown: false,
            pcidssModalShown: false,
            renderHtml: '',
            awayFetchSpinner: false,
            htmlEvent: '',
            isInternalIP: window.globalLanguage.internalIP,
            allCountriesGlobal: [],
            popularCountriesGlobal: [],
            allStates: [],
        },
        created: function () {
            var self = this;
            $(document).ready(function () {
                // switch language for mobile app. 
                $('.c-Lang-H__link--ch').on('click', () => {
                  try {
                    window.flutter_inappwebview.callHandler('changeLanguage', 300).then(function(result) {
                      // action to take on response.
                    });
                  } catch (err) {
                    console.log('App is not enabled.');
                  }
                });
                $('.c-Nav-M__link--ch').on('click', () => {
                    try {
                      window.flutter_inappwebview.callHandler('changeLanguage', 300).then(function(result) {
                        // action to take on response.
                      });
                    } catch (err) {
                      console.log('App is not enabled.');
                    }
                });
                $('.c-Lang-H__link--es').on('click', () => {
                    try {
                        window.flutter_inappwebview.callHandler('changeLanguage', 400).then(function(result) {
                        // action to take on response.
                        });
                    } catch (err) {
                        console.log('App is not enabled.');
                    }
                });
                $('.c-Lang-H__link--en').on('click', () => {
                    try {
                        window.flutter_inappwebview.callHandler('changeLanguage', 200).then(function(result) {
                        // action to take on response.
                        });
                    } catch (err) {
                        console.log('App is not enabled.');
                    }
                });

                $('#js-ShareModalOffCanvas, .c-Actions-H__link--share').on('click', function (e) {
                    self.showShareModal();
                    Vue.nextTick(()=>{
                        var captchaIMGWrap = $('#AOCaptchaIMG');
                        var newUrl = "/AOCaptcha.jpg";
                        // Set to null first bc IE
                        captchaIMGWrap.attr('src', null);
                        captchaIMGWrap.attr('src', newUrl);
                    })
                    e.preventDefault();
                });
                $('.c-Actions-H__link--feedback').on('click', function (e) {
                    self.showFeedbackModal();
                    e.preventDefault();
                });
                $('#js-SaveShare').on('click', function (e) {
                    var captchaSaveIMGWrap = $('#AOCaptchaIMG');
                    var captchaSaveIMGQuote = $('#js-SaveShare-AOCaptchaIMG');

                    var newSaveUrl = "/AOCaptcha.jpg";
                    // Set to null first bc IE
                    captchaSaveIMGWrap.attr('src', null);
                    captchaSaveIMGWrap.attr('src', newSaveUrl);
                    captchaSaveIMGQuote.attr('src', null)
                    captchaSaveIMGQuote.attr('src', newSaveUrl);
                    e.preventDefault();
                });
                $('#js-ShareModal-Main').click(function (e) {
                    if (e.target.id === "js-ShareModal-wrap") {
                        self.hideShareModal();
                    }
                });
                $('#foot-pcidss, #pcidss-icon').on('click', function (e) {
                    e.preventDefault();
                    self.showPcidssModal();
                });
                $('#js-pcidssModal-Main').on('click', function (e) {
                    self.hidePcidssModal();
                });
                $('#requestCallbackmodal').on('click', function (e) {
                    if (!self.timefetched) {
                        self.timefetched = true;
                        self.getCurrentCSTTime();
                    }
                    self.showGoAwayModal();
                    e.preventDefault();
                });
                $('#requestCallbackmodalMobile').on('click', function (e) {
                    if (!self.timefetched) {
                        self.timefetched = true;
                        self.getCurrentCSTTime();
                    }
                    self.showGoAwayModal('mobile');
                    e.preventDefault();
                });

                // Email action use
                // open fresh chat
                let onlineChat = getParameterByName("chatonline");
                if (onlineChat == 'true' && typeof window.Genesys === 'function') {
                    Genesys("command", "Messenger.open", {},
                        function(o){},  // if resolved
                        function(o){    // if rejected
                            Genesys("command", "Messenger.close");
                        }
                    );
                }

                // open request a callback modal
                let requestcallback = getParameterByName("requestcallback");
                if (requestcallback == 'true') {
                    self.showGoAwayModal();
                }

                /*
                *  SITE SEARCH
                */
                var siteSearchUrl = window.globalLanguage.searchUrl;
                var $searchElm = $("#js-SearchModal"),
                        $searchHolder = $(".search-input-container"),
                        $searchInput = $searchHolder.find("input"),
                        $searchOverlay = $searchHolder.next();
                var $menuNav = $(".search-link");
                var $menuClose = $(".o-search-close");
                var $searchIcon = $(".input-group--icon");
                $searchIcon.click((e)=>{
                    var $siteSearchForm = $(e.target).parent().closest("form");
                    //window.location.href="/search-results?page=1&query=" + $(e.target).val() + "&lang=" + window.globalLanguage.lang;
                    
                    // SENTRY-803
                    if ($siteSearchForm[0]) {
                        var $siteSeatchDom = document.querySelector("#" + $siteSearchForm[0].id);
                        $siteSeatchDom.action = globalLanguage.isSchoolEligibility ? globalLanguage.schoolSearchUrl :  siteSearchUrl;
                        $siteSeatchDom.submit();
                    }
                });
                $menuClose.click((e)=> {
                    $(".c-Contact__col1 .search-input-container").slideUp();
                });
                $menuNav.click((e)=> {
                    $(".c-Contact__col1 .search-input-container").slideToggle();
                });
                $searchElm.click((e)=> {
                    $searchElm.toggleClass("show");
                    $searchHolder.toggle();
                    $searchHolder.toggleClass("show");
                    if($searchElm.hasClass("show")) {
                        $searchInput.focus();
                    }

                });

                $searchOverlay.click(()=> {
                    $searchHolder.hide();
                    $searchElm.removeClass("show");
                    $searchHolder.removeClass("show");
                });

                $searchInput.on("keydown", function(e) {
                    var $siteSearchForm = $(e.target).parent().closest("form");
                    if(e.key == 'Enter' || e.keyCode == 13) {
                        var $formElm = document.querySelector("#" + $siteSearchForm[0].id);
                        $formElm.action = siteSearchUrl;
                        $formElm.submit();
                    }
                });
                

            }).keyup(function (e) {
                e.preventDefault();
                if (e.keyCode == 27) {
                    self.hideShareModal();
                }
            });

            // Get countries data from sessionStorage
            let masterList = getCountriesFromStorage();
            if (masterList && masterList.lang === window.globalLanguage.lang) {
                this.popularCountriesGlobal = masterList.popularCountries;
                this.allCountriesGlobal = masterList.countries;
                this.allStates = masterList.statesList;
                setTimeout(() => {
                    this.triggerCountryList();
                    this.triggerStateList();
                }, 1500);
            }
            else {
                // Get countries data from API
                this.$http.get('/api/retrieve-countries/' + '?lang=' + window.globalLanguage.lang).then((res) => {
                    if(res && res.body && res.body.data && res.body.data.masterList) {
                        let listObj = res.body.data.masterList;
                        listObj.lang = window.globalLanguage.lang;
                        this.popularCountriesGlobal = listObj.popularCountries;
                        this.allCountriesGlobal = listObj.countries;
                        this.allStates = listObj.statesList;
                        setItemSessionStorage(`masterList_${window.globalLanguage.lang}`, JSON.stringify(listObj));

                        this.triggerCountryList();
                        this.triggerStateList();
                    }
                },err => {
                    console.log(err);
                });
            }
            // init alert bar
            if(this.featuredMessagesList && this.featuredMessagesList.length > 0) {
                for(let i = 0; i < this.featuredMessagesList.length; i++) {
                    if(this.featuredMessagesList[i].primary == true) {
                        this.primaryBar = this.featuredMessagesList[i];
                        this.primaryBar.href = this.primaryBar.emergencyMessage[0].clickable ? this.primaryBar.emergencyMessage[0].link : 'javascript:void(0)';
                    } else {
                        this.secondBar = this.featuredMessagesList[i];
                        this.secondBar.href = this.secondBar.emergencyMessage[0].clickable ? this.secondBar.emergencyMessage[0].link : 'javascript:void(0)';
                    }
                }
            }
        },
        mounted: function() {
            //request a callback
            let numberOfPhnCodes = $("select[id*='phnCode']");
            if (numberOfPhnCodes && numberOfPhnCodes.length) {
                this.phnCodeId = 'phnCode' + (numberOfPhnCodes.length + 1);
                this.phnCodeParentId = 'phnCode' + (numberOfPhnCodes.length + 1) + '-parent';
            } else {
                this.phnCodeId = 'phnCode';
                this.phnCodeParentId = 'phnCode-parent';
            }

            this.$nextTick(() => {
                let element =  document.querySelectorAll('[id=nav-logout]');
                for(let i =0; i < element.length; i++) {
                    element[i].addEventListener("click", function() {
                        mobileAppLogout();
                    });
                }
            })
        },
        methods: {
            showShareModal: function () {
                this.shareModalShown = true;
                $('body').addClass('is-Locked');
                this.setBodyOverflow('hidden');
            },
            showFeedbackModal: function () {
                this.feedbackModalShown = true;
                $('body').addClass('is-Locked');
                this.setBodyOverflow('hidden');
            },
            hideShareModal: function () {
                this.shareModalShown = false;
                const $isCsrModal = $('#login-modal').find('.no-action-esc');
                if (!$isCsrModal || $isCsrModal.length === 0) {
                    $('body').removeClass('is-Locked');
                    this.setBodyOverflow('auto');
                } else {
                    Vue.nextTick(()=>{
                        $('body').addClass('is-Locked');
                    })
                }
            },
            hideFeedbackModal: function () {
                this.feedbackModalShown = false;
                const $isCsrModal = $('#login-modal').find('.no-action-esc');
                if (!$isCsrModal || $isCsrModal.length === 0) {
                    $('body').removeClass('is-Locked');
                    this.setBodyOverflow('auto');
                } else {
                    Vue.nextTick(()=>{
                        $('body').addClass('is-Locked');
                    })
                }
            },
            showPcidssModal: function () {
                this.pcidssModalShown = true;
                $('body').addClass('is-Locked');
            },
            hidePcidssModal: function () {
                this.pcidssModalShown = false;
                $('body').removeClass('is-Locked');
            },
            showGoAwayModal: function (isMobile) {
                $('body').addClass('is-Locked');
                if(isMobile) {
                    $('.pushy-link.o-Push-close.o-Close').trigger('click');
                }
                if(!this.renderHtml) {
                    this.goAwayEventShowModal();
                }
                
            },
            hideGoAwayModal: function (e, elm, reset) {
                // this.showRequestCallBack = false;
                $('body').removeClass('is-Locked');
                this.phoneNumResch = false;
                this.phoneNumValidated = false;
                this.phoneReschDetails.time = null;
                this.phoneReschDetails.timezone = null;
                this.formSending = false;
                this.isTouched = false;
                if (reset) {
                    this.form = {
                        date: '',
                        time: '',
                        amPM: 'AM',
                        timezone: '',
                        country: 'USA',
                        isdCode: '1',
                        phoneNumber: '',
                        countryCode: 'USA'
                    };
                }
            },
            triggerCountryList () {
                global.eventHub.$emit('countries-obtained', this.allCountriesGlobal);
            },
            triggerStateList () {
                global.eventHub.$emit('states-obtained', this.allStates);
            },
            setBodyOverflow: value => {
                let $bodyElm = document.body;
                if ($bodyElm) {
                    if (value == 'auto') $bodyElm.style.overflow = '';
                    else $bodyElm.style.overflow = value;
                }
            },
        }
    });

    new Vue({
        el: '#nav-header',
        mixins: [NavHeader],
        data () {
            return {
                alreadyFetched: false
            }
        },
        mounted () {
            const self = this;
            const $toolsContact = document.querySelector('.c-Nav-M__item-tools--contact');
            if ($toolsContact) {
                $toolsContact.addEventListener('mouseenter',(e)=>{
                    if (!this.alreadyFetched) {
                        self.getCurrentCSTTime();
                        this.alreadyFetched = true;
                    }
                });
            }
        }
    })
    
    new Vue({
        el: '#mobile-nav-header',
        mixins: [NavHeader],
        data () {
            return {
                alreadyFetched: false
            }
        },
        methods: {
            fetchTime () {
                if (!this.alreadyFetched) {
                    this.getCurrentCSTTime();
                    this.alreadyFetched = true;
                }
            }
        }
    })
}



export const MainVueOpts = {
    data: function () {
        return {
            bodyPosition: 0,
            fieldErrors: [],
            globalErrors: [],
            isResultsPage: typeof ibJS !== 'undefined' && ibJS.isResultsPage ? ibJS.isResultsPage : false, //global from html
            modalShown: false,
            modalUrl: '',
            noPlansFoundShown: false,
            server500ErrorShown: false,
            ssCriteria: {},
            serverErrors: [],
            showDependentsMessage:false,
            modalAddress:'',
            appModal:false,
            videomodal: false,
            searchResultErrors: [],
            hasSearchRequiredIssue: false,
            apiModal: false,
            isMyAccLogin: false,
            contentModal: false,
            isMultiModal: false,
            fullQuoteId: null,
            modalHeader: '',
            showQuickEdit: false
        }
    },
    created: function () {
        console.log("this is a uat");
        var self = this;
        $(document).keyup(function(e) {
            e.preventDefault();
            if (e.keyCode == 27) {
                self.hideModal();
            }
        });
        $(document).ready(function ($) {
            $(':checkbox').on('keypress', function (event) {
                if (event.which === 13 || event.which === 32) {
                    event.preventDefault();
                    this.click();
                    if(event.target.id!==''){
                        $(event.target.id).focus();
                    }
                }
            });
        });
        $(document).ready(function () {
            $(':radio').on('keypress', function (event) {
                if (event.which === 13 || event.which === 32) {
                    event.preventDefault();
                    this.click();
                    if(event.target.id!==''){
                        $(event.target.id).focus();
                    }
                }
            });
        });
        if(window.ibJS.isSearchResult) {
            this.searchResultErrors = ibJS.errors;
            /* Hide no result found message when we got similar system error message */
            if(this.searchResultErrors && this.searchResultErrors.length > 0 && this.searchResultErrors.indexOf('query.errors.required') > -1) {
                this.hasSearchRequiredIssue = true;
            }
        }

        this.$http.get('/api/cookie-policy-accepted/').then(res=>{
            const responseBody = res.body;
            const isCookiePresent = responseBody?.cookieDTOList?.find(itm=>itm.cookieName=='cookiePolicyAccepted');
            if (!isCookiePresent && window.self == window.top) {
                const cookiePolicy = document.getElementById('cookie-policy');
                if (cookiePolicy) {
                    cookiePolicy.classList.remove('hide');
                    const allClsBtns = cookiePolicy.getElementsByClassName('close-button');
                    if (allClsBtns) {
                        Array.from(allClsBtns).forEach(el=>{
                            el.addEventListener('click', this.checkForCookiePolicy)
                        })
                    }
                }
                
            }
        });
    },
    mounted() {
        global.eventHub.$on('show-modal', this.showModal);

        // APP-2091
        let showModalEvent = global.eventHub._events['show-modal'];
        if(showModalEvent.length > 1) {
            global.eventHub._events['show-modal'] = [showModalEvent[showModalEvent.length-1]];
        }
        // INSUBUY-4881 goawaymodal/callback request
        if (document.querySelector('.goawaymodal') && getCookieItem('captureModalEvent') === 'true') {
            
            let goawaybutton = document.querySelector('.goawaymodal');
            // let footer = document.querySelector('.c-Site__footer');
            // if(footer) {
            //     footer.style.marginBottom = '30px';
            // }
            if(goawaybutton) {
                goawaybutton.style.display = 'flex';
                goawaybutton.addEventListener('click', this.triggerParentCallBack);
            }
        }

    },
    methods: {
        resetSSErrors: function () {
            this.fieldErrors = [];
            this.globalErrors = [];
            this.server500ErrorShown = false;
            this.noPlansFoundShown = false;
            this.showDependentsMessage = false;
        },
        showModal: function(url, isMyAccLogin, multiModal) {
            this.videomodal = false;
            this.modalHeader = '';
            if(typeof this.application !== undefined && this.$options.parent && typeof this.$options.parent.resetSessionExpireTime == "function"){
                this.$options.parent.resetSessionExpireTime();
            }
            if(url.indexOf('lang') < 0) {
                url += '&lang=' + window.globalLanguage.lang || 'en';
            }
            if (url) {
                if(url.indexOf('video') > -1 && url.indexOf('customertestimonials') == -1 && url.indexOf('commercial') == -1){
                    this.videomodal = true;
                }else if (url.indexOf('customertestimonials') > -1) {
                    this.modalHeader = 'customertestimonials';
                }else if (url.indexOf('commercial') > -1) {
                    this.modalHeader = 'commercial';
                }
                this.modalUrl = url;
                this.modalShown = true;
                this.bodyPosition = $('body').scrollTop();
                $('body').addClass('is-Locked');
                this.setBodyOverflow('hidden');

                if(isMyAccLogin) {
                    this.isMyAccLogin = true;
                    $("#login-modal").addClass("c-MyAcc__loginModal-hide-modal");
                }
                if (multiModal) this.isMultiModal = true;
            }
            // APP-2091 This could be refactored to add less listeners... (it's only for the one scenario in this ticket)
            this.$nextTick(() => {
                $('.c-Modal').click( e => {
                    if (e.target.parentElement && e.target.parentElement.parentElement && e.target.parentElement.parentElement.id === 'js-Modal-Main'){
                        //this.hideModal();
                    }
                });
            });
            setTimeout(function(){
                if (typeof window.injectEmail === 'function'){
                    window.injectEmail();
                }
            },500);
        },
        hideModal: function () {
            this.modalShown = false;
            const dataUrl = document.querySelector('.c-Modal') != null ? document.querySelector('.c-Modal').getAttribute('data-url') : '';
            if (dataUrl === this.modalUrl) this.isMultiModal = false;
            if (!this.isMultiModal) {
                this.appModal = false;
            } else {
                Vue.nextTick(()=>{
                    const listOfMultiModals = ['uploadsheet'];
                    let isMultiModal = false;
                    for (let i = 0; i < listOfMultiModals.length; i++) {
                        isMultiModal = this.modalUrl.indexOf(listOfMultiModals[i]) === -1 ? false : true;
                        if (isMultiModal) {
                            break;
                        }
                    }
                    this.isMultiModal = false;
                    this.showModal(this.modalUrl, false, isMultiModal);
                })
            }           
            const e = document.getElementsByClassName('c-Head-MyAcc__nav-menu--toggled');
            const b = document.getElementById('myacc-nav-menu');
            const display = b ? getComputedStyle(b).display : '';	
            
            if(this.isMyAccLogin) {
                $("#login-modal").removeClass("c-MyAcc__loginModal-hide-modal");
            }

            if (e && e.length > 0 && display === 'block') {
                $('body').addClass('is-Locked');
            } else {
                const $isCsrModal = $('#login-modal').find('.no-action-esc');
                if (!$isCsrModal || $isCsrModal.length === 0) {
                    $('body').removeClass('is-Locked');
                    this.setBodyOverflow('auto');
                } else {
                    Vue.nextTick(()=>{
                        $('body').addClass('is-Locked');
                    })
                }

                $('body').scrollTop(this.bodyPosition);
            }
        },
        setBodyOverflow: value => {
            let $bodyElm = document.body;
            if ($bodyElm) {
                if (value == 'auto') $bodyElm.style.overflow = '';
                else $bodyElm.style.overflow = value;
            }
        },
        setBackendErrors: function (errors) {
            this.fieldErrors = [];
            this.globalErrors = [];

            if (errors && errors.fieldErrors) {
                this.fieldErrors = errors.fieldErrors;
            }
            if (errors && errors.globalErrors) {
                this.globalErrors = errors.globalErrors;
            }
        },
        setBEErrors: function (errors) {
            this.globalErrors = [];

            this.globalErrors = errors;
        },
        showServer500Error: function (errors) {
            if (errors && errors.serverErrors) {
                this.serverErrors = errors.serverErrors;
            }
            try {
                this.server500ErrorShown = true;
            } catch(e){}

            setTimeout(function () {
                $('.js-E-rev').ibEProtect();
            }, 1000);
        },
        showNoPlansFoundError: function (fullQuoteId) {
            this.noPlansFoundShown = true;
            this.fullQuoteId = fullQuoteId || null;
        },
        hideNoPlansFoundError: function (fullQuoteId) {
            this.noPlansFoundShown = false;
            this.fullQuoteId = fullQuoteId || null;
        },
        showTripDaysError () {
            // No functionality in quote form
        },
        showNoDependentsCoveredError: function(){
            this.showDependentsMessage=true;
        },
        showPaymentOptionsModal: function(planId, renewable) {
            if (renewable) {
                this.showModal(`/payment-options?planId=${planId}&lang=${window.globalLanguage.lang}&isApplication=true&renewable=${renewable}`);
            } else {
                this.showModal(`/payment-options?planId=${planId}&lang=${window.globalLanguage.lang}&isApplication=true`);
            }
        },
        showAddressModal: function(url,address){
            if (url && address) {
                this.modalUrl = url;
                this.modalAddress = address;
                //this.modalShown = true;
                this.appModal = true;
                this.bodyPosition = $('body').scrollTop();
                $('body').addClass('is-Locked');
            }
        },
        hideAddressModal: function(){
            this.modalShown=false;
            this.appModal=false;
        },
        triggerParentCallBack: function () {
            if (this.$parent && !this.$parent.renderHtml && typeof this.$parent.goAwayEventShowModal === 'function') {
                this.$parent.goAwayEventShowModal();   
            } else if (!this.renderHtml && typeof this.goAwayEventShowModal === 'function') {
                this.goAwayEventShowModal();    
            }
        },
        checkForCookiePolicy () {
            const expYear = new Date().getFullYear()+1;
            const expDate = new Date().setFullYear(expYear);
            this.$http.post('/api/cookie-policy-accepted/', {
                "cookieName" : "cookiePolicyAccepted",
                "cookieValue" : "true",
                "expiresOn" : convertTimeForCookie()
            }).then(res=>{
                // added;
                document.getElementById('cookie-policy').classList.add('hide');
            })
        },
        openChat() {
            if (typeof window.Genesys === 'function') {
                Genesys("command", "Messenger.open", {},
                    function(o){},  // if resolved
                    function(o){    // if rejected
                        Genesys("command", "Messenger.close");
                    }
                );
            }
        },
        closeQuoteError() {
            this.noPlansFoundShown = false;
        },
        closeServerError() {
            this.server500ErrorShown = false;
        },
        closeEditQuote() {
            this.editQuoteShown = false;
        },
    }
};


//Prevents Share modal on homepage and on video guide from breaking when MainVue called twice.
if(!MainVue){
    MainVue = Vue.extend(MainVueOpts);
}

export default MainVue;
//export { MainVueOpts };
