<template>
    <div class="c-Narrow-search__add-opt-item" v-cloak>
        <!-- <label class="c-Narrow-search__add-opt-label">
            <input type="checkbox" v-model="checked"
                class="c-Narrow-search__add-opt-check">
                {{label}}
        </label>  -->
        <v-checkbox
            :id="getLabel"
            label-class="c-Narrow-search__add-opt-label"
            checkmark-class="c-Narrow-search__add-opt-check"
            :value="checkedInput"
            :disable="disabled"
            @change="changeCheckedValue">
            <template v-slot:prefix>
                <span class="c-Narrow-search__feature-label">{{label}}</span>
            </template>
        </v-checkbox>
        <span v-if="modalUrl" @click.prevent="showModal(modalUrl)" class="o-Information o-Information--narrow c-Narrow-search__add-opt-quest"></span>
        <slot name="modal"></slot>
    </div>
</template>

<script>
import Checkbox from '../../../components/components.checkbox.vue'
export default {
    components: {
        'v-checkbox': Checkbox
    },
    props: {
        checkedInput: {
            default: false,
        },
        label: {
            type: String,
            default: ''
        },
        modalUrl: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        // checked: {
        //     get: function() {
        //         return this.checkedInput;
        //     },
        //     set: function(value) {
        //         this.$emit('change-value', value);
        //     }
        // }
        getLabel () {
            if (this.label) return this.label.replace(/\s/g, '-');
            else return '';
        }
    },
    methods: {
        showModal(url) {
            this.$emit('show-modal', url);
        },
        changeCheckedValue(value) {
            this.$emit('change-value', value);
        }
    }
}
</script>